import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
// import queryString from 'query-string';

import { authActions } from '../actions';

import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
import appLogo from '../assets/FF_icon.png';

const AuthPage = (props) => {
  let [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const { dispatch } = props;
    if (location.search.length > 0)
      setTimeout(
        dispatch(
          authActions.authenticate(location.search, (error) => {
            setError(error.error_description);
          })
        ),
        2000
      );
    else setError('Missing code');
  }, []);

  return (
    <div className='row bgcolors'>
      <div
        className='panel-sidebar col-3 text-center d-none d-md-block'
        style={{ height: '100vh' }}>
        <p className='v-center'>
          <img
            src={scLogoWide}
            className='mb-3'
            style={{ maxWidth: '50%' }}
            alt='SimCase'
          />
          <br />
          <small>
            <a href='http://simcase.io' target='_blank' rel='noreferrer'>
              Learn More
            </a>
          </small>
        </p>
      </div>
      <div
        className='col-12 col-sm-10 offset-sm-1 col-md-5 offset-md-5 text-center'
        style={{ height: '100vh' }}>
        <div className='v-center'>
          <div className='card'>
            <div className='card-body pt-5 pb-5'>
              <h3 className='mt-0 mb-0 title-font font-weight-light'>
                {error ? error : 'Authenticating...'}
              </h3>
              <img
                src={appLogo}
                style={{ maxHeight: '180px' }}
                className='img-fluid mt-3 mb-3'
                alt=''
              />
              <br />
              {error && (
                <Link
                  to={{
                    pathname: '/',
                  }}
                  className='btn btn-outline-primary btn-lg'>
                  Log In
                </Link>
              )}
              <p className='mt-5 mb-0 d-md-none text-center'>
                <img src={scLogo} style={{ width: '25px' }} alt='' />
                <br />
                <small className='text-secondary'>Developed by Simcase</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedAuthPage = connect(mapStateToProps)(AuthPage);
export { connectedAuthPage as AuthPage };
