import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Data
import { scenarios } from '../../constants';

// Actions
import { gameActions, modalActions } from '../../actions';

// Helpers
import { getSetupValues } from '../../helpers/utils';

// INLINE COMPONENT: Event Selector
const EventSelector = (props) => {
  const {
    index = null,
    options,
    selected = '',
    onChange = null,
    disabled = false,
  } = props;
  const optionEls = options.map((o) => (
    <option key={`op-${o.id}`} value={o.id}>
      {o.title}
    </option>
  ));

  return (
    <select
      disabled={disabled}
      value={selected}
      className='form-control form-select'
      onChange={onChange ? (e) => onChange(index, selected, e) : null}>
      <option value=''>Select Event</option>
      {optionEls}
    </select>
  );
};

const FormEvents = (props) => {
  // State
  // const [fetching, setFetching] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState({ registered: [] });
  const [scenarioEvents, setScenarioEvents] = useState([]);

  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const game = useSelector((state) => state.game);

  // Effects
  useEffect(() => {
    setItem(game);
    if (game) {
      const theScenario = scenarios.find((s) => s.id === game.scenario);
      setScenarioEvents(theScenario?.events);
    }
  }, [game]);

  // Methods
  const validationLaunch = () => {
    const isLaunched =
      game?.started && game?.status === 'launched' ? true : false;
    if (isLaunched)
      return dispatch(
        modalActions.open({
          title: 'Session Launched',
          body: 'This session has already been launched. You can not modify its configuration anymore.',
          buttons: (
            <button
              key='modal-btn-close'
              className='btn btn-light'
              onClick={() => dispatch(modalActions.close())}>
              Cancel
            </button>
          ),
        })
      );
    else return false;
  };

  const handleSubmit = ({ redir = false }) => {
    const toSubmit = getSetupValues(item);

    // Clear gaps in the events_ids array
    const validEvents = toSubmit.events_ids.filter((id) => id !== '');
    const emptyEvents = toSubmit.events_ids.filter((id) => id === '');
    const orderedEvents = [...validEvents, ...emptyEvents];
    toSubmit.events_ids = orderedEvents;

    dispatch(gameActions.updateGame(toSubmit));
    // setSubmitted(true);
    if (redir === true)
      navigate(`/games/${item?._id}/setup/experience` + location.search, {
        replace: false,
      });
  };

  const handleChange = (e) => {
    if (validationLaunch()) return;

    const { name, value } = e.target;
    let newItem = { ...item };

    // Scenarios
    if (name === 'scenario') {
      const theScenario = scenarios.find((s) => s.id === value);
      setScenarioEvents(theScenario?.events);
    }

    // Events
    if (name === 'events_n' && Number(value) === 3)
      newItem['events_ids'] = [1, 2, 3];
    if (name === 'events_n' && Number(value) === 5)
      newItem['events_ids'] = [1, 2, 3, 4, 5];
    if (name === 'events_n' && Number(value) === 0)
      newItem['events_ids'] = [1, 2, 3, 4, 5];

    newItem[name] = value;
    newItem['changed'] = true;

    setItem(newItem);
    // setSubmitted(false);
    // dispatch(gameActions.updateGame(newItem));
  };

  const handleEventRemoval = (event_id) => {
    // New events Ids
    const eventsIds = [...item.events_ids];

    // Get the event post in the events array
    const newEventPos = eventsIds.indexOf(event_id);

    // Remove the event from the current slot
    eventsIds.splice(newEventPos, 1);

    // Add the empty event to the end of the list
    eventsIds.push('');

    // Set the item
    const newItem = { ...item };
    newItem.events_ids = eventsIds;
    setItem(newItem);
  };

  const handleEventChange = (slot, current, e) => {
    // New events Ids
    const eventsIds = [...item.events_ids];

    // Get the new value for this slot
    const { value } = e.target;
    const newValue = value ? Number(value) : value;

    if (newValue === '') return handleEventRemoval(current);

    // Calculate the swap
    const newEventPos = eventsIds.indexOf(newValue);

    // Swap
    eventsIds[newEventPos] = current;
    eventsIds[slot] = newValue;

    // Set the item
    const newItem = { ...item };
    newItem.events_ids = eventsIds;
    setItem(newItem);
  };

  const toggleScenarioTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          Select one of two available scenarios, each with a unique set of five
          events set.
        </li>
        <li className='mb-2'>
          The roles are the same for each scenario, however the details about
          the role are modified to align with the industry setting.
        </li>
        <li className='mb-0'>
          Note that eScooter industry scenario is designed to reflect a greater
          degree of difficulty than the Chocolatier industry scenario.
        </li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Select Scenario',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const toggleEventsTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          Determine if you would like to have students complete responses to
          three events, five events, or a custom number.
        </li>
        <li className='mb-2'>
          Events run 10-30 minutes, therefore more events will result in a
          longer user experience.
        </li>
        <li className='mb-2'>The minimum number of events is one.</li>
        <li className='mb-0'>Once ready, click the "Continue" button.</li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Number of Events',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const resetSettings = () => {
    const defaultSettings = {
      scenario: 'chocolate',
      events_n: 5,
      events_ids: [1, 2, 3, 4, 5],
    };

    const newItem = { ...item, ...defaultSettings };
    dispatch(gameActions.updateGame(newItem));
    dispatch(modalActions.close());
    // setSubmitted(true);
  };

  const toggleResetSettings = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <div className='text-center'>
        <p>Are you sure you want to reset settings for this step?</p>
        <p>This decision cannot be undone.</p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Cancel
      </button>,
      <button
        key='modal-btn-close'
        className='btn btn-primary'
        onClick={resetSettings}>
        Confirm
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Reset Settings',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  // Logic
  const disabledForm = ['saving', 'launched'].includes(game?.status)
    ? true
    : false;

  return (
    <div className='d-flex flex-column h-100'>
      <div className='row flex-grow-1'>
        {/* LEFT */}
        <div className='col-5 border-end mt-4 mb-4 px-4'>
          <h6>
            Select scenario{' '}
            <i
              onClick={(e) => toggleScenarioTooltip(true)}
              style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faInfoCircle} className='text-primary' />
            </i>
          </h6>
          <div className='d-flex align-items-center justify-content-center'>
            <div className='form-group'>
              <div className='input-group py-2'>
                <select
                  name='scenario'
                  className='form-control form-select'
                  value={item.scenario || ''}
                  onChange={handleChange}
                  disabled={disabledForm}>
                  <option value=''>Select Scenario</option>
                  {scenarios.map((scenario, sci) => {
                    return (
                      <option key={`scenario-${sci}`} value={scenario.id}>
                        {scenario.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {item?.scenario && (
            <div>
              <p className='fw-bold'>
                <small>About the scenario</small>
              </p>
              <p className='mb-0'>
                {scenarios.find((s) => s.id === item.scenario)?.description}
              </p>
            </div>
          )}
        </div>

        {/* RIGHT */}
        <div className='col-7 mt-4 mb-4 px-4'>
          <h6>
            Select the number of events{' '}
            <i
              onClick={(e) => toggleEventsTooltip(true)}
              style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faInfoCircle} className='text-primary' />
            </i>
          </h6>
          <div className=''>
            <div className='d-block form-check ps-0 mt-3'>
              <label
                className='form-check-label'
                style={{ width: '80px' }}
                htmlFor='events-3'>
                3 Events
              </label>
              <input
                className='form-check-input float-none ms-2'
                type='radio'
                name='events_n'
                value='3'
                checked={Number(item.events_n) === 3}
                disabled={disabledForm}
                id='events-3'
                onChange={handleChange}
              />
            </div>
            <div className='d-block form-check ps-0 mt-1'>
              <label
                className='form-check-label'
                style={{ width: '80px' }}
                htmlFor='events-5'>
                5 Events
              </label>
              <input
                className='form-check-input float-none ms-2 mt-1'
                type='radio'
                name='events_n'
                value='5'
                checked={Number(item.events_n) === 5}
                disabled={disabledForm}
                id='events-5'
                onChange={handleChange}
              />
            </div>
            <div className='d-block form-check ps-0'>
              <label
                className='form-check-label'
                style={{ width: '80px' }}
                htmlFor='events-custom'>
                Custom
              </label>
              <input
                className='form-check-input float-none ms-2'
                type='radio'
                name='events_n'
                value='0'
                checked={Number(item.events_n) === 0}
                disabled={disabledForm}
                id='events-custom'
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className='col-12 px-4 border-top py-4'>
          <div className='row row-cols-5 h-100'>
            {/* Selected Events */}
            {item?.events_ids?.map((event_id, ei) => {
              const event = scenarioEvents?.find((e) => e.id === event_id);

              return (
                <div
                  key={`ev-${ei}`}
                  className='d-flex flex-column col h-100x overflow-autox'>
                  {/* Custom Edit */}
                  {Number(item.events_n) === 0 && (
                    <div className='d-flex flex-shrink-1 mb-2'>
                      <EventSelector
                        key={`sel-${ei}`}
                        index={ei}
                        selected={event_id}
                        options={
                          scenarios.find((s) => s.id === item.scenario)
                            ?.events || []
                        }
                        onChange={handleEventChange}
                        disabled={disabledForm}
                      />
                      {event_id !== '' && (
                        <button
                          disabled={disabledForm}
                          onClick={(e) => handleEventRemoval(event_id)}
                          className='btn btn-outline-danger ms-2'>
                          <FontAwesomeIcon icon='trash-alt' size='xs' />
                        </button>
                      )}
                    </div>
                  )}

                  <div
                    className={`card h-100 ${
                      event ? '' : 'bg-light opacity-50'
                    }`}>
                    <div className='card-body overflow-auto'>
                      <h6 className='card-title'>{event ? event.title : ''}</h6>
                      <p
                        className={`${event ? 'card-text' : 'mt-5 card-text'}`}
                        style={{ maxHeight: '80px' }}>
                        <small>
                          {event
                            ? event.description
                            : 'Use dropdown to add an event +'}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <footer className='d-flex align-items-center bg-white p-3'>
        <button
          onClick={toggleResetSettings}
          disabled={disabledForm}
          className='btn btn-outline-primary me-auto'>
          Reset Settings
        </button>

        <small className='me-4 text-muted'>
          {item.status === 'saving'
            ? 'Saving...'
            : `Saved on: ${moment(item?.updated_at).format(
                'MMMM D, YYYY [at] h:mm A'
              )}`}
        </small>

        <button
          disabled={disabledForm}
          className='btn btn-outline-primary me-4'
          onClick={handleSubmit}>
          Save
        </button>

        <button
          className='btn btn-primary'
          disabled={disabledForm}
          onClick={(e) => handleSubmit({ redir: true })}>
          Save &amp; Continue
        </button>
      </footer>
    </div>
  );
};

export default FormEvents;
