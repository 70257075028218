import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DebriefMenu = props => {
  // Hooks
  const location = useLocation();

  // Vars
  const { game } = props;

  const links = [
    // { name: 'Players', url: '/players' },
    // { name: 'Debrief', url: '/debrief/overview' },
    // { name: 'Pareto', url: '/debrief/pareto' },
    // { name: 'Issue Types', url: '/debrief/types' },
    { name: 'Decisions', url: '/debrief/decisions' },
    { name: 'Analysis', url: '/debrief/analysis' },
    { name: 'Industry Assessment', url: '/debrief/survey' },
    { name: 'Leaderboard', url: '/debrief/leaderboard' }
  ];

  const linksItems = links.map((l, li) => {
    const active = location.pathname.includes(l.url)
      ? 'btn-primary'
      : 'btn-link';
    return (
      <Link
        key={'dm-' + li}
        to={`/games/${game._id}${l.url}`}
        className={`btn ${active} btn-sm ms-3 ${active}`}>
        {l.name}
      </Link>
    );
  });

  return (
    <div>
      <div className='d-inline-block'>{linksItems}</div>
      <button
        className='btn btn-sm btn-outline-primary ms-3'
        onClick={e => window.location.reload()}>
        <FontAwesomeIcon icon='redo-alt' />
      </button>
    </div>
  );
};

function mapStateToProps(state) {
  const { game } = state;
  return {
    game
  };
}

const connectedPage = connect(mapStateToProps)(DebriefMenu);
export { connectedPage as DebriefMenu };
// export default DebriefMenu
