import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faCaretDown,
  faCheck,
  faTimes,
  faMinus,
  faDotCircle,
  faUserMinus,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../actions';

// Components
import PlayerDraggable from './PlayerDraggable';

// Add FontAwesome icons
library.add([faCaretUp, faCaretDown, faCheck, faTimes]);

const PlayersList = ({ players = [] }) => {
  // State
  const [sort, setSort] = useState(1);

  // Hooks
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);

  // METHODS
  const handlePlayerActiveChange = (currentPlayerId, activeState) => {
    if (currentPlayerId) {
      dispatch(
        // gameActions.deallocatePlayer({
        //   _id: game?._id,
        //   current: currentPlayerId,
        //   active: activeState,
        // })
        gameActions.setPlayerGroup({
          _id: game?._id,
          player: currentPlayerId,
          group: '',
          active: activeState,
        })
      );
    }
  };

  const handleSort = () => {
    if (sort === 0) setSort(1);
    if (sort === 1) setSort(-1);
    if (sort === -1) setSort(1);
  };

  // LOGIC
  const sortedPlayers =
    sort !== 0
      ? players.sort((a, b) => {
          const fullNameA = a?.name + ' ' + a?.lastname;
          const fullNameB = b?.name + ' ' + b?.lastname;
          if (fullNameA < fullNameB) return -1 * sort;
          if (fullNameA > fullNameB) return 1 * sort;
          return 0;
        })
      : players;

  // Check if the form is disabled
  const disabledForm = ['saving', 'launched'].includes(game?.status)
    ? true
    : false;

  return (
    <div>
      <table
        className='table table-striped'
        style={{
          tableLayout: 'fixed',
        }}>
        <thead
          style={{ borderBottomWidth: '2px', borderBottomColor: '#0b4c8c' }}>
          <tr>
            <th className='py-1'>
              USER
              <button className='btn py-0 px-2 ms-1' onClick={handleSort}>
                <FontAwesomeIcon
                  icon={sort === 1 ? faCaretUp : faCaretDown}
                  className={sort === 1 ? 'mb-1' : 'mb-0'}
                />
              </button>
            </th>
            <th width='35px'></th>
            <th width='35px'></th>
            <th width='50px'></th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, pi) => (
            <tr
              key={`player-${player._id}-${
                player?.active ? 'active' : 'inactive'
              }-${pi}`}>
              <td className='align-middle'>
                <PlayerDraggable
                  player={player}
                  canDrag={true}
                  disabled={disabledForm}
                />
              </td>
              <td
                className='text-center align-middle text-center'
                style={{ width: '1%', whiteSpace: 'nowrap' }}>
                {player?.assigned ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className='text-success'
                    title='Assigned'
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className='text-danger'
                    title='Not Assigned'
                  />
                )}
              </td>
              <td
                className='text-center align-middle text-center'
                style={{ width: '1%', whiteSpace: 'nowrap' }}>
                {player?.online ? (
                  <FontAwesomeIcon
                    icon={faDotCircle}
                    title='Online'
                    className={
                      player?.online ? 'text-success' : 'text-secondary'
                    }
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faMinus}
                    title='Offline'
                    className='text-secondary'
                  />
                )}
              </td>
              <td
                className='text-center align-middle text-center'
                style={{ width: '1%', whiteSpace: 'nowrap' }}>
                <button
                  disabled={disabledForm}
                  title={player?.active ? 'Deactivate' : 'Activate'}
                  onClick={() =>
                    handlePlayerActiveChange(player?._id, !player?.active)
                  }
                  className={`btn btn-sm btn-outline-${
                    player?.active ? 'danger' : 'primary'
                  }`}>
                  {player?.active ? (
                    <FontAwesomeIcon icon={faUserMinus} />
                  ) : (
                    <FontAwesomeIcon icon={faUserPlus} />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayersList;
