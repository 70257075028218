import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
// import numeral from 'numeral';
import { CSVLink } from 'react-csv';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Header from '../../components/Header';
// import PresentationBtn from '../../components/PresentationBtn';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';
import PlayerDetails from '../../components/PlayerDetails';

// Constants
const forceLabels = [
  'Supplier',
  'Buyer',
  'Substitute',
  'Focal Firm',
  'Competitor',
  'New Entrant',
];

const Leaderboard = (props) => {
  // Vars
  const { game } = props;
  const { leaderboard = [] } = game;

  // State
  const [details, setDetails] = useState(null);

  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // Effects
  useEffect(() => {
    if (params.id && game?.round === null)
      dispatch(gameActions.getLeaderboard(params.id));
    if (params.id && game?.round !== null)
      dispatch(gameActions.getLeaderboard(params.id, game?.round));
  }, [params.id, game?.round]);

  // Functions
  const percentageToLabel = (percentage) => {
    let label = 'Suboptimal';
    if (percentage >= 34) label = 'Good';
    if (percentage >= 67) return 'Excellent';
    return label;
  };

  const handleDetails = (player_id) => {
    setDetails(player_id);
  };

  const participants = leaderboard
    ? leaderboard
        .filter((p) =>
          game?.groups?.find((g) =>
            g.players?.find((gp) => gp.email === p.email)
          )
        )
        .map((p, pi) => {
          // Values
          const performance = Math.round(p?.performance * 100);
          const decisions_performance = p?.decisions_scores
            ? Math.round((100 * p?.decisions_scores) / p?.decisions_count)
            : 0;
          const asessments_performance = p?.assessments_count
            ? Math.round((100 * p?.assessments_scores) / p?.assessments_count)
            : 0;

          // Player name
          // const playerFullName = `${p?.name} ${p?.lastname}`;
          // const playerDisplayName =
          //   playerFullName.trim().length > 0 ? playerFullName : p?.email;
          const playerName = `${p?.name}`;
          const playerLastName = `${p?.lastname}`;
          const playerDisplayName =
            playerName.length > 0 ? playerName : p?.email;

          const thruIndex = p?.thru ? game?.events_ids?.indexOf(p?.thru) : 0;

          return {
            rank: pi + 1,
            email: p?.email,
            name: playerDisplayName,
            lastname: playerLastName,
            performance:
              p?.thru !== ''
                ? {
                    label: percentageToLabel(performance),
                    value: performance,
                  }
                : '-',
            decisions_performance:
              p?.thru !== ''
                ? {
                    label: percentageToLabel(decisions_performance),
                    value: decisions_performance,
                  }
                : '-',
            assessments_performance:
              p?.thru !== ''
                ? {
                    label: percentageToLabel(asessments_performance),
                    value: asessments_performance,
                  }
                : '-',
            rindex: forceLabels[p?.rindex - 1] || p?.rindex,
            world: p?.world,
            thru: p?.thru !== '' ? thruIndex + 1 : '-',
            action: (
              <button
                className='btn btn-sm btn-outline-dark py-0'
                onClick={() => handleDetails(p?.email)}>
                Details
              </button>
            ),
          };
        })
    : [];

  // Has any participant completed an event
  const hasAnyCompleted = participants.some((p) => p.thru !== '-');

  // Modal player name
  // const modalPlayer = details
  //   ? participants.find((p) => p.email === details)
  //   : null;

  // Run identifier
  const roundIndex = game?.snapshots?.findIndex((s) => s === game.round);
  const roundNumber =
    roundIndex > -1 ? roundIndex + 1 : game?.snapshots?.length + 1;
  // console.log({ roundNumber });

  // Render
  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title'>
        <b>Results</b>: Leaderboard
        <small className='float-end'>
          <DebriefMenu game_id={game._id} location={location} />
        </small>
        <small>
          <CSVLink
            className={`btn btn-sm btn-link ms-3 ${
              hasAnyCompleted ? '' : 'disabled'
            }`}
            // TFF_Leaderboard_[Game Name]_[Run Name/Number]_[Date].csv
            filename={`TFF_Leaderboard_${game?.name}_Run${roundNumber}_${game?.created_at}.csv`}
            // Headers
            headers={[
              { label: 'Rank', key: 'rank' },
              { label: 'First Name', key: 'name' },
              { label: 'Last Name', key: 'lastname' },
              { label: 'Role', key: 'rindex' },
              { label: 'Decisions', key: 'decisions_performance' },
              { label: 'Analysis', key: 'assessments_performance' },
              { label: 'Weighted Performance', key: 'performance' },
              { label: 'Events Completed', key: 'thru' },
              { label: 'World', key: 'world' },
            ]}
            // Data
            data={participants.map((p) => ({
              rank: p.rank,
              name: p.name,
              lastname: p.lastname,
              performance: p.performance.label,
              decisions_performance: p.decisions_performance.label,
              assessments_performance: p.assessments_performance.label,
              role: p.rindex,
              world: p.world,
              thru: p.thru,
            }))}>
            Export data
          </CSVLink>
        </small>
      </h2>

      <div style={{ fontSize: '14px' }}>
        <List
          labels={[
            'Rank',
            'First Name',
            'Last Name',
            'Role',
            'Decisions',
            'Analysis',
            'Weighted Performance',
            'Events Completed',
            'World',
            'Action',
          ]}
          fields={[
            'rank',
            'name',
            'lastname',
            'rindex',
            'decisions_performance',
            'assessments_performance',
            'performance',
            'thru',
            'world',

            'action',
          ]}
          sortables={[
            'rank',
            'name',
            'lastname',
            'rindex',
            'decisions_performance',
            'assessments_performance',
            'performance',
            'thru',
            'world',
          ]}
          data={participants}
        />
      </div>

      <Modal
        isOpen={details ? true : false}
        centered={false}
        size='xxl'
        toggle={() => setDetails(null)}>
        <ModalHeader toggle={() => setDetails(null)}>Details</ModalHeader>
        <ModalBody className='text-center'>
          <PlayerDetails
            playerIdentifier={details}
            playerProgress={participants.find((p) => p?.email === details)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
