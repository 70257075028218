import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DndContext } from '@dnd-kit/core';
import { CSVLink } from 'react-csv';

// Compontents
import Wizard from '../../components/Wizard';
import PlayersList from '../../components/PlayersList';
import PlayersGrid from '../../components/PlayersGrid';
import CSVDrop from '../../components/CSVDrop';
import CSVImporterModal from '../../components/CSVImporterModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faGlobe,
  faLink,
  // faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

// Helpers
// import { socketOn } from '../../helpers';

// Actions
import { gameActions, modalActions } from '../../actions';
import { useLocation, useNavigate } from 'react-router-dom';

// Helpers
import { getSetupValues } from '../../helpers/utils';

// Constants
const forcesNames = [
  { name: 'Focal Firm', id: 4 },
  { name: 'Supplier', id: 1 },
  { name: 'Buyer', id: 2 },
  { name: 'Competitor', id: 5 },
  { name: 'Substitute', id: 3 },
  { name: 'New Entrant', id: 6 },
];

const ForcePlayers = (props) => {
  const { players = [], onChange = null } = props;

  const playerSlots =
    players && players.length > 0
      ? players?.map((p) => {
          const fullName = `${p.name} ${p.lastname}`;
          const plName = fullName.trim().length > 0 ? fullName : p.email;
          return onChange ? (
            <small key={`p-${p._id}`} className='d-block'>
              {p.type === 'bot' ? (
                <i className='text-muted'>Computer</i>
              ) : (
                plName
              )}
            </small>
          ) : (
            <small key={`p-${p._id}`} className='d-block'>
              {p.type === 'bot' ? (
                <i className='text-muted'>Computer</i>
              ) : (
                plName
              )}
            </small>
          );
        })
      : null;

  return playerSlots;
};

const FormSetup = (props) => {
  // ****************
  // COMPONENT STATE
  // ****************
  const [fetching, setFetching] = useState(false);
  const [wizard, setWizard] = useState(true);
  const [item, setItem] = useState({ registered: [] });
  // const [online, setOnline] = useState([]);
  const [search, setSearch] = useState('');
  const [csvImporter, setCSVImporter] = useState(false);
  const [imported, setImported] = useState(null);
  const [resetTime, setResetTime] = useState(Date.now());

  // **************
  // HOOKS + REDUX
  // **************
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.authentication.user);

  // ********
  // EFFECTS
  // ********
  useEffect(() => {
    setItem(game);

    // // Listen to online players socket message
    // if (game?._id) {
    //   socketOn('online_players', (online) => {
    //     console.log('Receiving online_players', online?.length);
    //     setOnline(online);
    //   });
    // }
  }, [game]);

  useEffect(() => {
    if (game?.access_type === 'csv' && item?.access_type === 'link') {
      handleSubmit({ redir: false });
    }
  }, [item?.access_type, game?.access_type]);

  // On game status change
  useEffect(() => {
    if (game?.status === 'launched') {
      setWizard(false);
    }
  }, [game?.status]);

  // ********
  // METHODS
  // ********
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const validationLaunch = () => {
    const isLaunched =
      game?.started && game?.status === 'launched' ? true : false;
    if (isLaunched)
      return dispatch(
        modalActions.open({
          title: 'Session Launched',
          body: 'This session has already been launched. You can not modify its configuration anymore.',
          buttons: (
            <button
              key='modal-btn-close'
              className='btn btn-light'
              onClick={() => dispatch(modalActions.close())}>
              Cancel
            </button>
          ),
        })
      );
    else return false;
  };

  const handleSubmit = ({ redir = false }) => {
    const toSubmit = getSetupValues(item);
    dispatch(gameActions.updateGame(toSubmit));
    // setSubmitted(true);
    if (redir === true)
      navigate(`/games/${item?._id}/setup/events` + location.search, {
        replace: false,
      });
  };

  const handleAutoAssign = (data) => {
    const theData = data?._id ? data : item;

    const players_in = Array.isArray(theData.players_in)
      ? theData.players_in
      : [Number(theData.players_in)];

    dispatch(
      gameActions.autoAssignPlayers({
        _id: theData._id,
        ppg: theData.ppg,
        usersradio: theData.usersradio,
        setup: theData.setup,
        players_in: players_in,
      })
    );
  };

  // Dispatch unassign all players
  const handleUnassign = () => {
    dispatch(gameActions.unassignAllPlayers({ _id: item._id }));
    dispatch(modalActions.close());
  };

  // Handle unassign all buttons click
  const handleUnassignAll = () => {
    // Hide it
    dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <div className='text-center'>
        <p>Are you sure you want to unassign all players?</p>
      </div>
    );

    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Cancel
      </button>,
      <button
        key='modal-btn-close'
        className='btn btn-primary'
        onClick={handleUnassign}>
        Confirm
      </button>,
    ];

    dispatch(
      modalActions.open({
        title: 'Unassign All',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const handleChange = (e) => {
    if (validationLaunch()) return;

    const { name, value } = e.target;
    // const old_access_type = item.access_type;
    let newItem = { ...item };

    if (name === 'players_text') newItem[name] = value.replace(/\t/g, ', ');
    else newItem[name] = value;

    // Flag as changed
    newItem['changed'] = true;

    // // Team
    // if (name === 'setup' && value === 'team') {
    //   newItem['ppg'] = 4;
    //   newItem['players_in'] = 0;
    // }
    // // Individual
    // if (name === 'setup' && value === 'individual') {
    //   newItem['ppg'] = 1;
    //   newItem['players_in'] = 4;
    // }

    setItem(newItem);
    // setSubmitted(false);
  };

  // const removePlayers = (ids) => {
  //   if (item && item._id) {
  //     const modalBody = (
  //       <p className='mt-3 text-center'>
  //         You are about to REMOVE a learner from the session.
  //       </p>
  //     );
  //     const modalButtons = [
  //       <button
  //         key='modal-btn-close'
  //         className='btn btn-light'
  //         onClick={() => dispatch(modalActions.close())}>
  //         Cancel
  //       </button>,
  //       <button
  //         key='modal-btn-delete'
  //         className='btn btn-danger'
  //         onClick={() => {
  //           dispatch(gameActions.removePlayers(item._id, ids));
  //           dispatch(modalActions.close());
  //         }}>
  //         Remove
  //       </button>,
  //     ];
  //     // Open the modal
  //     dispatch(
  //       modalActions.open({
  //         title: 'Please Confirm',
  //         body: modalBody,
  //         buttons: modalButtons,
  //       })
  //     );
  //   }
  // };

  const handleImportSubmit = (importedObjects) => {
    // Validate importedObjects array
    if (!Array.isArray(importedObjects)) return;
    if (importedObjects.length === 0) return;

    // Now, process each player by sending it to the server
    // Capture the response and update the state
    dispatch(
      gameActions.joinMany(game?._id, importedObjects, (done) => {
        setImported(done);
      })
    );
  };

  const readCSV = (content) => {
    const { dispatch } = this.props;
    let item = { ...this.state.item };
    item.players_text = content;
    dispatch(gameActions.updateGame(item));
  };

  const handleGroupChange = (e, player) => {
    const { value } = e.target;
    dispatch(
      gameActions.setPlayerGroup({
        _id: item._id,
        player: player,
        group: value,
      })
    );
  };

  const handlePlayerChange = (currentPlayerId, activeState) => {
    if (currentPlayerId) {
      dispatch(
        gameActions.deallocatePlayer({
          _id: item._id,
          current: currentPlayerId,
          active: activeState,
        })
      );
    }
  };

  const toggleUserConfigTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          Select from two standard configurations, or customize your session.
        </li>
        <li>
          Once you select a configuration, click the button labeled "Auto-assign
          Users".
        </li>
        <li className='mb-2'>
          Note that team-configurations and some individual-configurations
          utilize timers to ensure decisions are submitted simultaneously. The
          no-timer option is only available in sessions with only one individual
          per world.
        </li>
        <li className='mb-2'>
          To restrict the access of certain users who may no longer be enrolled,
          click the "Deactivate" button near that users name.
        </li>
        <li>Once ready, click the "Continue" button.</li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Select user configuration',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const toggleWorldsTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          Akin to breakout groups, each world is an instance in which all six
          forces interact.
        </li>
        <li className='mb-2'>
          Within a world, forces can be managed by users, teams of users, or the
          computer.
        </li>
        <li className='mb-2'>
          Note that the decisions made within a world do not impact the other
          worlds in the session.
        </li>
        <li>Once ready, click the "Continue" button.</li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Worlds & Teams',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const resetSettings = () => {
    const defaultSettings = {
      setup: 'team',
      ppg: 4,
      players_in: [1, 2, 3, 4, 5, 6],
    };

    // The new item is the current values merged with the default settings
    const newItem = { ...item, ...defaultSettings };
    // Set the default settings
    dispatch(gameActions.updateGame(newItem));
    // Clear all user assignments
    dispatch(gameActions.unassignAllPlayers({ _id: item._id }));
    // Close the modal
    dispatch(modalActions.close());

    // Set the reset time
    setResetTime(Date.now());
  };

  const toggleResetSettings = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <div className='text-center'>
        <p>Are you sure you want to reset settings for this step?</p>
        <p>This decision cannot be undone.</p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Cancel
      </button>,
      <button
        key='modal-btn-close'
        className='btn btn-primary'
        onClick={resetSettings}>
        Confirm
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Reset Settings',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  // Return only the first 200 lines from the input string
  const trimLines = (string) => {
    const limit = 200;
    const lines = string.split('\n');
    return lines.slice(0, limit).join('\n');
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    const { player_id } = active?.data?.current;
    const { group_id } = over?.data?.current;

    dispatch(
      gameActions.setPlayerGroup({
        _id: item._id,
        player: player_id,
        group: group_id,
      })
    );
  };

  const isAssigned = (player) => {
    const playerGroup = item?.groups?.find((g) =>
      g.players.find((gp) => gp._id === player._id)
    );
    return playerGroup ? true : false;
  };

  const isOnline = (player) => {
    return game?.online?.includes(player?.email) ? true : false;
  };

  const toggleWizard = () => {
    setWizard(!wizard);
  };

  const handleWizardSubmit = (data) => {
    // Update item state
    const newItem = { ...item, ...data };
    // Auto-assign players
    handleAutoAssign(newItem);
    setWizard(false);
  };

  // COMPONENT LOGIC
  const fromHBP = user && user.from && user.from === 'lti-hbp' ? true : false;
  const isSample = item?.sample === true;
  const isTrial = item?.purchase?.data?.metadata?.trial === true;
  const disabledForm = ['saving', 'launched'].includes(game?.status)
    ? true
    : false;

  // const tempGroups = setGroups(item.players, item.ppg);
  // const maxPlayerPerGroup = item?.groups?.map((g) => g?.players.length);
  const worldRows = item?.groups
    ? item.groups.reduce((worlds, g, i) => {
        const force_id = `force_${g.rindex}`;
        worlds[g.world - 1] = worlds[g.world - 1] ? worlds[g.world - 1] : {};
        worlds[g.world - 1][force_id] = worlds[g.world - 1][force_id]
          ? worlds[g.world - 1][force_id]
          : [];

        worlds[g.world - 1][force_id] = g.players;
        return worlds;
      }, [])
    : [];

  const userPlayers = item.players
    ?.filter((p) => {
      // Filter by search
      if (search.length > 0) {
        const fullName = `${p.name} ${p.lastname}`;
        const plName = fullName.trim().length > 0 ? fullName : p.email;
        const found = plName.toLowerCase().includes(search.toLowerCase());
        return found && p.type === 'user';
      }
      return p.type === 'user';
    })
    .map((p) => {
      const newPlayer = { ...p };
      newPlayer.assigned = isAssigned(p);
      newPlayer.online = isOnline(p);
      return newPlayer;
    });

  // Run identifier
  const roundIndex = item?.snapshots?.findIndex((s) => s === item.round);
  const roundNumber =
    roundIndex > -1 ? roundIndex + 1 : item?.snapshots?.length + 1;
  // CSV data array
  const csvDataArray =
    userPlayers?.map((p) => {
      // Get this player group
      const playerGroup = item?.groups?.find((g) =>
        g.players.find((gp) => gp._id === p._id)
      );

      // Get the world and force
      const world = playerGroup?.world || '';
      const force = forcesNames.find((f) => f.id === playerGroup?.rindex)?.name;

      return {
        name: p.name,
        lastname: p.lastname,
        email: p.email,
        registered: moment(p.created_at).format('YYYY-MM-DD HH:mm:ss'),
        assigned: isAssigned(p) ? 'Yes' : 'No',
        world: world,
        force: force,
      };
    }) || [];

  // Unassigned players are not present in any group
  const assignedPlayers = userPlayers?.filter((p) => isAssigned(p));
  const unassignedPlayers = userPlayers?.filter((p) => !isAssigned(p));

  const forcesOrder = [4, 1, 2, 5, 3, 6];
  const playersList = userPlayers?.map((p, pi) => {
    // List of available world-groups
    const gameWorldsGroups = item?.groups
      ?.sort(
        (a, b) =>
          forcesOrder.indexOf(a?.rindex) - forcesOrder.indexOf(b?.rindex)
      )
      ?.sort((a, b) => a?.world - b?.world)
      .map((g) => (
        <option key={`pl-${p._id}-${g._id}`} value={g._id}>
          World {g?.world} - {forcesNames.find((f) => f.id === g?.rindex)?.name}
        </option>
      ));

    // Get this player group
    const playerGroup = item?.groups?.find((g) =>
      g.players.find((gp) => gp._id === p._id)
    );

    const isActive = p.active ? true : false;

    return (
      <div
        key={`pl-${pi}`}
        className={`d-flex align-items-center p-1 ${
          pi === userPlayers.length - 1 ? '' : 'border-bottom'
        }`}>
        <select
          value={playerGroup ? playerGroup?._id : ''}
          disabled={disabledForm || !isActive}
          onChange={(e) => handleGroupChange(e, p._id)}
          className='form-select form-select-sm w-auto me-3'>
          <option value=''>Unassigned</option>
          {gameWorldsGroups}
        </select>
        <small>
          <b>
            {p.name} {p.lastname}
          </b>
        </small>
        <small className='text-muted ms-2'>{p.email}</small>
        <button
          disabled={disabledForm}
          onClick={(e) => handlePlayerChange(p._id, !isActive)}
          className={`btn btn-sm ${
            isActive ? 'btn-outline-danger' : 'btn-outline-success'
          } ms-auto`}>
          <small>{isActive ? 'Deactivate' : 'Reactivate'}</small>
        </button>
      </div>
    );
  });

  // COMPONENT RENDER
  return (
    <div className='d-flex flex-column h-100'>
      {/* DRAG AND DROP SETUP */}
      {/* {false && ( */}
      <div className='row flex-grow-1'>
        <DndContext onDragEnd={handleDragEnd}>
          {/* LEFT */}
          <div className='col-4 border-end mt-4 mb-4 px-4'>
            <div className='d-flex flex-column flex-xl-row justify-content-between mb-2'>
              <h6 className='text-dark mb-0'>
                Registered Users ({userPlayers?.length}){' '}
              </h6>
              <div>
                {!fromHBP && (
                  <button
                    disabled={disabledForm}
                    className='btn btn-sm btn-link ps-0 ms-0 ms-xl-auto'
                    onClick={() => {
                      setCSVImporter(true);
                      setImported(null);
                    }}>
                    IMPORT USERS
                  </button>
                )}
                {!fromHBP ? '|' : ''}
                <CSVLink
                  className={`btn btn-sm btn-link`}
                  // TFF_Leaderboard_[Game Name]_[Run Name/Number]_[Date].csv
                  filename={`TFF_Players_${item?.name}_Run${roundNumber}_${item?.created_at}.csv`}
                  // Headers
                  headers={[
                    { label: 'First Name', key: 'name' },
                    { label: 'Last Name', key: 'lastname' },
                    { label: 'Email', key: 'email' },
                    { label: 'Registered', key: 'registered' },
                    { label: 'Assigned', key: 'assigned' },
                    { label: 'World', key: 'world' },
                    { label: 'Force', key: 'force' },
                  ]}
                  // Data
                  data={csvDataArray}>
                  EXPORT USERS
                </CSVLink>
              </div>
            </div>

            {/* SEARCH INPUT (filters players) */}
            <input
              type='text'
              className='form-control mt-3'
              placeholder='Search Users'
              aria-label='Search users'
              value={search}
              onChange={handleSearch}
            />

            {/* ACTIVE PLAYERS LIST */}
            <div className='d-flex flex-column flex-xl-row justify-content-between mt-4 mb-3'>
              <h6 className='text-dark'>
                Active Users ({userPlayers?.filter((p) => p?.active).length})
              </h6>
              {item.access_type === 'link' &&
                !isTrial &&
                !isSample &&
                !fromHBP && (
                  <h6 className='d-block text-dark fw-light'>
                    <b>Join Link</b>:{' '}
                    {item.short_url ? (
                      <a
                        href={`https://${item.short_url}`}
                        target='_blank'
                        rel='noreferrer'>
                        {item.short_url}
                      </a>
                    ) : (
                      <a
                        href={
                          process.env.REACT_APP_GAME_URL + '/join/' + item._id
                        }
                        target='_blank'
                        rel='noreferrer'>
                        {process.env.REACT_APP_GAME_URL + '/join/' + item._id}
                      </a>
                    )}
                  </h6>
                )}
            </div>

            <PlayersList players={userPlayers?.filter((p) => p?.active)} />

            {/* INACTIVE PLAYERS LIST */}
            <h6 className='text-dark mt-5 mb-3'>
              Inactive Users ({userPlayers?.filter((p) => !p?.active).length})
            </h6>
            <PlayersList players={userPlayers?.filter((p) => !p?.active)} />
          </div>
          {/* RIGHT */}
          <div className='col-8 mt-4 mb-4 px-4'>
            <div className='d-flex align-items-top justify-content-between mb-3'>
              <div>
                <h6 className='text-dark mb-0'>
                  {wizard ? 'Assignment Recommendations' : 'User Assignment'}
                </h6>
                {!wizard && (
                  <small className='d-inline-block mt-2'>
                    <b>
                      {assignedPlayers?.length}{' '}
                      {assignedPlayers?.length > 1 ? 'users' : 'user'}
                    </b>{' '}
                    assigned to{' '}
                    <b>
                      {worldRows?.length}{' '}
                      {worldRows?.length > 1 ? 'worlds' : 'world'}
                    </b>
                  </small>
                )}
                {wizard && (
                  <a
                    href='https://help.hbsp.harvard.edu/hc/en-us/articles/23728733211027-Five-Forces-Simulation-Choosing-the-optimal-user-assignment-setup-for-your-class'
                    target='_blank'
                    className='btn btn-sm btn-link px-0 mt-2 small'
                    rel='noreferrer noopener'
                    style={{ textDecoration: 'none' }}>
                    <b>LEARN ABOUT SETUP OPTIONS</b>{' '}
                    <FontAwesomeIcon
                      icon={faLink}
                      className='text-primary ms-1'
                    />
                  </a>
                )}
              </div>
              <div>
                <button
                  className={`btn btn-sm ${
                    wizard ? 'btn-outline-primary' : 'btn-primary'
                  }`}
                  disabled={
                    // disabledForm || item?.players?.length === 0 ? true : false
                    disabledForm ? true : false
                  }
                  onClick={toggleWizard}>
                  {wizard
                    ? 'Skip (Manually Assign Users)'
                    : 'Assignment Recommendations'}
                </button>
              </div>
            </div>

            {!wizard && <PlayersGrid />}

            {wizard && (
              <Wizard
                key={`wizard-${resetTime}`}
                online={game?.online ? game?.online : []}
                onCancel={toggleWizard}
                onSubmit={handleWizardSubmit}
              />
            )}
          </div>
        </DndContext>
      </div>
      {/* )} */}

      {/* <hr style={{ margin: '5rem 0' }} /> */}
      {false && (
        <div className='row flex-grow-1'>
          {/* LEFT */}
          <div className='col-4 border-end mt-4 mb-4 px-4'>
            {item?.access_type === 'csv' && (
              <div className='d-nonex row mb-4'>
                {/* <div className='col-6'>
                <div className='form-group'>
                  <div className='input-group'>
                    <input
                      name='name'
                      className='form-control'
                      placeholder='Enter game name'
                      disabled={item.status === 'saving' ? true : false}
                      value={item.name || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div> */}
                <div className='col-12'>
                  <div className='form-group'>
                    <div className='input-group'>
                      <select
                        name='access_type'
                        className='form-control form-select'
                        value={item.access_type || ''}
                        onChange={handleChange}
                        disabled={disabledForm}>
                        <option value='csv'>Upload Emails</option>
                        <option value='link'>Link</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <h6 className='mt-5'>
              Select user configuration{' '}
              <i
                onClick={(e) => toggleUserConfigTooltip(true)}
                style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faInfoCircle} className='text-primary' />
              </i>
              {/* <Tooltip info='hey hey' /> */}
            </h6>
            <div
              className='row text-center mt-3'
              style={{ fontSize: '0.8rem' }}>
              <div className='col-3'>
                &nbsp;
                <div className='mt-2 py-2 fst-italic text-end'>
                  Roles
                  <hr className='my-2' /> Team Size
                </div>
              </div>
              <div className='col-3'>
                <div className='form-check ps-0'>
                  <label className='form-check-label' htmlFor='ppg-team'>
                    Team
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='setup'
                    value='team'
                    checked={item.setup === 'team'}
                    disabled={disabledForm}
                    id='ppg-team'
                    onChange={handleChange}
                  />
                </div>
                <div className='card p-2'>
                  All Roles
                  <hr className='my-2' />4
                </div>
              </div>
              <div className='col-3'>
                <div className='form-check ps-0'>
                  <label className='form-check-label' htmlFor='ppg-individual'>
                    Solo Play
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='setup'
                    value='individual'
                    checked={item.setup === 'individual'}
                    disabled={disabledForm}
                    id='ppg-individual'
                    onChange={handleChange}
                  />
                </div>
                <div className='card p-2'>
                  Focal Firm
                  <hr className='my-2' />1
                </div>
              </div>
              <div className='col-3'>
                <div className='form-check ps-0'>
                  <label className='form-check-label' htmlFor='setup-custom'>
                    Custom
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='setup'
                    value='custom'
                    checked={item.setup === 'custom'}
                    disabled={disabledForm}
                    id='setup-custom'
                    onChange={handleChange}
                  />
                </div>
                <div className='card p-2'>
                  -
                  <hr className='my-2' />-
                </div>
              </div>
            </div>
            {['custom'].includes(item?.setup) && (
              <div className='mt-4'>
                <div className='d-flex align-items-center'>
                  <small className='w-50 text-end me-3'>
                    Roles with users:
                  </small>
                  <select
                    multiple={true}
                    name='players_in'
                    className='form-control form-select-sm mt-1'
                    style={{ appearance: 'auto' }}
                    value={item.players_in || ''}
                    onChange={handleChange}
                    disabled={disabledForm}>
                    <option value='0'>All Roles </option>
                    {forcesNames.map((f, fi) => (
                      <option key={`op-${f.id}`} value={f.id}>
                        {f.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='d-flex align-items-center mt-2'>
                  <small className='w-50 text-end me-3'>Users per team:</small>
                  <select
                    name='ppg'
                    className='form-control form-select-sm mt-1'
                    style={{ appearance: 'auto' }}
                    value={item.ppg || ''}
                    onChange={handleChange}
                    disabled={disabledForm}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                  </select>
                </div>
              </div>
            )}

            <div className='col-12 mt-4'>
              <button
                disabled={disabledForm}
                className='btn btn-sm btn-outline-primary w-100 mb-3'
                onClick={handleAutoAssign}>
                Auto-assign users
              </button>
            </div>
          </div>

          {/* RIGHT */}
          <div className='col-8 mt-4 mb-4 px-4'>
            {/* The following code will be refactored into a separate component */}
            <div className='form-group mt-5'>
              <h6>
                Registered users:{' '}
                {item?.registered ? item?.registered?.length : 0}
                {/* <span className='badge bg-warning badge-pill'>
                {item?.registered ? item?.registered?.length : 0}
              </span> */}
                <span className='badge bg-secondary badge-pill ms-2'>
                  Unassigned Players: {unassignedPlayers?.length}
                </span>
                {item.access_type === 'link' && (
                  <small
                    className='btn btn-link btn-sm ml-2 font-italic'
                    onClick={(e) => {
                      setFetching(true);
                      dispatch(gameActions.fetchGames(item._id));
                      setTimeout(() => setFetching(false), 1000);
                    }}>
                    {fetching ? 'Refreshing...' : 'Refresh list'}
                  </small>
                )}
                {item.access_type === 'link' &&
                  !isTrial &&
                  !isSample &&
                  !fromHBP && (
                    <span
                      className='py-1 px-2 rounded-3 float-end'
                      style={{ backgroundColor: '#DDD' }}>
                      <code>
                        User Registration Link:{' '}
                        {item.short_url ? (
                          <a
                            href={`https://${item.short_url}`}
                            target='_blank'
                            rel='noreferrer'>
                            {item.short_url}
                          </a>
                        ) : (
                          <a
                            href={
                              process.env.REACT_APP_GAME_URL +
                              '/join/' +
                              item._id
                            }
                            target='_blank'
                            rel='noreferrer'>
                            {process.env.REACT_APP_GAME_URL +
                              '/join/' +
                              item._id}
                          </a>
                        )}
                      </code>
                    </span>
                  )}
              </h6>
              {/* {item.registered.length % item.ppg !== 0 && (
              <small
                style={{
                  fontSize: '12px',
                  padding: '5px 8px',
                  verticalAlign: 'middle',
                }}
                className='badge badge-pill badge-danger float-end'>
                This game needs {item.ppg} players per group. Add more players.
              </small>
            )} */}
              {item.access_type === 'csv' && (
                <CSVDrop onComplete={readCSV}>
                  <div className='input-group mb-2'>
                    <textarea
                      name='players_text'
                      className='form-control'
                      placeholder='When uploading a comma-separated values file (.csv), please format with email addresses in the first column, first names in the second column, and last names in the third column. You can also copy the contents of the .csv and paste them into this field.'
                      value={trimLines(item.players_text || '')}
                      onChange={handleChange}
                      rows='10'
                      disabled={disabledForm}></textarea>
                  </div>
                  <small>
                    <b>Note</b>: Maximum 200 rows/users permitted
                  </small>
                </CSVDrop>
              )}
              {item.access_type === 'link' && (
                <div className='card w-100'>
                  <div
                    className='card-body p-1 overflow-auto w-100'
                    style={{ maxHeight: '30vh' }}>
                    {playersList.length > 0 ? (
                      playersList
                    ) : (
                      <div className='py-2 px-3'>
                        <small>No users registered yet.</small>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {playersList?.length > 0 && (
                <button
                  disabled={
                    isSample || unassignedPlayers?.length === userPlayers.length
                      ? true
                      : false
                  }
                  onClick={handleUnassignAll}
                  className='btn btn-sm btn-link mt-2 float-end'>
                  Unassign all
                </button>
              )}
            </div>
          </div>

          <div className='col-12 px-4 border-top pt-4'>
            <table className='table table-smx table-striped'>
              <thead>
                <tr>
                  <th className='fw-normal text-center'>
                    World{' '}
                    <i
                      onClick={(e) => toggleWorldsTooltip(true)}
                      style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='text-primary'
                      />
                    </i>
                  </th>
                  <th className='fw-normal text-center'>Users Per Team</th>
                  <th className='fw-normal'>Focal Firm</th>
                  <th className='fw-normal'>Supplier</th>
                  <th className='fw-normal'>Buyer</th>
                  <th className='fw-normal'>Competitor</th>
                  <th className='fw-normal'>Substitute</th>
                  <th className='fw-normal'>New Entrant</th>
                </tr>
              </thead>
              <tbody>
                {worldRows.map((worldRow, ri) => {
                  // Determine number of user per team in this world
                  const userPlayers = Object.values(worldRow).filter((f) =>
                    f.every((p) => p.type === 'user')
                  );
                  const worldPPG = userPlayers.every((f, fi, farray) => {
                    return f.length === farray[0].length;
                  })
                    ? userPlayers[0]
                      ? userPlayers?.[0].length
                      : 0
                    : 'Varied';
                  return (
                    <tr key={`row-${ri}`}>
                      <td className='text-center'>
                        <FontAwesomeIcon
                          icon={faGlobe}
                          className='text-primary me-1'
                        />{' '}
                        World {ri + 1}
                      </td>
                      <td className='text-center'>
                        {worldPPG}
                        {/* {game.ppg} */}
                      </td>

                      {/* Focal Firm */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_4}
                        />
                      </td>

                      {/* Suppliers */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_1}
                        />
                      </td>

                      {/* Buyers */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_2}
                        />
                      </td>

                      {/* Competitors */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_5}
                        />
                      </td>

                      {/* Substitutes */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_3}
                        />
                      </td>

                      {/* New Entrants */}
                      <td>
                        <ForcePlayers
                          onChange={
                            item?.setup === 'custom' ? handlePlayerChange : null
                          }
                          players={worldRow.force_6}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* CSV IMPORTER MODAL */}
      <CSVImporterModal
        isOpen={csvImporter}
        onClose={() => {
          setCSVImporter(false);
          setImported(null);
        }}
        onSubmit={handleImportSubmit}
        success={imported}
      />

      {/* FOOTER */}
      <footer className='d-flex align-items-center bg-white p-3'>
        <button
          disabled={disabledForm}
          onClick={toggleResetSettings}
          className='btn btn-outline-primary me-auto'>
          Reset Settings
        </button>

        {/* Debug */}
        {user?.role === 'administrator' && (
          <div className='d-flex d-align-center ms-auto me-4'>
            <small className='d-inline-block me-2'>
              Players: {item?.players?.length}
            </small>
            <small className='d-inline-block me-2'>
              Groups: {item?.groups?.length}
            </small>
          </div>
        )}

        {['administrator', 'manager'].includes(user?.role) && (
          <div className='input-group input-group-sm w-auto me-4'>
            <select
              name='status'
              className='form-control form-select'
              value={item.status || ''}
              onChange={handleChange}
              disabled={
                item.status === 'saving' || disabledForm ? true : false
              }>
              <option value='saved'>Saved</option>
              <option value='launched'>Launched</option>
              <option value='deleted'>Deleted</option>
            </select>
          </div>
        )}

        {['administrator', 'manager'].includes(user?.role) && (
          <span className='me-5 text-muted'>
            <small>Title: {item?.name}</small>
          </span>
        )}

        <small className='me-4 text-muted'>
          {item.status === 'saving'
            ? 'Saving...'
            : `Saved on: ${moment(item?.updated_at).format(
                'MMMM D, YYYY [at] h:mm A'
              )}`}
        </small>

        <button
          disabled={disabledForm || assignedPlayers?.length === 0}
          className='btn btn-outline-primary me-4'
          onClick={handleSubmit}>
          Save
        </button>

        <button
          disabled={disabledForm || assignedPlayers?.length === 0}
          className='btn btn-primary'
          onClick={(e) => handleSubmit({ redir: true })}>
          Save &amp; Continue
        </button>

        {/* {!submitted && (
          <button className='btn btn-primary' onClick={handleSubmit}>
            Submit
          </button>
        )}
        {submitted && (
          <Link to={`../events`} className='btn btn-primary'>
            Continue
          </Link>
        )} */}
      </footer>
    </div>
  );
};

export default FormSetup;
