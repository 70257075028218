import React from 'react';

const DecisionsTable = (props) => {
  // Get the props
  const { data = [] } = props;

  // console.log(data);

  // Logic
  const rows = data.map((g, gi) => {
    // Decisions
    const decisions = g?.decisions?.map((d, di) => (
      <li>
        <small key={`d-${di}`}>{d?.description}</small>
      </li>
    ));

    // Players
    const learners = g.players?.map((p, pi) => {
      const displayName =
        `${p.name} ${p.lastname}`.trim().length > 0
          ? `${p.name} ${p.lastname}`
          : p.email;
      return (
        <p key={`player-${pi}`} className='mb-0'>
          <small>{displayName}</small>
        </p>
      );
    });

    return (
      <tr
        key={`row-${gi}`}
        className={g?.world_n % 2 === 0 ? 'bg-light' : 'bg-white'}>
        <td>{g?.world}</td>
        <td>{learners}</td>
        <td>
          <ul>{decisions}</ul>
        </td>
        <td>
          <small>{g?.reflections?.why}</small>
        </td>
        <td>
          <small>{g?.reflections?.other}</small>
        </td>
      </tr>
    );
  });

  // Render
  return (
    <div>
      <table className='table table-sm'>
        <thead>
          <tr>
            <td>World</td>
            <td>Learners</td>
            <td>Decisions</td>
            <td>Reflection: Rationale</td>
            <td>Reflection: Alternatives</td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default DecisionsTable;
