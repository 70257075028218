import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faGlobe,
  faInfo,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

// Actions
import { modalActions } from '../actions';

// Components
import UniversePreview from './UniversePreview';

// Add FontAwesome icons
library.add([faInfo, faInfoCircle, faGlobe]);

const sample_roles = {
  focalFirm: 4,
  supplier: 1,
  buyer: 2,
  competitor: 5,
  substitute: 3,
  newEntrant: 6,
};

const sample_roles_labels = {
  focalFirm: 'Focal Firm',
  supplier: 'Supplier',
  buyer: 'Buyer',
  competitor: 'Competitor',
  substitute: 'Substitute',
  newEntrant: 'New Entrant',
};

const Wizard = ({ online = [], onCancel = null, onSubmit = null }) => {
  // STATE
  const [formData, setFormData] = useState({
    ppg: 1,
    players_in: [1, 2, 3, 4, 5, 6],
    usersradio: 'allusers',
  });

  // HOOKS
  const dispatch = useDispatch();
  const item = useSelector((state) => state.game);

  // METHODS
  const handleChange = (e) => {
    // Field value and name
    const value = e.target.value;
    const name = e.target.name;

    const newFormData = { ...formData, [name]: value };

    // Async runmethod sets
    if (name === 'runmethod' && value === 'async') {
      newFormData.usermethod = 'single_pc';
      newFormData.ppg = 1;
      newFormData.players_in = [4];
    }

    // Semi-sync runmethod sets
    // Check if usermethod is single_pc, in which case remove the value
    if (name === 'runmethod' && value === 'semi') {
      if (formData.usermethod === 'single_pc') {
        delete newFormData.usermethod;
      }
    }

    // Force ppg to 1 if single_pc or single_single
    if (['single_pc', 'single_single'].includes(value)) {
      newFormData.ppg = 1;
    }

    // Set players_in to focalFirm if single_pc or multi_pc
    if (['single_pc', 'multi_pc'].includes(value)) {
      newFormData.players_in = [4];
    }

    // Set players_in to all the options if single_single or multi_multi
    if (['single_single', 'multi_multi'].includes(value)) {
      newFormData.players_in = [1, 2, 3, 4, 5, 6];
    }

    setFormData(newFormData);
    // console.log(newFormData);
  };

  const handleCheckboxChange = (e) => {
    // Field value and name
    const value = e.target.value;
    const checked = e.target.checked;

    // Get current players_in array
    const players_in = [...formData.players_in] || [];
    if (checked) {
      players_in.push(parseInt(value));
    } else {
      const index = players_in.indexOf(parseInt(value));
      if (index > -1) {
        players_in.splice(index, 1);
      }
    }

    const newFormData = { ...formData, players_in: players_in.sort() };
    setFormData(newFormData);
  };

  const handleAutoAssign = () => {
    // Display a confirmation modal
    dispatch(
      modalActions.open({
        title: 'Auto Assign Users',
        body: (
          <div>
            <p>
              All current team assignments will be cleared and replaced with new
              assignments. This action cannot be undone.
            </p>
            <p>
              <b>Are you sure you want to proceed?</b>
            </p>
          </div>
        ),
        buttons: [
          <button
            key='modal-btn-close'
            className='btn btn-outline-secondary me-3'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            key='modal-btn-confirm'
            className='btn btn-primary'
            onClick={() => {
              onSubmit(formData);
              dispatch(modalActions.close());
            }}>
            Confirm
          </button>,
        ],
      })
    );
  };

  // LOGIC
  const activePlayers = item?.players?.filter(
    (player) => player.active && player.type === 'user'
  );
  const onlineActivePlayers = activePlayers?.filter((player) =>
    online?.includes(player.email)
  );

  const canSubmit = formData?.runmethod && formData?.usermethod;

  return (
    <div className='container-fluid bg-white border rounded-1 py-3 px-3'>
      {/* <a
        href='#'
        className='btn btn-sm btn-link px-0 mb-3 small'
        rel='noreferrer noopener'
        style={{ textDecoration: 'none' }}>
        <b>LEARN ABOUT SETUP OPTIONS</b>
      </a> */}

      {/* ROW 1 */}
      <h6 className='mb-3'>Choose run method</h6>

      <div className='row'>
        <div className='col-4'>
          <div className='form-check'>
            <input
              id='runmethodradio-1'
              name='runmethod'
              value='sync'
              className='form-check-input mt-4'
              type='radio'
              onChange={handleChange}
            />
            <label className='form-check-label' htmlFor='runmethodradio-1'>
              Synchronous
              <br />
              <small className='text-black-50'>
                Choose synchronous if all users will play the simulation during
                a single class session.
              </small>
            </label>
          </div>
        </div>
        <div className='col-4'>
          <div className='form-check'>
            <input
              id='runmethodradio-2'
              name='runmethod'
              value='semi'
              className='form-check-input mt-4'
              type='radio'
              onChange={handleChange}
            />
            <label className='form-check-label' htmlFor='runmethodradio-2'>
              Semi-Synchronous
              <br />
              <small className='text-black-50'>
                Choose semi-synchronous if users will play in groups outside of
                a single session, during a common time of their choosing.
              </small>
            </label>
          </div>
        </div>
        <div className='col-4'>
          <div className='form-check'>
            <input
              id='runmethodradio-3'
              name='runmethod'
              value='async'
              className='form-check-input mt-4'
              type='radio'
              onChange={handleChange}
            />
            <label className='form-check-label' htmlFor='runmethodradio-3'>
              Asynchronous
              <br />
              <small className='text-black-50'>
                Choose asynchronous if all users will play the simulation
                outside of a single session, during a time of their choosing.
              </small>
            </label>
          </div>
        </div>
      </div>

      <hr />

      {/* ROW 2 */}
      <h6 className='mb-3'>Choose user assignment method</h6>

      <div className='row'>
        <div className='col'>
          <div className='form-check'>
            <input
              className='form-check-input mt-4'
              type='radio'
              name='usermethod'
              value='single_pc'
              id='single_pc'
              disabled={!['sync', 'async'].includes(formData.runmethod)}
              onChange={handleChange}
              checked={formData.usermethod === 'single_pc'}
            />
            <label className='form-check-label' htmlFor='single_pc'>
              Single-player vs. Computers
              <br />
              <small className='text-black-50'>
                Users will individually play a role in their own world. Computer
                players will comprise the other roles in each users's world
              </small>
            </label>
          </div>
          <div className='form-check mt-4'>
            <input
              className='form-check-input mt-4'
              type='radio'
              name='usermethod'
              value='multi_pc'
              id='multi_pc'
              disabled={!['sync', 'semi'].includes(formData.runmethod)}
              onChange={handleChange}
              checked={formData.usermethod === 'multi_pc'}
            />
            <label className='form-check-label' htmlFor='multi_pc'>
              Multi-player vs. Computers
              <br />
              <small className='text-black-50'>
                Users will cooperatively play a role in their own world.
                Computer players will comprise the other roles in each team's
                world
              </small>
            </label>
          </div>
        </div>
        <div className='col'>
          <div className='form-check'>
            <input
              className='form-check-input mt-4'
              type='radio'
              name='usermethod'
              value='single_single'
              id='single_single'
              disabled={!['sync', 'semi'].includes(formData.runmethod)}
              onChange={handleChange}
              checked={formData.usermethod === 'single_single'}
            />
            <label className='form-check-label' htmlFor='single_single'>
              Single-player vs. Single-player
              <br />
              <small className='text-black-50'>
                Users will individually play a role in a world, with up to six
                users comprising a world. Computer players will fill any open
                roles.
              </small>
            </label>
          </div>
          <div className='form-check mt-4'>
            <input
              className='form-check-input mt-4'
              type='radio'
              name='usermethod'
              value='multi_multi'
              id='multi_multi'
              disabled={!['sync', 'semi'].includes(formData.runmethod)}
              onChange={handleChange}
              checked={formData.usermethod === 'multi_multi'}
            />
            <label className='form-check-label' htmlFor='multi_multi'>
              Multi-player vs. Multi-Player
              <br />
              <small className='text-black-50'>
                Users will cooperatively play a role in a world, with up to six
                users playing each role. Computer players will fill any open
                roles.
              </small>
            </label>
          </div>
        </div>
      </div>

      <div className='mt-5 bg-light p-3'>
        <p className='mb-0'>
          <FontAwesomeIcon icon={faGlobe} className='text-black' /> A{' '}
          <b>world</b> is defined as an instance with full set of forces(roles),
          all interacting with each other during the run. Users playing in one
          world do not affect or interact with users in any other world.
        </p>
      </div>

      <hr />

      <div className='row py-3'>
        <div className='col'>
          <h6 className='mb-3'>
            Roles to Assign{' '}
            {/* <FontAwesomeIcon icon={faInfoCircle} className='text-black' /> */}
          </h6>

          {Object.keys(sample_roles).map((role) => {
            const isChecked = formData.players_in.includes(sample_roles[role]);
            const roleLabel = sample_roles_labels[role];
            return (
              <div className='' key={role}>
                <input
                  id={role}
                  name={role}
                  className='form-check-input'
                  type='checkbox'
                  value={sample_roles[role]}
                  style={{ marginTop: '5px', height: '16px' }}
                  onClick={handleCheckboxChange}
                  disabled={formData.usermethod ? false : true}
                  checked={isChecked}
                />
                <label className='form-check-label ms-2' htmlFor={role}>
                  {roleLabel}
                </label>
              </div>
            );
          })}
        </div>
        <div className='col'>
          <h6 className='mb-3'>
            Users per Role{' '}
            {/* <FontAwesomeIcon icon={faInfoCircle} className='text-black' /> */}
          </h6>
          {/* TODO: add select number +/- */}
          <input
            type='number'
            name='ppg'
            value={formData.ppg}
            onChange={handleChange}
            min={1}
            max={6}
            className='form-control'
            style={{ width: '100px' }}
            disabled={
              !formData.usermethod ||
              ['single_pc', 'single_single'].includes(formData.usermethod)
            }
          />
        </div>
        <div className='col'>
          <h6 className='mb-3'>Users to Assign</h6>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='usersradio'
              value='allusers'
              id='allusers'
              disabled={formData.usermethod ? false : true}
              onChange={handleChange}
              checked={formData.usersradio === 'allusers'}
            />
            <label className='form-check-label' htmlFor='allusers'>
              All Active Users ({activePlayers?.length})
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='usersradio'
              value='activeusers'
              id='activeusers'
              onChange={handleChange}
              disabled={formData.usermethod ? false : true}
              checked={formData.usersradio === 'activeusers'}
            />
            <label className='form-check-label' htmlFor='activeusers'>
              Online Active Users Only ({onlineActivePlayers?.length})
            </label>
          </div>
        </div>
      </div>

      {formData?.usermethod && (
        <div>
          <hr />

          {/* ROW 3 */}
          <div className='row'>
            <UniversePreview
              ppr={formData?.ppg}
              players_in={formData?.players_in}
              usermethod={formData?.usermethod}
            />
          </div>

          <hr />
        </div>
      )}

      <div className='d-flex py-3'>
        <div className='colx'>
          <button
            // onClick={() => onSubmit(formData)}
            disabled={!canSubmit}
            onClick={handleAutoAssign}
            className='btn btn-primary btn-lg'>
            Auto Assign Users
          </button>
        </div>
        <div className='ms-auto'>
          <button
            type='button'
            className='btn btn-outline-primary btn-lg float-end'
            onClick={onCancel}>
            Skip (Manually Assign Users)
          </button>
        </div>
      </div>
    </div>
  );
};

export default Wizard;
