// import React, { useEffect } from 'react';
// import { Routes, Route } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { setupSocket, enterBackend, socketOn } from '../../helpers';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Header from '../../components/Header';

// Form Components
import Submenu from './form_submenu';
import FormSetup from './form_setup';
import FormEvents from './form_events';
import FormExperience from './form_experience';
import FormRun from './form_run';

// Actions
// import { gameActions } from '../../actions';

const GameForm = (props) => {
  // Props

  // State

  // Hooks
  // const dispatch = useDispatch();
  const game = useSelector((state) => state.game);

  // // Effects
  // useEffect(() => {
  //   if (game?._id) {
  //     // Fetch game api data && connects to WS
  //     fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
  //       .then((res) => res.json())
  //       .then((data) => {
  //         // setupSocket(data.io_domain, null, () => {
  //         //   if (game?._id) {
  //         //     // enterBackend({ game_id: game._id }, (gameData) => {
  //         //     //   console.log(`Connected to ${gameData._id} ws backend.`);
  //         //     // });
  //         //     // // Sockets!
  //         //     // socketOn('player', () => {
  //         //     //   dispatch(gameActions.fetchGames(game._id));
  //         //     // });
  //         //   }
  //         // });
  //       });
  //   }
  // }, [game?._id, dispatch]);

  // Functions

  // Logic

  const { pathname } = window.location;
  // console.log(pathname);
  let title = 'Users';
  if (pathname.includes('/users')) title = 'Users';
  if (pathname.includes('/events')) title = 'Events';
  if (pathname.includes('/experience')) title = 'Experience';
  if (pathname.includes('/run')) title = 'Run';

  // Render
  if (!game._id) return <div>Loading...</div>;

  // if (game.status === 'launched' && game.started === true)
  //   return <Navigate to={'/games/' + game._id + '/players'} />;

  return (
    <div className='d-flex flex-column vh-100'>
      <Header />

      {/* MAIN */}
      <div className='d-flex flex-column flex-grow-1'>
        {/* Submenu */}
        <div className='submenu-wrap d-flex align-itens-center border-bottom py-2'>
          <div className='title-wrap me-auto'>
            <h4 className='fw-light m-0'>
              <b>Setup</b>: {title}
            </h4>
          </div>
          <div className='submenu-wrap ms-auto'>
            <Submenu game_id={game._id} />
          </div>
        </div>
        {/* Content */}
        <Routes>
          <Route exact path={'/'} element={<FormSetup />} />
          <Route exact path={'/users'} element={<FormSetup />} />
          <Route exact path={'/events'} element={<FormEvents />} />
          <Route exact path={'/experience'} element={<FormExperience />} />
          <Route exact path={'/run'} element={<FormRun />} />
        </Routes>
      </div>
    </div>
  );
};

export default GameForm;
