import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PlayerSurvey = (props) => {
  // Props
  const { player = null } = props;

  // State
  const [force, setForce] = useState(1);

  // Hooks
  const game = useSelector((state) => state.game);

  const handleForce = (id) => setForce(id);

  // Logic
  const thePlayer = game?.players?.find((p) => p.email === player);

  // Force buttons
  const forceButtons = [
    { name: 'Focal Firm', id: 4 },
    { name: 'Supplier', id: 1 },
    { name: 'Buyer', id: 2 },
    { name: 'Competitor', id: 5 },
    { name: 'Substitute', id: 3 },
    { name: 'New Entrant', id: 6 },
  ].map((b, bi) => {
    return (
      <div key={b.name} className='col-12 mt-3'>
        <button
          className={
            b.id === force
              ? 'd-block w-100 btn btn-outline-primary'
              : 'd-block w-100 btn btn-white'
          }
          onClick={() => handleForce(b.id)}>
          {b.name}
        </button>
      </div>
    );
  });

  const surveysPre = [thePlayer]?.map((p) => {
    // return p?.survey_initial || null;
    return p?.survey_initial &&
      Object.keys(p?.survey_initial).length === 6 &&
      Object.keys(p?.survey_initial).every((k) => p?.survey_initial[k] !== null)
      ? p?.survey_initial
      : null;
  });

  const surveysPost = [thePlayer]?.map((p) => {
    // return p?.survey_final || null;
    return p?.survey_final &&
      Object.keys(p?.survey_final).length === 6 &&
      Object.keys(p?.survey_final).every((k) => p?.survey_final[k] !== null)
      ? p?.survey_final
      : null;
  });

  const totalPre = surveysPre?.reduce(
    (acc, s) => {
      if (!s) return acc;

      const newAcc = [...acc];
      const selectedForceValue = s[`force_${force}`];
      // Add +1 to the selected force value (High, Medium, Low)
      if (typeof selectedForceValue !== 'undefined')
        newAcc[2 - selectedForceValue] += 1;
      return newAcc;
    },
    [0, 0, 0]
  ) || [0, 0, 0];

  const totalPost = surveysPost?.reduce(
    (acc, s) => {
      if (!s) return acc;

      const newAcc = [...acc];
      const selectedForceValue = s[`force_${force}`];
      // Add +1 to the selected force value (High, Medium, Low)
      if (typeof selectedForceValue !== 'undefined')
        newAcc[2 - selectedForceValue] += 1;
      return newAcc;
    },
    [0, 0, 0]
  ) || [0, 0, 0];

  // No response
  if (!thePlayer?.survey_initial || !thePlayer?.survey_final)
    return (
      <div className='row m-5'>
        <div className='col'>
          <div className='my-5'>No response.</div>
        </div>
      </div>
    );

  return (
    <div className='row mt-5'>
      <div className='col-2'>
        <h6 className='mt-0'>Perceived Power</h6>
        <div className='row'>{forceButtons}</div>
      </div>
      <div className='col-10 bg-white rounded-3'>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: { text: '' },
            chart: { type: 'column', animation: false },
            credits: { enabled: false },
            legend: { enabled: true },
            xAxis: {
              title: { text: 'Power' },
              categories: ['Low', 'Medium', 'High'],
              showEmpty: true,
            },
            yAxis: {
              title: { text: 'Count' },
              allowDecimals: false,
            },
            series: [
              {
                name: 'Initial',
                data: totalPre,
              },
              {
                name: 'Final',
                data: totalPost,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default PlayerSurvey;
