import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

// import queryString from 'query-string';
// import { history } from './helpers';

// import { PrivateRoute } from './components/PrivateRoute';
// import { HomePage } from './containers/home';

import { HomePage } from './containers/Home';
import { AuthPage } from './containers/Auth';
import { GamesPage } from './containers/games/index';
import { GameItem } from './containers/games/item';
import { GameNew } from './containers/games/new';

// GLOBAL MODALS
import ConfirmModal from './components/ComfirmModal';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
  }

  // componentDidMount(){}

  render() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div id='app' className='col-12 bg-light'>
            <BrowserRouter basename='/admin'>
              <Routes>
                <Route exact path='/' element={<HomePage />} />

                <Route exact path='/authenticate' element={<AuthPage />} />

                <Route
                  exact
                  path='/logout'
                  component={(props) => {
                    localStorage.removeItem('scjwt');
                    props.history.push('/');
                    return null;
                  }}
                />

                <Route exact path='/games' element={<GamesPage />} />

                <Route exact path='/games/new' element={<GameNew />} />

                <Route path='/games/:id/*' element={<GameItem />} />
              </Routes>
            </BrowserRouter>

            <ConfirmModal />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default connect(mapStateToProps)(App);
