import React from 'react';
import PptxGenJS from 'pptxgenjs';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Data
import { scenarios } from '../constants';

// Assets
import pptTopChocolate from '../assets/ppt-top-chocolate.png';
import pptTopEscooter from '../assets/ppt-top-escooter.png';

// Constants
const forceButtons = [
  'Focal Firm',
  'Supplier',
  'Buyer',
  'Competitor',
  'Substitute',
  'New Entrant',
];

const alternatives = ['Low', 'Medium', 'High'];
const forcesIds = [4, 1, 2, 5, 3, 6];

// Colors
const colorRed = 'B10021';
const colorBlack = '000000';
const colorGrey = '999999';

// Slides config
// const slidesConfig = {
//   x: 0,
//   y: 0,
//   w: 10,
//   h: 7.5,
// };

// Slides config
const fullPageConfig = {
  x: 0,
  y: 0,
  w: 10,
  h: 7.5,
  valign: 'middle',
  align: 'center',
};

// Slide title config
const slideTitleConfig = {
  x: 0.25,
  y: 0.35,
  w: 9.25,
  h: 1,
  fontFace: 'Carlito',
  fontSize: 24,
  color: colorRed,
  // underline: {
  //   color: colorBlack,
  //   style: 'dotted',
  // },
};
// Slide title config
const slideTextConfig = {
  x: 0.5,
  y: 1.5,
  w: 9,
  h: 6,
  fontFace: 'Carlito',
  fontSize: 18,
  valign: 'top',
  color: colorBlack,
};

// Charts config
const chartsConfig = {
  x: 1,
  y: 1.25,
  w: 8,
  h: 5.5,
};

const PresentationBtn = (props) => {
  const { disabled = false } = props;
  // Hooks
  const game = useSelector((state) => state.game);
  // const roundIndex = game?.snapshots?.findIndex((s) => s === game.round);
  // const roundNumber = roundIndex > -1 ? roundIndex + 1 : game?.snapshots?.length + 1;
  // console.log({ roundNumber });

  // Methods
  const handleClick = () => {
    console.log('Generating presentation...');

    // Key data
    const totalEvents = game?.events_ids?.filter((e) => e !== '')?.length;
    const eventsString = totalEvents > 1 ? 'Events' : 'Event';
    const runStartedAt = game?.started_at || Date.now();
    const runDate = moment(runStartedAt).format('M/D/YY h:mm:ssa');
    //console.log({ runDate });

    // 1. Create a Presentation
    let pres = new PptxGenJS();

    // Define layout
    pres.layout = 'LAYOUT_4x3';

    // Define slide master (top banner and footer)
    pres.defineSlideMaster({
      title: 'MASTER_SLIDE',
      background: { color: 'FFFFFF' },
      objects: [
        {
          text: {
            text: 'Copyright © 2022 Harvard Business Publishing  ',
            options: {
              x: 0,
              y: 0,
              w: '100%',
              h: 7.4,
              fill: 'FFFFFF',
              fontSize: 9,
              color: '888888',
              align: 'center',
              valign: 'bottom',
            },
          },
        },
        {
          // rect: { x: 0, y: 0, w: '100%', h: 0.25, fill: { color: '000000' } },
          image: {
            x: 0,
            y: 0,
            w: 10,
            h: 0.3125,
            path:
              game?.scenario === 'chocolate' ? pptTopChocolate : pptTopEscooter,
            sizing: { type: 'contain', w: 10, h: 0.3125 },
          },
        },
      ],
    });

    // Page 1 - Static - Your Role versus Other Roles
    let slide1 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    const scenarioTitle =
      game?.scenario === 'chocolate' ? 'Gourmet Chocolate' : 'Escooter';
    slide1.addText(
      [
        {
          text: 'Strategy Simulation:',
          options: { fontSize: 36, color: colorRed },
        },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'The Five Forces',
          options: { fontSize: 36, color: colorRed },
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        { text: 'Debrief Slides', options: { fontSize: 30, breakLine: true } },
        {
          text: `${scenarioTitle} Industry`,
          options: { fontSize: 20, breakLine: true },
        },
        { text: ' ', options: { breakLine: true } },
        { text: 'HBP No. 7884', options: { fontSize: 24, breakLine: true } },
        {
          text: `${totalEvents} ${eventsString} | Run: ${runDate}`,
          options: { fontSize: 12, color: colorGrey, breakLine: true },
        },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'This PowerPoint presentation was prepared by Professor Rachel Wilson of Belmont University for the sole purpose of aiding classroom instructors in the use of Strategy Simulation: The Five Forces (HBP No. 7880). HBP educational materials are developed solely as the basis for class discussion. These materials are not intended to serve as endorsements, sources of primary data, or illustrations of effective or ineffective management.',
          options: { fontSize: 8, color: '', breakLine: true },
        },
      ],
      {
        ...fullPageConfig,
        y: 1,
        h: 6.5,
        x: 0.5,
        w: 9,
        // h: 5,
        // inset: 0.5,
        // valign: 'middle',
        // line: { width: '2', color: 'B10021' },
      }
    );

    // Page 2 - Static - Your Role versus Other Roles
    let slide2 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide2.addText('Note to Instructors', {
      ...slideTitleConfig,
      y: 0.5,
      fontSize: 36,
    });
    slide2.addText(
      [
        {
          text: 'Please treat the information in this presentation as you would a teaching note.',
          options: { fontSize: 22 },
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'DO NOT COPY OR POST',
          options: { fontSize: 40, color: colorBlack, align: 'center' },
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
      ],
      {
        ...slideTextConfig,
        y: 1.75,
        h: 5,
        inset: 0.5,
        valign: 'middle',
        line: { width: '2', color: 'B10021' },
      }
    );

    // Page 3 - Static - Your Role versus Other Roles
    let slide3 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide3.addText('Your Role versus Other Roles', slideTitleConfig);
    slide3.addText(
      [
        {
          text: 'What did you learn about each role? Did your understanding of the roles change or stay stable throughout each event in the simulation?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        { text: 'Focal Firm', options: { bullet: true, indentLevel: 0 } },
        { text: 'Supplier', options: { bullet: true, indentLevel: 0 } },
        { text: 'Buyer', options: { bullet: true, indentLevel: 0 } },
        { text: 'Competitor', options: { bullet: true, indentLevel: 0 } },
        { text: 'Substitute', options: { bullet: true, indentLevel: 0 } },
        { text: 'New Entrant', options: { bullet: true, indentLevel: 0 } },
      ],
      slideTextConfig
    );

    // Page 4 - Static - The [scenario] Industry
    const scenarioName =
      game?.scenario === 'chocolate' ? 'Gourmet Chocolate' : 'E-Scooter';
    let slide4 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide4.addText(`The ${scenarioName} Industry`, slideTitleConfig);
    slide4.addText(
      [
        {
          text: 'Describe the industry’s structure:',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        { text: 'Concentration', options: { bullet: true, indentLevel: 0 } },
        { text: 'Constraints', options: { bullet: true, indentLevel: 0 } },
        { text: 'Dependencies', options: { bullet: true, indentLevel: 0 } },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'You responded to an industry assessment to rate the forces’ power as high, medium, or low. Did your assessment change as you played through different events? Why or why not?',
        },
      ],
      slideTextConfig
    );

    // Page 5 - Static - The [scenario] Industry
    let slide5 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide5.addText(`The ${scenarioName} Industry`, slideTitleConfig);
    slide5.addText(
      [
        {
          text: 'Which force seemed to hold the most power in the industry? Why would you pick that force?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'What could this mean for other forces in the industry?',
        },
      ],
      slideTextConfig
    );

    // General events data
    // const scenarioEvents = scenarios
    //   .find((s) => s.id === game?.scenario)
    //   ?.events.filter((e) => game.events_ids.includes(e.id));

    // Get all events in the order they were played
    const scenarioEvents = game?.events_ids
      ?.map((id) => {
        const theScenario = scenarios.find((s) => s.id === game?.scenario);
        const theEvent = theScenario?.events.find((e) => e.id === id);
        return theEvent;
      })
      .filter((e) => e !== undefined);
    // console.log({ scenarioEvents });

    // *********************
    // 2. Assessment Slides
    // *********************

    forcesIds.forEach((force, fi) => {
      // Create a new slide instance
      let slide = pres.addSlide({ masterName: 'MASTER_SLIDE' });

      // Add a title
      slide.addText(
        `Industry Assessment: ${forceButtons[fi]}`,
        slideTitleConfig
      );

      const surveysPre = game?.players
        ?.filter((p) => p.type !== 'bot')
        .map((p) => {
          return p?.survey_initial &&
            Object.keys(p?.survey_initial).length === 6 &&
            Object.keys(p?.survey_initial).every(
              (k) => p?.survey_initial[k] !== null
            )
            ? p?.survey_initial
            : null;
        });

      const surveysPost = game?.players
        ?.filter((p) => p.type !== 'bot')
        .map((p) => {
          return p?.survey_final &&
            Object.keys(p?.survey_final).length === 6 &&
            Object.keys(p?.survey_final).every(
              (k) => p?.survey_final[k] !== null
            )
            ? p?.survey_final
            : null;
        });

      const totalPre = surveysPre?.reduce(
        (acc, s) => {
          if (!s) return acc;

          const newAcc = [...acc];
          const selectedForceValue = s[`force_${force}`];
          // Add +1 to the selected force value (High, Medium, Low)
          if (
            typeof selectedForceValue !== 'undefined' &&
            selectedForceValue !== null
          )
            newAcc[2 - selectedForceValue] += 1;
          return newAcc;
        },
        [0, 0, 0]
      ) || [0, 0, 0];

      const totalPost = surveysPost?.reduce(
        (acc, s) => {
          if (!s) return acc;

          const newAcc = [...acc];
          const selectedForceValue = s[`force_${force}`];
          // Add +1 to the selected force value (High, Medium, Low)
          if (
            typeof selectedForceValue !== 'undefined' &&
            selectedForceValue !== null
          )
            newAcc[2 - selectedForceValue] += 1;
          return newAcc;
        },
        [0, 0, 0]
      ) || [0, 0, 0];

      const chartData = [
        {
          name: 'Initial',
          labels: alternatives,
          values: totalPre,
        },
        {
          name: 'Final',
          labels: alternatives,
          values: totalPost,
        },
      ];

      // console.log(chartData);

      // Draw the chart
      slide.addChart(pres.ChartType.bar, chartData, {
        ...chartsConfig,
        // barGrouping: 'stacked',
        // Chart title
        title: `Industry Assessment: ${forceButtons[fi]}`,
        showTitle: true,
        titleFontSize: 12,
        // Val axis: Y
        valAxisLabelFontSize: 8,
        valAxisMinVal: 0,
        valAxisMaxVal: Math.max(...totalPre, ...totalPost, 6),
        valAxisMajorUnit: 1,
        // Cat axis: X
        catAxisLabelFontSize: 6,
        catAxisLabelFrequency: 1,
        catAxisLabelRotate: -45,
        // Series colors
        chartColors: ['7E97c3', 'Ded9c2'],
        // Legend
        showLegend: true,
        legendPos: 'b',
      });
    });

    // Page 12 - Static - The [scenario] Industry
    let slide12 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide12.addText(`Power in the Industry`, slideTitleConfig);
    slide12.addText(
      [
        {
          text: 'How did you feel about the relative changes in power that the entire group’s decisions caused after each event?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'Were there any outcomes that were a surprise or that confused you?',
        },
      ],
      slideTextConfig
    );

    // Page 13 - Static - The [scenario] Industry
    let slide13 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide13.addText(`Effects of Events`, slideTitleConfig);
    slide13.addText(
      [
        {
          text: 'Were there any environmental shifts at the beginning of each event that seemed to have a large effect on your force? Which ones?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'How did they threaten your position or create opportunity for you?',
        },
      ],
      slideTextConfig
    );

    // Page 14 - Static - The [scenario] Industry
    let slide14 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide14.addText(`Effects of Event Decisions`, slideTitleConfig);
    slide14.addText(
      'Did the consequences of any of your event decisions surprise you?',
      slideTextConfig
    );

    // Page 15 - Static - The [scenario] Industry
    let slide15 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide15.addText(`Power in Your Role`, slideTitleConfig);
    slide15.addText(
      'How did you feel in your role? Powerful? Powerless? Why?',
      slideTextConfig
    );

    // Page 16 - Static - The [scenario] Industry
    let slide16 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide16.addText(`Other Forces’ Effects`, slideTitleConfig);
    slide16.addText(
      [
        {
          text: 'Describe an occasion when you considered other forces and what they might decide to do before submitting your own decisions.',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'Focal firm and competitor: How often and in what ways did you consider each other?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'Focal firm and competitor: How often and in what ways did you consider the substitute or new entrant?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'Substitute: Did you consider the focal firm and competitor in the industry while making decisions? How so?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'New entrant: Did you consider the focal firm and competitor in the industry while making decisions? How so?',
          options: { bullet: true, indentLevel: 0 },
        },
      ],
      slideTextConfig
    );

    // Page 17 - Static - The [scenario] Industry
    let slide17 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide17.addText(`Industry Attractiveness`, slideTitleConfig);
    slide17.addText(
      [
        {
          text: 'What makes an industry attractive to enter? Is the e-scooter industry an attractive industry?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'What features of the industry make it attractive?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'Are the conditions of the industry so bad that you would suggest exiting the industry? Why?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'Substitute: Did you consider the focal firm and competitor in the industry while making decisions? How so?',
          options: { bullet: true, indentLevel: 0 },
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'When would you consider it time to exit an industry?',
        },
      ],
      slideTextConfig
    );

    // Page 18 - Static - The [scenario] Industry
    let slide18 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide18.addText(`Value Chain Relationships`, slideTitleConfig);
    slide18.addText(
      [
        {
          text: 'Questions for the focal firm:',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'How would you describe the relationship with your buyer and supplier?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'Did you view them favorably or unfavorably?',
          options: { bullet: true, indentLevel: 0 },
        },
        {
          text: 'What level of negotiation power did you think you had with each of them? Why?',
          options: { bullet: true, indentLevel: 0 },
        },
      ],
      slideTextConfig
    );

    // Page 19 - Static - The [scenario] Industry
    let slide19 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide19.addText(`Event Decision Details`, slideTitleConfig);
    slide19.addText(
      [
        {
          text: 'What changes in inventory levels or production volume did you make during the simulation? What caused you to make those changes?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'In hindsight, were these wise choices in the long term? Why?',
          options: { bullet: true, indentLevel: 0 },
        },
      ],
      slideTextConfig
    );

    // Page 20 - Static - The [scenario] Industry
    let slide20 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide20.addText(`Event Decision Details`, slideTitleConfig);
    slide20.addText(
      [
        {
          text: 'Which of your decisions would have been considered part of your company’s marketing choices?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'What decisions did you make in your value chain to support those marketing choices?',
          options: { bullet: true, indentLevel: 0 },
        },
      ],
      slideTextConfig
    );

    // *********************
    // 3. Decisions Slides
    // *********************

    // Events Home
    let slideEventsHome = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slideEventsHome.addText(
      [
        {
          text: 'Events',
          options: { fontFace: 'Carlito', fontSize: 48, color: colorRed },
        },
      ],
      fullPageConfig
    );

    // Events Intro
    let slideEventsIntro = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    const eventsTitles = scenarioEvents.map((e, ei) => ({
      text: `Event ${ei + 1}: ${e.title}`,
      options: { breakLine: true },
    }));
    slideEventsIntro.addText(`Individual Events`, slideTitleConfig);
    slideEventsIntro.addText(
      [
        {
          text: 'For which event did you personally have the most difficult time deciding how to respond?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        ...eventsTitles,
      ],
      slideTextConfig
    );

    // Loop the events
    scenarioEvents.forEach((event, ei) => {
      // Event Intro
      let slideEventIntro = pres.addSlide({ masterName: 'MASTER_SLIDE' });
      slideEventIntro.addText(
        `Event ${ei + 1}: ${event?.title}`,
        slideTitleConfig
      );
      slideEventIntro.addText([{ text: event?.description }], slideTextConfig);

      forcesIds.forEach((force, fi) => {
        // Create a new slide instance
        let slide = pres.addSlide({ masterName: 'MASTER_SLIDE' });

        // Add a title
        slide.addText(
          `${event?.title} Decisions: ${forceButtons[fi]}`,
          slideTitleConfig
        );

        // Count the decisions per filters (event/force)
        const selectedDecisions = game?.groups
          ? game?.groups
              ?.filter((g) => g.rindex === force)
              ?.reduce((acc, g) => {
                const item = g.items.find((i) => i.event === event.id) || [];
                if (item) return [...acc, { ...item, type: g.type }];
                else return acc;
              }, [])
          : [];

        // console.log(selectedDecisions);

        // Get the decisions list (categories in the x-axis)
        const theEvent = game?.short_decisions_list?.[`event_${event.id}`];
        const fullDecisions = theEvent?.[`force_${force}`];

        // Get the sorted values for the y-axis
        const decisionsBotSeries = fullDecisions?.map((fd) => {
          const item = selectedDecisions.filter(
            (d) => d?.type === 'bot' && d?.data?.includes(fd.code)
          );
          return [fd.description, item.length];
        });
        const decisionsUserSeries = fullDecisions?.map((fd) => {
          const item = selectedDecisions.filter(
            (d) => d?.type !== 'bot' && d?.data?.includes(fd.code)
          );
          return [fd.description, item.length];
        });
        const decisionsTotalSeries = fullDecisions?.map((fd, fdi) => {
          const total =
            decisionsBotSeries[fdi][1] + decisionsUserSeries[fdi][1];
          return [fd.description, total];
        });
        const decisionsSortedTotal = decisionsTotalSeries?.sort(
          (a, b) => b[1] - a[1]
        );
        const decisionsSortedUsers = decisionsSortedTotal?.map((d) =>
          decisionsUserSeries.find((u) => u[0] === d[0])
        );
        const decisionsSortedBots = decisionsSortedTotal?.map((d) =>
          decisionsBotSeries.find((b) => b[0] === d[0])
        );

        // console.log(decisionsSortedUsers);

        const chartData = [
          {
            name: 'User',
            labels: decisionsSortedUsers?.map((v) => v[0]),
            values: decisionsSortedUsers?.map((v) => Number(v[1])),
          },
          {
            name: 'Computer',
            labels: decisionsSortedBots?.map((v) => v[0]),
            values: decisionsSortedBots?.map((v) => Number(v[1])),
          },
        ];

        // console.log(chartData);

        // Draw the chart
        slide.addChart(pres.ChartType.bar, chartData, {
          ...chartsConfig,
          barGrouping: 'stacked',
          // Chart title
          title: `${event?.title} Decisions: ${forceButtons[fi]}`,
          showTitle: true,
          titleFontSize: 12,
          // Val axis: Y
          valAxisLabelFontSize: 8,
          valAxisMinVal: 0,
          valAxisMaxVal: decisionsSortedTotal
            ? Math.max(...decisionsSortedTotal?.map((d) => d[1]), 6)
            : 6,
          valAxisMajorUnit: 1,
          // Cat axis: X
          catAxisLabelFontSize: 6,
          catAxisLabelFrequency: 1,
          catAxisLabelRotate: -45,
          // Series colors
          chartColors: ['7E97c3', 'Ded9c2'],
          // Legend
          showLegend: true,
          legendPos: 'b',
        });
      });
    });

    // Page 58 - Static - The [scenario] Industry
    let slide58 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide58.addText(`Takeaways`, slideTitleConfig);
    slide58.addText(
      [
        {
          text: 'What is your biggest takeaway from this simulation?',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'How might this change the way you see competition or relationships between industry players?',
        },
      ],
      slideTextConfig
    );

    // Page 59 - Static - The [scenario] Industry
    let slide59 = pres.addSlide({ masterName: 'MASTER_SLIDE' });
    slide59.addText(`Takeaways`, slideTitleConfig);
    slide59.addText(
      [
        {
          text: 'Take your role and sketch your own set of five forces with examples of companies that might fit in each of these roles in a different industry.',
        },
        { text: ' ', options: { breakLine: true } },
        { text: ' ', options: { breakLine: true } },
        {
          text: 'How would this industry’s structure differ from the chocolate industry scenario you just played through?',
        },
      ],
      slideTextConfig
    );

    // *********************
    // 4. Generate the PPT
    // *********************
    pres.writeFile({ fileName: `7884_PPT_ENG_${Date.now()}.pptx` });
  };

  return (
    <button
      disabled={disabled}
      className='btn btn-sm btn-link ms-3'
      onClick={handleClick}>
      Download Debrief Slides
    </button>
  );
};

export default PresentationBtn;
