import React, { useState } from 'react';

const Logs = ({ game }) => {
  // State
  const [toggle, setToggle] = useState(false);

  // Methods
  const flat_items = (game) => {
    const items = game?.groups?.map((g) => g?.items);
    const flat_items = items?.flat();
    return flat_items;
  };

  const sort_items = (items = []) => {
    const sorted_items = items.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });
    return sorted_items;
  };

  const get_author = (id) => {
    const author = game?.players?.find((u) => u._id === id);
    return author;
  };

  // Render
  return (
    <div className='logs'>
      {/* Button to toggle the visibility of the logs */}
      <button
        className='btn btn-sm btn-link mt-5 mb-2'
        onClick={() => setToggle(!toggle)}>
        {toggle ? 'Hide' : 'Show'} Logs
      </button>

      {/* Table of logs */}
      {toggle && (
        <table className='table table-sm table-bordered table-hover'>
          <tbody className='small text-secondary'>
            {sort_items(flat_items(game || [])).map((item, ii, array) => {
              const previous_item = array[ii - 1] || null;
              const has_same_time =
                ii === 0 ||
                (previous_item && previous_item.created_at === item.created_at);
              return (
                <tr key={item.id}>
                  <td>
                    <code
                      className={has_same_time ? 'fw-bold text-primary' : ''}>
                      {item.created_at}
                    </code>
                  </td>
                  <td>{get_author(item.author)?.email || 'x'}</td>
                  <td>{item.event}</td>
                  <td>{item.type}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Logs;
