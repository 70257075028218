import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { gamesActions, gameActions, modalActions } from '../../actions';

import Header from '../../components/Header';
import Pagination from '../../components/Pagination';
import List from './list';
import ListFilters from '../../components/ListFilters';

const GamesPage = props => {
  // Vars
  const { games } = props;
  let qs = queryString.parse(window.location.search);
  const pp = 100;

  // State
  let [items, setItems] = useState([]);
  const [search, searchFilters] = useState({});

  // Hooks
  const dispatch = useDispatch();

  // Initialize
  useEffect(() => {
    console.log('Initialized!');
    dispatch(gameActions.setData(null));
    dispatch(gamesActions.fetchGames(null, { pp: pp, total: true, ...qs }));
  }, []);

  useEffect(() => {
    // console.log('GamesPage: useEffect');
    if (games?.items) setItems(games?.items);
  }, [games?.items]);

  // Functions
  const deleteGame = item => {
    // Use the open action from modalAction to display a confirmation
    dispatch(
      modalActions.open({
        title: 'Session Deletion',
        body:
          'You are about to delete this session. Click "Confirm" to proceed.',
        buttons: [
          <button
            key='modal-btn-close'
            className='btn btn-outline-secondary me-3'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            key='modal-btn-confirm'
            className='btn btn-primary'
            onClick={() => dispatch(gamesActions.removeGame(item))}>
            Confirm
          </button>
        ]
      })
    );
  };

  const pageChange = page => {
    console.log('pageChange', page);
    dispatch(
      gamesActions.fetchGames(null, {
        pag: page,
        pp: pp,
        total: true,
        ...qs,
        ...search
      })
    );
  };

  const handleSearch = filters => {
    console.log(filters);
    dispatch(
      gamesActions.fetchGames(null, {
        pp: pp,
        total: true,
        ...filters,
        ...qs
      })
    );
    searchFilters(filters);
  };

  // Logic
  // console.log('listItems', items.length);
  const listItems = items.map(i => {
    // const hbpInstructor =
    //   i?.platform === 'he-website' && i?.created_by === i?.instructor?._id
    //     ? true
    //     : false;
    return {
      name: <span className='text-break'>{i.name}</span>,
      instructor: i.instructor ? i.instructor.email : i.instructor_email || '',
      status: i.status,
      num_players: i?.players?.filter(p => p.type === 'user')?.length || '',
      created_at: moment(i.created_at).format('MMMM Do YYYY, h:mm:ss a'),
      actions: (
        <span className='text-nowrap'>
          {i.status === 'saved' ? (
            <Link
              key={`edit-${i._id}`}
              to={'/games/' + i._id + '/setup/users'}
              className='btn btn-sm btn-outline-primary'>
              Add Players
            </Link>
          ) : (
            <Link
              key={`edit-${i._id}`}
              to={'/games/' + i._id + '/players'}
              className='btn btn-sm btn-outline-success'>
              In Progress
            </Link>
          )}
          <button
            key={`remove-${i._id}`}
            disabled={i.status === 'deleted' ? true : false}
            className={`btn btn-sm btn-light text-danger ms-3 ${
              i.status === 'deleted' ? 'disabled' : ''
            }`}
            onClick={e => deleteGame(i)}>
            <FontAwesomeIcon
              icon='trash-alt'
              size='1x'
              style={{ cursor: 'pointer' }}
            />
          </button>
        </span>
      )
    };
  });

  const calculoPag =
    games?.total <= pp ? 1 : parseInt(games?.total / pp, 10) + 1;

  return (
    <div>
      <Header subtitle={false} />

      <h2 className='row sec-title'>Games</h2>
      <div className='float-end'>
        <ListFilters
          onSubmit={handleSearch}
          fields={[{ name: 'name', placeholder: 'Name' }]}
        />
      </div>

      <div>
        <List
          labels={[
            'Name',
            'Instructor',
            'Status',
            'Players',
            'Created At',
            'Actions'
          ]}
          fields={[
            'name',
            'instructor',
            'status',
            'num_players',
            'created_at',
            'actions'
          ]}
          // sortables={[
          //   'name',
          //   'instructor',
          //   'status',
          //   'num_players',
          //   'created_at',
          // ]}
          data={listItems}
        />
        <Pagination totalPages={calculoPag} pageChange={pageChange} qs={qs} />
        <p className='text-muted text-center fst-italic small mb-4'>
          Total Sessions: {numeral(games?.total).format('0,0')}
        </p>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, games } = state;
  return {
    player,
    games
  };
}

const connectedGamesPage = connect(mapStateToProps)(GamesPage);
export { connectedGamesPage as GamesPage };
