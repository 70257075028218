import React from 'react';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDotCircle,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import './ButtonTabsFeedback.css';

const ButtonTabsFeedback = (props) => {
  const { options = [], selected = null, correct = null } = props;

  // Logic
  const optionButtons = options.map((option, index) => {
    let icon = <FontAwesomeIcon icon={faDotCircle} />;
    let btnStyle = 'btn-black bg-black text-white text-opacity-50';

    // Always highlight the correct one
    if (index === correct) {
      icon = <FontAwesomeIcon icon={faCheckCircle} className='text-seafoam' />;
    }

    // Selected the correct one
    if (index === selected && index === correct) {
      icon = <FontAwesomeIcon icon={faCheckCircle} className='text-white' />;
      btnStyle =
        selected === index ? 'btn-seafoam bg-seafoam text-white' : btnStyle;
    }

    // Selected the incorrect one
    if (index === selected && index !== correct) {
      icon = (
        <FontAwesomeIcon icon={faTimesCircle} className='text-valentine' />
      );
      btnStyle =
        selected === index
          ? 'btn-valentine bg-valentine text-valentine'
          : btnStyle;
    }

    // console.log(options, selected, correct);

    return (
      <div key={'option-' + index} className='col'>
        <button
          disabled={true}
          className={`w-100 btn btn-tab btn-tab-feedback border-0 text-start bg-opacity-100 opacity-100 fw-light ${btnStyle}`}>
          {icon ? <i className='float-start me-2'>{icon}</i> : ''}
          {option}
        </button>
      </div>
    );
  });

  return (
    <div className={`row row-cols-${options.length}`}>{optionButtons}</div>
  );
};

export default ButtonTabsFeedback;
