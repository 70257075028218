import React from 'react';
import { connect } from 'react-redux';

import Message from './Message';

// The Header creates links that can be used to navigate
// between routes.
class ChatHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const self = this;
    const { group, offer } = this.props;

    const rawMessages = group ? group.chat : [];
    let rawOffers = group ? group.items : [];
    rawOffers = rawOffers.map((o, oi) => {
      o.acceptable = o.order === rawOffers[rawOffers.length - 1].order;
      return o;
    });

    // Mix & sort chats and offers
    let timeline = [...rawMessages, ...rawOffers];
    timeline.sort((a, b) => {
      a.created_at = new Date(a.created_at).getTime();
      return a.created_at > b.created_at
        ? 1
        : b.created_at > a.created_at
        ? -1
        : 0;
    });

    let player = group.players[0];
    player.counterpart = group.players[1];

    const timelineEls = timeline.map((o, oi) => {
      if (o.msg) {
        return (
          <Message
            key={'msg-' + oi}
            player={player}
            author={o.author}
            msg={o.msg}
          />
        );
      } else {
        const highlighted = o._id === offer._id ? true : false;
        return (
          <div
            key={'off-' + oi}
            className='mb-3'
            style={{ position: 'relative' }}>
            {highlighted && (
              <div
                ref={(el) => {
                  self.chatBottom = el;
                }}></div>
            )}
            ...block...
          </div>
        );
      }
    });

    return <div>{timelineEls}</div>;
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

export default connect(mapStateToProps)(ChatHistory);
