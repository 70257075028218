import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import PlayerDraggable from './PlayerDraggable';
import GroupDroppable from './GroupDroppable';
// import BotSlot from './BotSlot';

// Actions
import { modalActions, gameActions } from '../actions';

import './PlayersGrid.css';

// Constants
const forcesNames = [
  { name: 'Focal Firm', id: 4 },
  { name: 'Supplier', id: 1 },
  { name: 'Buyer', id: 2 },
  { name: 'Competitor', id: 5 },
  { name: 'Substitute', id: 3 },
  { name: 'New Entrant', id: 6 },
];

const PlayersGrid = (props) => {
  // GLOBAL STATE
  const game = useSelector((state) => state.game);

  // HOOKS
  const dispatch = useDispatch();

  // METHODS
  const handleAddWorld = () => {
    console.log('handleAddWorld');
    dispatch(
      gameActions.addGroups(
        game._id,
        forcesNames.map((f) => ({ rindex: f.id }))
      )
    );
  };

  const handleUnassignAll = () => {
    // Display a confirmation modal
    dispatch(
      modalActions.open({
        title: 'Clear Team Assignments',
        body: (
          <div>
            <p>
              All currently assigned users will be unassigned. This action
              cannot be undone.
            </p>
            <p>
              <b>Are you sure you want to proceed?</b>
            </p>
          </div>
        ),
        buttons: [
          <button
            key='modal-btn-close'
            className='btn btn-outline-secondary me-3'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            key='modal-btn-confirm'
            className='btn btn-danger'
            onClick={() => {
              dispatch(gameActions.unassignAllPlayers({ _id: game._id }));
              dispatch(modalActions.close());
            }}>
            Confirm
          </button>,
        ],
      })
    );
  };

  // LOGIC
  const worldGroups = [];
  const worldRows = game?.groups
    ? game.groups.reduce((worlds, g) => {
        // Get the world index
        const world_idx = g.world - 1;
        // Get the force index
        const force_id = `force_${g.rindex}`;
        // Initialize the world object inside the worlds array
        worlds[world_idx] = worlds[world_idx] ? worlds[world_idx] : {};
        // Initialize the force array inside this world object
        worlds[world_idx][force_id] = worlds[world_idx][force_id]
          ? worlds[world_idx][force_id]
          : [];
        //  Finally, add the players to the force array
        worlds[world_idx][force_id] = g.players;

        // Add the group ids to the worldGroups array
        worldGroups[world_idx] = worldGroups[world_idx]
          ? worldGroups[world_idx]
          : {};
        worldGroups[world_idx][force_id] = g._id;

        return worlds;
      }, [])
    : [];

  // Check if the form is disabled
  const disabledForm = ['saving', 'launched'].includes(game?.status)
    ? true
    : false;

  // COMPONENT RENDER
  return (
    <div className='container-fluid bg-white border rounded-1 py-3 px-3'>
      <div className='h-100'>
        <div>
          {worldRows.map((world, i) => [
            <div key={`world-${i}-title`}>
              <div
                className='text-start pt-2'
                colSpan={forcesNames.length}
                style={{ borderBottomWidth: 0 }}>
                <h6 className='fw-bold hr-line mt-3'>World {i + 1}</h6>
              </div>
            </div>,

            <div key={`world-${i}`} className='d-flex flex-row flex-wrap gap-2'>
              {/* TODO: o va aquí el tamaño de la caja con txt que no sobrepase (...)*/}
              {forcesNames.map((force, fi) => (
                <div
                  key={`world-${i}-force-${force.id}`}
                  style={{
                    flex: '1 0 30%' /* explanation below */,
                    borderBottomWidth: 0,
                    width: '32%',
                    // height: 'auto',
                    // minHeight: '80px',
                  }}>
                  <GroupDroppable
                    force={force}
                    world={i + 1}
                    group_id={worldGroups[i][`force_${force.id}`]}>
                    {world[`force_${force.id}`]?.map((player) => (
                      <PlayerDraggable
                        key={`world-${i}-force-${force.id}-player-${player._id}`}
                        force={force}
                        player={player}
                        canDrag={false}
                        canRemove={disabledForm}
                      />
                    ))}
                  </GroupDroppable>
                </div>
              ))}
            </div>,
          ])}
        </div>
      </div>

      {/* WIZARD FOOTER */}
      <div className='d-flex justify-content-between mt-4 pt-4'>
        <button
          className='btn btn-outline-primary'
          onClick={handleAddWorld}
          disabled={disabledForm}>
          Add World
        </button>

        <button
          className='btn btn-outline-danger'
          onClick={handleUnassignAll}
          disabled={disabledForm || game?.groups?.length === 0 ? true : false}>
          Clear All User Assignments
        </button>
      </div>
    </div>
  );
};

export default PlayersGrid;
