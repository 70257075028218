import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import ButtonTabsFeedback from './ButtonTabsFeedback';
// import ButtonTabs from './ButtonTabs';

// Actions
import { gameActions } from '../actions';

const PlayerKnowledgeCheck = (props) => {
  // Get props
  const { player = null } = props;

  // Hooks
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);

  // Effects
  useEffect(() => {
    if (game._id && !game.forces_data) {
      dispatch(gameActions.getForcesData(game._id));
    }
  }, [game, dispatch]);

  // Logic
  const thePlayeGroup = game?.groups?.find((g) =>
    g.players.find((p) => p.email === player)
  );
  const thePlayer = thePlayeGroup?.players?.find((p) => p.email === player);
  const theRoleQuestions =
    game?.forces_data?.[`force_${thePlayeGroup?.rindex}`];

  const questionBoxes = theRoleQuestions
    ? theRoleQuestions?.check_questions.map((question, index) => {
        const feedback = theRoleQuestions?.check_answers[index];
        const rightAns = theRoleQuestions?.check_right[index];
        // const alternatives = ['Increase', 'No Change', 'Decrease', 'Unsure'];
        const alternatives = ['Increase', 'Decrease', 'No Change'];
        const rightAnsIndex = alternatives.findIndex((a) =>
          a.includes(rightAns)
        );
        const selectedAlt = thePlayer?.check?.[`q_${index}`];
        // console.log(thePlayer, selectedAlt);

        // const colorClass = alternatives.map((alt, ai) => {
        //   // Selected the right one
        //   if (selectedAlt === ai && alt.includes(rightAns))
        //     return 'btn-success';
        //   // Selected the wrong one
        //   if (selectedAlt === ai && !alt.includes(rightAns))
        //     return 'btn-danger';
        //   // Not selected
        //   return '';
        // });

        const feedbackIcon =
          selectedAlt === rightAnsIndex ? (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='text-seafoam mt-1 me-2'
            />
          ) : (
            <FontAwesomeIcon
              icon={faTimesCircle}
              className='text-danger mt-1 me-2'
            />
          );

        // console.log({ hasSubmitted, colorClass, alternatives });

        return (
          <div className='col-6'>
            <div
              key={`q-${index}`}
              className={`card bg-dark text-white bg-opacity-100 mt-3 h-100`}>
              <div className='card-body'>
                <p
                  style={{ color: '#F6DECF' }}
                  className='sans fw-lighter mb-0 text-start'>
                  {question}
                </p>

                <div className='mt-3'>
                  <ButtonTabsFeedback
                    options={alternatives}
                    selected={selectedAlt}
                    correct={rightAnsIndex}
                  />
                </div>

                <div className='mt-4 px-2 text-start'>
                  <p className='mb-2'>Feedback:</p>
                  <div className='d-flex'>
                    {feedbackIcon}
                    <span className='fw-lighter'>
                      <span className='fw-normal'>
                        {selectedAlt === rightAnsIndex
                          ? 'Correct. '
                          : 'Incorrect. '}
                      </span>
                      {feedback}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    : [];

  return (
    <div className='row mb-3 my-5'>
      {thePlayer?.check ? (
        questionBoxes
      ) : (
        <div className='my-5'>
          <p>No response.</p>
        </div>
      )}
    </div>
  );
};

export default PlayerKnowledgeCheck;
