import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
  // faDotCircle,
  // faCheckCircle,
  // faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const List = props => {
  // Vars
  const { className = '' } = props;

  // State
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [sortables, setSortables] = useState([]);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(-1);
  const [sortedBy, setSortedBy] = useState(null);

  useEffect(() => {
    const { data, labels, fields, sortables, sort, sortedBy } = props;
    setLabels(labels || []);
    setFields(fields || []);
    setSortables(sortables || []);
    setData(data || []);
    setSort(sort || -1);
    setSortedBy(sortedBy || null);
  }, [props]);

  // Functions
  const sortTable = col => {
    // const sort = sort;
    const field = fields[col];
    const sortFunction = (a, b) => {
      const aVal =
        typeof a[field].value !== 'undefined' ? a[field].value : a[field];
      const bVal =
        typeof b[field].value !== 'undefined' ? b[field].value : b[field];
      if (aVal < bVal) return -1 * sort;
      if (aVal > bVal) return 1 * sort;
      return 0;
    };
    let toBeSortedItems = [...data];
    let numericItems = toBeSortedItems.filter(i =>
      typeof i[field].value !== 'undefined'
        ? !isNaN(i[field].value)
        : !isNaN(i[field])
    );
    let alphaItems = toBeSortedItems.filter(i =>
      typeof i[field].value !== 'undefined'
        ? isNaN(i[field].value)
        : isNaN(i[field])
    );
    numericItems.sort(sortFunction);
    alphaItems.sort(sortFunction);
    const sortedItems = numericItems.concat(alphaItems);

    // Set the state
    // console.log(sortedItems);
    setData(sortedItems);
    setSort(sort * -1);
    setSortedBy(col);
  };

  const onRowClick = (data, index) => {
    if (props.onRowClick) props.onRowClick(data, index);
  };

  // Logic
  const keys = fields;
  const headings = labels.map((k, ki) => {
    const active = sortedBy === ki ? 'active' : '';
    const sortIcon = (
      <FontAwesomeIcon
        className={active ? 'ms-1' : 'opacity-25 ms-1'}
        icon={faSort}
      />
    );
    const arrowIcon =
      sort === -1 ? (
        <FontAwesomeIcon
          className={active ? 'ms-1' : 'opacity-25 ms-1'}
          icon={faSortUp}
        />
      ) : (
        <FontAwesomeIcon
          className={active ? 'ms-1' : 'opacity-25 ms-1'}
          icon={faSortDown}
        />
      );
    const sortable = sortables.indexOf(keys[ki]) > -1 ? true : false;

    return sortable ? (
      <th
        key={'th-' + ki}
        onClick={() => sortTable(ki)}
        style={{ cursor: 'pointer' }}
        className={'text-center text-nowrap cursor-pointer' + active}>
        {k} {active ? arrowIcon : sortIcon}
      </th>
    ) : (
      <th key={'th-' + ki} className='text-center'>
        {k}
      </th>
    );
  });
  const rows = data.map((d, di) => {
    let cols = keys.map((k, ki) => {
      const value = d[k]?.value ? d[k].value : d[k];
      const label = d[k]?.label ? d[k].label : value;
      const colProps = d[k]?.colProps ? d[k].colProps : {};
      return (
        <td
          onClick={() => onRowClick(d, di)}
          key={'td-' + ki}
          className='text-center'
          style={{ maxWidth: '50%' }}
          {...colProps}>
          <small>{label}</small>
        </td>
      );
    });
    return <tr key={'tr-' + di}>{cols}</tr>;
  });

  // Render
  return (
    <table className={`${className} table table-hover`}>
      <thead className=''>
        <tr>{headings}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player
  };
}

export default connect(mapStateToProps)(List);
