import { gameConstants } from '../constants';

export function game(state = { round: null }, action) {
  switch (action.type) {
    case gameConstants.SET_DATA:
      const d = action.data;
      if (d === null) return { round: null };
      else
        return {
          ...state,
          ...d,
        };
    case gameConstants.SET_LEADERBOARD:
      const leaderboard = action.data;
      if (leaderboard === null) return { ...state };
      else
        return {
          ...state,
          leaderboard,
        };
    case gameConstants.SET_DECISIONS_LIST:
      const decisions_list = action.data;
      if (decisions_list === null) return { ...state };
      else
        return {
          ...state,
          decisions_list,
        };
    case gameConstants.SET_SHORT_DECISIONS_LIST:
      const short_decisions_list = action.data;
      if (short_decisions_list === null) return { ...state };
      else
        return {
          ...state,
          short_decisions_list,
        };
    case gameConstants.SET_FEEDBACK_LIST:
      const feedback_list = action.data;
      if (feedback_list === null) return { ...state };
      else
        return {
          ...state,
          feedback_list,
        };
    case gameConstants.SET_FORCES_DATA:
      const forces_data = action.data;
      if (forces_data === null) return { ...state };
      else
        return {
          ...state,
          forces_data,
        };
    case gameConstants.SET_EVENTS_DATA:
      const events_data = action.data;
      if (events_data === null) return { ...state };
      else
        return {
          ...state,
          events_data,
        };
    case gameConstants.SET_ROUND:
      const round = action.data;
      return {
        ...state,
        round,
      };
    case gameConstants.SET_GAMES:
      return {
        ...state,
        games: action.data,
      };
    case gameConstants.ADD_PLAYERS:
      const newpls = action.data;
      const totalPlayers = [...state.players, ...newpls];
      return {
        ...state,
        players: totalPlayers,
      };
    default:
      return state;
  }
}
