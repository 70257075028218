import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Actions
import { gameActions } from '../actions';

const RoundSelect = (props) => {
  const { game, dispatch } = props;

  // Hooks
  const [selectedRound, setSelectedRound] = useState('');
  const [snaps, setSnaps] = useState([]);
  const [modal, setModal] = useState(false);
  const [highlight, setHiglight] = useState('');
  const [error, setError] = useState(false);

  // On mount
  useEffect(() => {
    if (game?._id) fetchSnapshots(game._id);
  }, [game?._id]);

  // On dropdown change: highlight
  useEffect(() => {
    setHiglight('highlight');
    setTimeout(() => setHiglight(''), 500);
  }, [selectedRound]);

  // Function: Create new round
  const newRound = () => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game._id + '/snapshot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data._id) {
          setModal(false);
          dispatch(gameActions.fetchGames(game._id));
          fetchSnapshots(game._id, false);
        } else if (data && data.error) {
          setError(data.error);
        }
      });
  };

  // Function: Retrieve game snapshots
  const fetchSnapshots = (game_id, keepRound = true) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/snapshot', {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          setSnaps(data.reverse());
          if (game?.round && keepRound)
            return setRound({ target: { value: game.round } });
          else setSelectedRound('');
        }
      });
  };

  // Function: Set an active snapshot
  const setRound = (e) => {
    const r = e.target.value ? e.target.value : null;
    if (r === null) {
      setSelectedRound('');
      dispatch(gameActions.setRound(r));
      return dispatch(gameActions.fetchGames(game._id));
    }

    if (r === 'new') {
      return setModal(true);
    }

    setSelectedRound(r);
    dispatch(gameActions.setRound(r));

    fetch(
      process.env.REACT_APP_API_URL + '/games/' + game._id + '/snapshot/' + r,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => dispatch(gameActions.setData(data.data)));
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <div>
      <select
        style={{
          fontSize: '14px',
          color: '#333',
          backgroundImage: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%230d6efd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")`,
        }}
        className={`d-inline-block w-auto form-select form-select-sm text-primary border-1 border-primary ${highlight}`}
        value={selectedRound}
        onChange={setRound}>
        <option key='round-null' value=''>
          Run #{snaps.length + 1} &nbsp; (Latest)
        </option>
        {snaps.map((s, si) => {
          return (
            <option key={`round-${si + 1}`} value={s._id}>
              Run #{snaps.length - si}
            </option>
          );
        })}
        {/* <option key='round-new' value='new'>
          CREATE NEW ROUND
        </option> */}
      </select>

      <button
        className='btn btn-sm btn-outline-primary ms-2'
        style={{ marginTop: '-2px' }}
        onClick={(e) => setModal(true)}>
        New Run
      </button>

      <Modal isOpen={modal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>Confirm New Run Creation</ModalHeader>
        <ModalBody className=''>
          <div className='m-4'>
            <p>Please confirm that you want to create a new run.</p>
            <p>
              Be aware that this will create a snapshot of the current run that
              you can review in the Results tab. These snapshots cannot be
              modified, so be sure that all users are finished with the current
              run before creating a new one.
            </p>
            <p>
              Once a new run is created, users will be able to replay the
              simulation and their subsequent decisions will be captured in the
              newest run.
            </p>
            {error && ( // Error message
              <div className='text-danger mt-3'>{error}</div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className='text-right'>
          <button
            className='btn btn-light'
            onClick={() => {
              setModal(false);
              setError(false);
            }}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={newRound}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(RoundSelect);
