import { userConstants } from '../constants';
// import { history } from '../helpers'

export const authActions = {
  authenticate,
  logout,
};

function authenticate(qs, cb) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_callback' + qs)
      .then((res) => res.json())
      .then((user) => {
        // Catch errors
        if (user.error) {
          if (cb) cb(user);
          return dispatch({ type: userConstants.LOGIN_FAILURE, user });
        }

        // Catch redirection
        const redir = user.redir ? user.redir : false;
        delete user.redir;
        // Store user essential data
        localStorage.setItem('scjwt', JSON.stringify(user));
        dispatch({ type: userConstants.LOGIN_SUCCESS, user });
        // Redir if neccesary
        if (redir) window.location = redir;
        // history.push(redir)
      });
  };
}

function logout(cb) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/auth/logout').then((res) => {
      if (res.redirected) {
        // Delete the user data
        localStorage.setItem('scjwt', null);
        window.location = res.url;
      }
    });
  };
}
