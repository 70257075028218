import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import './GroupDroppable.css';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faRobot,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../actions';

const PlayerDraggable = ({
  force,
  player,
  canDrag = true,
  canRemove = true,
  disabled = false,
}) => {
  // console.log({ player });

  // HOOKS
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);

  // DND HOOKS
  const draggableId = canDrag ? `player-${player._id}` : player._id;
  const { attributes, listeners, setNodeRef, transform, isDragging, over } =
    useDraggable({
      id: draggableId,
      data: {
        player_id: player._id,
      },
      disabled: disabled || !canDrag || !player.active,
    });

  // METHODS
  const handleTrash = () => {
    const game_id = game._id;
    const player_id = player._id;
    dispatch(
      gameActions.setPlayerGroup({
        _id: game_id,
        player: player_id,
        group: '',
      })
    );
  };

  // LOGIC
  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  };

  const playerName = `${player?.name} ${player?.lastname}`.trim();

  return (
    <div
      className={`player-draggable text-start ${
        !player?.active ? 'text-muted opacity-50' : ''
      }`}
      id={draggableId}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        ...style,
        // opacity: isDragging ? 0.5 : 1,
      }}>
      {/* USERS */}
      {player?.type === 'user' && (
        <div
          className={`small text-body-secondary h-100 ${
            canDrag ? '' : 'box border rounded px-1 mt-2'
          } ${isDragging ? 'bg-white rounded' : ''}`}
          style={{
            fontFamily: 'Curier New, monospace',
            fontWeight: 200,
          }}>
          <div
            className=''
            title={playerName}
            style={{
              // Text overflow CSS
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}>
            <button
              className='btn btn-sm btn-link mx-0'
              disabled={canRemove}
              onClick={canDrag ? null : handleTrash}>
              <FontAwesomeIcon
                icon={canDrag ? faGripVertical : faTimesCircle}
                size='sm'
                className={canDrag ? 'text-body-secondary' : 'text-danger'}
              />
            </button>
            {playerName || 'No name'}
          </div>
        </div>
      )}

      {/* BOTS */}
      {player?.type === 'bot' && (
        <div className='d-flex flex-column justify-content-center align-items-center text-center text-opacity-50 fw-bold border-dotted rounded h-100'>
          <FontAwesomeIcon icon={faRobot} size='xs' className={'text-dgray'} />
          <small className='mt-1 lh-1'>COMPUTER</small>
          {/* <small className='mt-1'>{force.name}</small> */}
        </div>
      )}
    </div>
  );
};

export default PlayerDraggable;
