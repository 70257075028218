import { gamesConstants } from '../constants';

export const gamesActions = {
  fetchGames,
  removeGame,
};

// New methods
function fetchGames(id, filters = {}) {
  const qstring = Object.keys(filters)
    .filter((key) => !isNaN(filters[key]) || filters[key].length > 0)
    .map((key) => `${key}=${filters[key]}`)
    .join('&');

  return (dispatch) => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        (id ? id : '') +
        `${qstring ? '?' + qstring : ''}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res) || Array.isArray(res?.items)) {
          const items = res?.items || res;
          const total = res?.total || items.length;
          dispatch({ type: gamesConstants.SET_GAMES, items, total });
        } else {
          dispatch({ type: gamesConstants.SET_GAME, data: res });
        }
      });
  };
}

function removeGame(data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gamesConstants.REMOVE_ITEM, data: res }),
          250
        );
      });
  };
}
