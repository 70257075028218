import React from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// import Evolution from './Evolution';
// import Summary from './Summary';
// import ChatHistory from './ChatHistory';

// import EventBox from './EventBox';
// import PlayerBox from './PlayerBox';
import PlayerAnalysis from './PlayerAnalysis';
import PlayerKnowledgeCheck from './PlayerKnowledgeCheck';
import PlayerSurvey from './PlayerSurvey';
import PlayerReflection from './PlayerReflection';

// Data
import { scenarios } from '../constants';

// The Header creates links that can be used to navigate
// between routes.
class PlayerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
      tab: 1,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let data = {};
    data[n] = v;
    this.setState(data);
  }

  handleTabChange(tabId) {
    this.setState({ tab: tabId });
  }

  render() {
    const { playerIdentifier, playerProgress, game } = this.props;
    const { tab } = this.state;

    // const playerData = game.players.find((p) => p.email === playerIdentifier);
    // console.log({ playerData, playerProgress });

    // Event tabs
    const eventTabs = scenarios
      .find((s) => s.id === game?.scenario)
      ?.events.filter((e) => game.events_ids.includes(e.id))
      .map((b, bi) => {
        return (
          <NavItem key={`link-${bi}-${playerIdentifier}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === bi + 1 ? 'active' : ''}
              onClick={() => this.handleTabChange(bi + 1)}>
              Event {bi + 1}
            </NavLink>
          </NavItem>
        );
      });

    const eventBoxes = scenarios
      .find((s) => s.id === game?.scenario)
      ?.events.filter((e) => game.events_ids.includes(e.id))
      .map((b, bi) => {
        return (
          <TabPane key={`pane-${bi}-${playerIdentifier}`} tabId={bi + 1}>
            <div className='row mt-4 mb-4'>
              <div className='col-12 text-start'>
                <div className='px-3'>
                  <b>{b?.title}</b>
                  <p className='mt-2'>{b?.description}</p>
                </div>
              </div>
            </div>
            <PlayerAnalysis
              event={b.id}
              eventPos={bi + 1}
              player={playerIdentifier}
            />
          </TabPane>
        );
      });

    return (
      <div>
        <div className='d-flex justify-content-between text-start fs-6 shadow p-3 rounded-2 mb-4'>
          <div>
            <small>Rank</small>:
            <br />
            <b>{playerProgress?.rank}</b>
          </div>
          <div>
            <small>Learner</small>:
            <br />
            <b>
              {playerProgress?.name} {playerProgress?.lastname}
            </b>
          </div>
          <div>
            <small>Weighted Performance</small>:
            <br />
            <b>{playerProgress?.performance?.label}</b>
          </div>
          <div>
            <small>Decisions</small>:
            <br />
            <b>{playerProgress?.decisions_performance?.label}</b>
          </div>
          <div>
            <small>Analysis</small>:
            <br />
            <b>{playerProgress?.assessments_performance?.label}</b>
          </div>
          <div>
            <small>Role</small>:
            <br />
            <b>{playerProgress?.rindex}</b>
          </div>
          <div>
            <small>World</small>:
            <br />
            <b>{playerProgress?.world}</b>
          </div>
          <div>
            <small>Thru</small>:
            <br />
            <b>{playerProgress?.thru}</b>
          </div>
        </div>

        <Nav tabs>
          {eventTabs}
          <NavItem key={`link-6-${playerIdentifier}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === 6 ? 'active' : ''}
              onClick={() => this.handleTabChange(6)}>
              Knowledge Check
            </NavLink>
          </NavItem>
          <NavItem key={`link-7-${playerIdentifier}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === 7 ? 'active' : ''}
              onClick={() => this.handleTabChange(7)}>
              Industry Assessment
            </NavLink>
          </NavItem>
          <NavItem key={`link-8-${playerIdentifier}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={tab === 8 ? 'active' : ''}
              onClick={() => this.handleTabChange(8)}>
              Final Reflection
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          {eventBoxes}
          <TabPane key='pane-6' tabId={6}>
            {/* <div className='row mt-4 mb-4'>
              <div className='col-6'>
                <PlayerBox player={playerIdentifier} />
              </div>
            </div> */}
            <PlayerKnowledgeCheck player={playerIdentifier} />
          </TabPane>
          <TabPane key='pane-7' tabId={7}>
            <PlayerSurvey player={playerIdentifier} />
          </TabPane>
          <TabPane key='pane-8' tabId={8}>
            <PlayerReflection player={playerIdentifier} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(PlayerDetails);
