export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getSetupValues = ({
  _id,
  players_text,
  name,
  ppg,
  access_type,
  setup,
  scenario,
  events_n,
  events_ids,
  players_in,
  pacing,
  settings_primer,
  settings_survey,
  settings_release,
  settings_domino,
  settings_leaderboard,
  settings_insights,
  settings_reflection,
  settings_animations,
  prep,
  status,
  started,
}) => ({
  _id,
  // players_text,
  name,
  // ppg,
  access_type,
  // setup,
  scenario,
  events_n,
  events_ids,
  // players_in,
  pacing,
  settings_primer,
  settings_survey,
  settings_release,
  settings_domino,
  settings_leaderboard,
  settings_insights,
  settings_reflection,
  settings_animations,
  prep,
  status,
  started,
});
