import { NavLink, useLocation } from 'react-router-dom';

const Submenu = (props) => {
  // Get the props
  const { game_id } = props;

  const location = useLocation();
  const { pathname } = location;
  const setupRoot = pathname.split('/').pop() === 'setup' ? true : false;

  let step = 1;
  if (pathname.includes('/users')) step = 1;
  if (pathname.includes('/events')) step = 2;
  if (pathname.includes('/experience')) step = 3;
  if (pathname.includes('/run')) step = 4;

  // console.log(pathname, step, setupRoot);

  return (
    <ul className='nav nav-pills ms-auto'>
      <li className='ms-4'>
        <NavLink
          to={`/games/${game_id}/setup/users`}
          className={({ isActive }) =>
            isActive || setupRoot
              ? 'nav-link active'
              : `nav-link ${
                  step > 1 ? 'border border-primary' : 'border border-primary'
                }`
          }>
          1. Users
        </NavLink>
      </li>
      <li className='ms-4'>
        <NavLink
          to={`/games/${game_id}/setup/events`}
          className={({ isActive }) =>
            isActive
              ? 'nav-link active'
              : `nav-link ${
                  step > 2 ? 'border border-primary' : 'border border-primary'
                }`
          }>
          2. Events
        </NavLink>
      </li>
      <li className='ms-4'>
        <NavLink
          to={`/games/${game_id}/setup/experience`}
          className={({ isActive }) =>
            isActive
              ? 'nav-link active'
              : `nav-link ${
                  step > 3 ? 'border border-primary' : 'border border-primary'
                }`
          }>
          3. Experience
        </NavLink>
      </li>
      <li className='ms-4'>
        <NavLink
          to={`/games/${game_id}/setup/run`}
          className={({ isActive }) =>
            isActive
              ? 'nav-link active'
              : `nav-link ${
                  step > 4 ? 'border border-primary' : 'border border-primary'
                }`
          }>
          4. Run
        </NavLink>
      </li>
    </ul>
  );
};

export default Submenu;
