export const scenarios = [
  {
    id: 'chocolate',
    label: 'Chocolate Industry',
    description:
      'Learners will play the role of a company—or force—within the gourmet chocolate industry, responding to major events (economic and otherwise).',
    events: [
      {
        id: 1,
        title: 'Cacao Blight',
        description: [
          "A fungus blight has befallen a portion of the world cacao crop, shrinking yields 30% for this season. This could last up to three years. Fortunately, none of Xoco Direct's farms, or the cacao suppliers for Spumoni Co. and Ruban de Soie have been affected by the blight."
        ]
      },
      {
        id: 2,
        title: 'Governmental Warning',
        description: [
          "The United States' Food and Drug Administration has issued a warning. There is a study that shows a specific chemical in cacao causes a detrimental change in brain chemistry. The governing body now requires that all chocolate carry a warning of this effect, while suggesting that consumers limit their consumption of cacao-based products."
        ]
      },
      {
        id: 3,
        title: 'Global Recession',
        description: [
          'A global economic recession hits, causing unemployment to double around the world.'
        ]
      },
      {
        id: 4,
        title: 'Cacao Cartel',
        description: [
          "A cacao cartel has formed within two major cacao-producing countries. The new cartel has announced a 30% increase in prices of its output, claiming this will help bring better earning and living conditions for its farmers. This cartel controls a majority of the world's cacao, and major buyers are in tentative support because of its claims to help farmers. Xoco Direct is not under the direction of the cartel, but all players in the industry will feel the global impact of this cartel's decisions. Ruban de Soie currently purchases cacao from a supplier in a country that is part of the new cartel."
        ]
      },
      {
        id: 5,
        title: 'Contamination',
        description: [
          'A larger direct chocolate competitor of both Dahlia Chocolate and Ruban de Soie has announced a contamination of product and will miss a quarter of order fulfillment while shutting down and remediating the plant.'
        ]
      }
    ]
  },
  {
    id: 'escooter',
    label: 'E-Scooter Industry',
    description:
      'Learners will play the role of a company—or force—within the shared (i.e., short term rental) e-scooter industry, responding to major events (economic and otherwise).',
    events: [
      {
        id: 1,
        title: 'Direct-to-Consumer',
        description: [
          "Haegi's manufacturing competitor, Finesse, opens pop-up retail stores in shopping centers across the European Union. They sell their own value line and seated line of e-scooters for 500€ and 750€, each, directly to consumers. These e-scooters are of good quality, with integrated lithium-ion batteries."
        ]
      },
      {
        id: 2,
        title: 'Battery Supply Chain',
        description: [
          'Haegi and other leading manufacturers are experiencing a disruption in international transportation for the lithium batteries that power their e-scooters due to problems with Back Bay Battery, a major battery source. Not only are they unable to meet demand and short-term orders for e-scooters, but they must also consider increasing the prices of their e-scooters to offset increases in pricing for alternative batteries.'
        ]
      },
      {
        id: 3,
        title: 'Partnerships with Cities',
        description: [
          'Ten major EU cities including Rome have collaborated in a Green Transportation Pact. They have agreed that it is time to capitalize on the improvements in carbon footprint that e-scooters have over gas-powered automobiles. These cities are starting to subsidize e-scooter use, reducing prices to consumers by 50%, and hiring people to help rental companies collect, charge, and maintain the e-scooters.'
        ]
      },
      {
        id: 4,
        title: 'Heightened Regulation',
        description: [
          'The European Union, in an attempt to reduce the negative effects of e-scooter proliferation, have decided to limit the number of e-scooters in urban areas. In addition, all rental e-scooters must now be docked and charged at specified parking sheds and helmets must be worn while riding.'
        ]
      },
      {
        id: 5,
        title: 'Entry of Laiz',
        description: [
          "Spurred by predictions of moderate market growth, Laiz has been given approval to fully enter the EU and US e-scooter rental markets using their own e-scooter lines. The strategy is to position Laiz's e-scooters as the fastest and highest quality on the market. They also intend to sell e-scooters to own in retail outlets for 800€ each and to sell their rechargeable batteries to e-scooter manufacturers worldwide. Laiz estimates that in two years, they will be able to create a global fleet of 60,000 e-scooters."
        ]
      }
    ]
  }
];
