import React from 'react';
import { useDroppable } from '@dnd-kit/core';

import './GroupDroppable.css';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const GroupDroppable = ({ children, force, group_id, world }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `force-${force.id}-w-${world}`,
    data: {
      force_id: force.id,
      group_id,
    },
  });

  // Logic
  const overClass = isOver ? 'bg-dgray' : 'bg-lgray';

  return (
    <div
      id={`force-${force.id}-w-${world}`}
      ref={setNodeRef}
      className={`group-droppable ${overClass} rounded h-100 p-2`}>
      {/* The force name */}
      <small className='d-block fw-bold text-uppercase text-center text-dgray mb-2'>
        {force.name}
      </small>

      {/* Children are players represented as boxes */}
      {children?.length > 0 ? ( // If there are players in the group
        children
      ) : (
        <div className='d-flex flex-column justify-content-center align-items-center text-center text-opacity-50 fw-bold border-dotted rounded'>
          <FontAwesomeIcon icon={faRobot} size='1x' className={'text-dgray'} />
          <small className='mt-1'>{force.name}</small>
        </div>
      )}
    </div>
  );
};

export default GroupDroppable;
