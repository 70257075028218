import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import GameForm from './form';
import { GameSetup } from './setup';
import { GamePlayers } from './players';

// Decisions
import { GameLeaderboard } from './debrief_leaderboard';
import { GameMaterials } from './debrief_materials';
import { GameDecisions } from './debrief_decisions';
import { GameSurvey } from './debrief_survey';
import { GameAnalysis } from './debrief_analysis';

import { setupSocket, socketOn, enterBackend } from '../../helpers';

const Form = (props) => {
  const { game, dispatch } = props;

  // Hooks
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const { id } = params;
    dispatch(gameActions.fetchGames(id));

    // Fetch game data to connect to socket
    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((res) => res.json())
      .then((data) => {
        // Validation
        if (!data?.io_domain) return console.log('No io_domain found');
        // Connect to ws
        setupSocket(data.io_domain, null, (error) => {
          if (error) return console.warn(error);
          if (id) {
            enterBackend({ game_id: id }, (gameData) => {
              console.log(`Connected to backend ${gameData?._id}`);
            });
            // Listen to sockets
            socketOn('player', () => dispatch(gameActions.fetchGames(id)));
            socketOn('timer_end', (timerObj) =>
              dispatch(gameActions.fetchGames(id))
            );
            socketOn('decision', (timerObj) =>
              dispatch(gameActions.fetchGames(id))
            );
            socketOn('event_flag', (timerObj) =>
              dispatch(gameActions.fetchGames(id))
            );
            socketOn('check_submitted', (timerObj) =>
              dispatch(gameActions.fetchGames(id))
            );
            socketOn('online_players', (online) => {
              console.log('Receiving online_players', online?.length);
              // setOnline(online);
              dispatch(gameActions.setData({ online }));
            });
            // socketOn('nodeal', () => this.delayedUpdated());
          }
        });
      });
  }, []);

  const SetupComponent =
    game && game.access_type === 'lti-hbp' ? GameSetup : GameForm;

  // Location validations
  const { pathname } = location;
  const isSaved = game?.status === 'saved' ? true : false;
  // const isLaunched = game?.status === 'launched' ? true : false;
  // const isStarted = game?.started === true ? true : false;

  // When saved
  const redirectWhenSaved = ['/players', '/debrief'];
  if (isSaved && redirectWhenSaved.some((s) => pathname.includes(s)))
    return <Navigate to={'/games/' + game?._id + '/setup'} />;

  // // When launched
  // const redirectWhenLaunched = ['/setup', '/users'];
  // if (
  //   isLaunched &&
  //   isStarted &&
  //   redirectWhenLaunched.some((s) => pathname.includes(s))
  // )
  //   return <Navigate to={'/games/' + game?._id + '/players'} />;

  return (
    <div>
      {game && game && (
        <div>
          <Routes>
            {/* Setup */}
            <Route exact path='/*' element={<SetupComponent />} />
            <Route path='/setup/*' element={<SetupComponent />} />

            {/* Debrief */}
            <Route exact path={'/players'} element={<GamePlayers />} />
            <Route
              exact
              path={'/debrief/decisions'}
              element={<GameDecisions />}
            />
            <Route exact path={'/debrief/survey'} element={<GameSurvey />} />
            <Route
              exact
              path={'/debrief/analysis'}
              element={<GameAnalysis />}
            />
            <Route
              exact
              path={'/debrief/leaderboard'}
              element={<GameLeaderboard />}
            />
            <Route exact path={'/materials'} element={<GameMaterials />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };
