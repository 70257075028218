import React from 'react';
import { useSelector } from 'react-redux';

// Highcharts
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

const PlayerReflection = (props) => {
  // Props
  const { player = null } = props;

  // State

  // Hooks
  const game = useSelector((state) => state.game);

  // Methods

  // Logic
  const thePlayer = game?.players?.find((p) => p.email === player);

  return (
    <div className='row'>
      <div className='col-12 my-5'>
        {thePlayer?.final_reflection?.length > 0 ? (
          thePlayer?.final_reflection
        ) : (
          <div className='my-5'>No reflection yet.</div>
        )}
      </div>
    </div>
  );
};

export default PlayerReflection;
