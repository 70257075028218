import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Components
import CSVDrop from './CSVDrop';

// Utils
import { validateEmail } from '../helpers/utils';

const CSVImporterModal = (props) => {
  // Props
  const {
    isOpen = false,
    onSubmit = null,
    onClose = null,
    success = null,
  } = props;

  // State
  const [csvImporter, setCSVImporter] = useState(isOpen);
  const [imported, setImported] = useState(null);
  const csvImporterRef = useRef(null);
  const disabledForm = false; // Replace with your logic for disabling the form

  // Effects
  useEffect(() => {
    setCSVImporter(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setImported(success);
  }, [success]);

  // Methods

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      csvImporterRef.current.value = e.target.result?.trim();
    };
    reader.readAsText(file);
  };

  const readCSV = (content) => {
    csvImporterRef.current.value = content;
  };

  const handleCSVParsing = () => {
    const csv_content = csvImporterRef.current?.value;

    // If csv_content is empty, then focus on the textarea
    if (csv_content.length === 0) {
      csvImporterRef.current?.focus();
      return;
    }

    // Separate by lines
    const lines = csv_content.split('\n');
    // Separate by commas
    const players_content = lines.map((l) => l.split(','));

    // Trim values and remove empty lines
    const players = players_content
      .map((p) => p.map((v) => v.trim()))
      .filter((p) => p.length > 0);

    // Validate that the first property is an email
    const validPlayers = players.filter((p) => validateEmail(p[0]));

    // Let's convert the array of arrays into an array of objects
    const validPlayersObjects = validPlayers.map((p) => ({
      email: p[0],
      name: p[1],
      lastname: p[2],
    }));

    onSubmit && onSubmit(validPlayersObjects);
  };

  return (
    <Modal isOpen={csvImporter} toggle={onClose} size='lg' centered>
      <ModalHeader toggle={onClose}>Import Users</ModalHeader>
      <ModalBody>
        <div className='form-group mb-2'>
          {imported && (
            <div className='alert alert-primary mb-3 py-2 px-3' role='alert'>
              <b>Successfully imported {imported?.length} users</b>.
            </div>
          )}

          <CSVDrop onComplete={readCSV}>
            <div className='input-group'>
              <textarea
                ref={csvImporterRef}
                name='players_text'
                className='form-control'
                placeholder='When uploading a comma-separated values file (.csv), please format with email addresses in the first column, first names in the second column, and last names in the third column. You can also copy the contents of the .csv and paste them into this field.'
                rows='10'
                disabled={disabledForm || imported?.length > 0}></textarea>
            </div>
          </CSVDrop>

          <p className='mt-3 mb-1 fw-bold'>
            <small>
              Select a csv file or drag and drop into the text input above.
            </small>
          </p>
          <input
            type='file'
            className='form-control'
            onChange={handleFileChange}
          />
        </div>

        <small className='text-secondary'>
          <b>Note</b>: Maximum 200 rows/users permitted
        </small>
      </ModalBody>
      <ModalFooter>
        {imported?.length > 0 && (
          <button
            className='btn btn-sm btn-link me-3'
            onClick={() => {
              setImported(null);
              csvImporterRef.current.value = '';
            }}>
            Import more
          </button>
        )}
        <button
          disabled={disabledForm || imported?.length > 0}
          className='btn btn-outline-primary'
          onClick={onClose}>
          Cancel
        </button>
        {!imported || imported?.length === 0 ? (
          <button
            disabled={disabledForm}
            className='btn btn-primary'
            onClick={handleCSVParsing}>
            Import
          </button>
        ) : (
          <button
            disabled={disabledForm}
            className='btn btn-primary'
            onClick={onClose}>
            Done
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CSVImporterModal;
