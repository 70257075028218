import React from 'react';
import { useSelector } from 'react-redux';

import computer from '../assets/computer.svg';
import single from '../assets/single.svg';
import team from '../assets/team.svg';
import multi from '../assets/multi.svg';

const images = [computer, single, team, multi];
const methodLabels = {
  single_pc: 'Single-player vs. Computers',
  multi_pc: 'Multi-player vs. Computers',
  single_single: 'Single-player vs. Single-player',
  multi_multi: 'Multi-player vs. Multi-player',
};

const forcesNames = [
  { name: 'Focal Firm', id: 4 },
  { name: 'Supplier', id: 1 },
  { name: 'Buyer', id: 2 },
  { name: 'Competitor', id: 5 },
  { name: 'Substitute', id: 3 },
  { name: 'New Entrant', id: 6 },
];

const UniversePreview = ({
  ppr = 1,
  players_in = [1, 2, 3, 4, 5, 6],
  usermethod = '',
}) => {
  // HOOKS
  const item = useSelector((state) => state.game);

  // LOGIC
  const forcesOrder = [4, 1, 2, 5, 3, 6];
  const filteredForces = forcesOrder.filter((force) =>
    players_in.includes(force)
  );
  const worlds = item.registered?.reduce((acc, curr, currIdx) => {
    // Calculate the world index based on the player's index
    // and the number of players per world and the number of groups (6)
    const world_index = Math.floor(currIdx / (ppr * players_in.length));
    const group_pos = Math.floor((currIdx % (ppr * players_in.length)) / ppr);
    const force_index = forcesOrder.indexOf(filteredForces[group_pos]);
    // console.log(group_pos, filteredForces[group_pos], force_index);

    // If the world is not defined yet, create it
    if (!acc[world_index]) {
      acc[world_index] = [0, 0, 0, 0, 0, 0];
    }

    // Add the player to the world in the correct group
    acc[world_index][force_index]++;

    return acc;
  }, []);

  // Active players
  const activePlayers = item?.registered?.filter(
    (player) => player.active && player.type === 'user'
  );

  // Single vs Multiplayer
  const playersModeLabel = usermethod.includes('single')
    ? 'single-player'
    : 'multi-player';
  const playersLabel = activePlayers?.length > 1 ? 'users' : 'user';
  const worldLabel = worlds.length > 1 ? 'worlds' : 'world';

  // console.log(worlds);

  return (
    <div className=''>
      {usermethod && (
        <div className='bg-light p-3'>
          <p className='mb-0'>
            You have{' '}
            <b>
              {activePlayers?.length} {playersLabel}
            </b>{' '}
            and have selected <b>{methodLabels[usermethod]}</b> mode. The
            assignment recommendation is{' '}
            <b>
              {worlds?.length} {worldLabel}
            </b>
            , with {ppr} {playersModeLabel} user per world.
          </p>
        </div>
      )}

      {/* {ppr} - {players_in.join(', ')} */}

      <div className='scroll'>
        <div className='bg-white border-0 rounded-1 py-3 px-0'>
          <table
            className='table table-sm h-100 border-0'
            style={{
              tableLayout: 'fixed',
            }}>
            <thead>
              <tr>
                <th className='border-bottom-0'></th>
                {forcesNames.map((force, fi) => (
                  <th
                    className='text-center fw-normal'
                    key={`force-${force.id}`}
                    style={{
                      borderBottomWidth: 0,
                      borderRightWidth:
                        fi === forcesNames?.length - 1 ? '' : '1px',
                      borderColor: '#dee2e6',
                    }}>
                    <b className='small-lg small d-inline-block py-3'>
                      {' '}
                      {force.name.toUpperCase()}{' '}
                    </b>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Display the different worlds */}
              {worlds.map((world, worldIdx) => {
                return (
                  <tr key={`world-${worldIdx}`}>
                    <td className='border-bottom-0 align-middle py-4 text-center'>
                      <small>World {worldIdx + 1}</small>
                    </td>
                    {world.map((amount, ai) => {
                      const icon = amount > 2 ? images[3] : images[amount];
                      return (
                        <td
                          key={`world-${worldIdx}-td-${ai}`}
                          className='text-center border-bottom-0 py-4'>
                          <img
                            key={`icon-${ai}-${worldIdx}`}
                            src={icon}
                            style={{
                              height: '35px',
                            }}
                            alt={`${amount} players`}
                          />
                          <br />
                          <small
                            className='d-inline-block text-dark opacity-50'
                            style={{ minWidth: '60px' }}>
                            {amount > 0 ? (
                              `${amount} ${amount === 1 ? 'player' : 'players'}`
                            ) : (
                              <br />
                            )}
                          </small>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className='scroll'>
        <div className='text-center m-3'>
          {worlds.map((world, worldIdx) => {
            return (
              <div
                key={`world-${worldIdx}`}
                className='d-flex w-100 align-items-center pb-3'>
                <span className='flex-fill'>World {worldIdx + 1}</span>
                {world.map((amount, ai) => {
                  const icon = amount > 2 ? images[3] : images[amount];
                  return (
                    <div className='text-center flex-fill'>
                      <img
                        key={`icon-${ai}-${worldIdx}`}
                        src={icon}
                        style={{ height: '35px' }}
                        alt='single'
                      />
                      <br />
                      <small
                        className='d-inline-block text-dark opacity-50'
                        style={{ minWidth: '60px' }}>
                        {amount > 0 ? `${amount} players` : <br />}
                      </small>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default UniversePreview;
