import React, { useState, useEffect } from 'react';

// Event Button
const EventButton = (props) => {
  // Props
  const { b, event, onClick = null } = props;

  // State
  const [show, setShow] = useState(false);

  // Hooks
  useEffect(() => {
    if (event !== b?.id) setShow(false);
  }, [event, b?.id]);

  // Methods
  const toggleDescription = () => {
    setShow(!show);
  };

  return (
    <div
      role='button'
      className={
        b?.id === event
          ? 'position-relative text-start pt-2 d-block w-100 btn border-primary bg-white'
          : 'position-relative text-start pt-2 d-block w-100 btn btn-white'
      }
      onClick={() => onClick(b?.id)}>
      <b className='fw-bold'>Event: {b?.title}</b>
      <div
        style={{
          margin: show ? '0 -1px' : '',
          zIndex: 999,
        }}
        className={
          show && b?.id === event
            ? 'position-absolute p-2 px-3 start-0 end-0 text-end bg-white border border-primary border-top-0 rounded-bottom shadow'
            : 'text-end text-dark'
        }>
        {show && b?.id === event && (
          <div className='text-start mb-2'>
            <small>{b?.description}</small>
          </div>
        )}
        <button
          disabled={b?.id === event ? false : true}
          onClick={toggleDescription}
          className='btn btn-sm btn-link'>
          <small>{show ? 'Hide' : 'Show'} description</small>
        </button>
      </div>
    </div>
  );
};

export default EventButton;
