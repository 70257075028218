import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Components
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Carousel from '../../components/Carousel';

// Data
// import { scenarios } from '../../constants';

// Actions
import { gameActions, modalActions } from '../../actions';

import { getSetupValues } from '../../helpers/utils';

// Previews: Chocolate
import ch_preview_0_landing from '../../assets/previews/choco/0_closed_landing.png';
import ch_preview_1_landing from '../../assets/previews/choco/1_landing.png';
import ch_preview_2_welcome from '../../assets/previews/choco/2_welcome.png';
import ch_preview_3_industry from '../../assets/previews/choco/3_industry.png';
import ch_preview_4_initial_assessment from '../../assets/previews/choco/4_initial_assessment.png';
import ch_preview_5_teams from '../../assets/previews/choco/5_teams.png';
import ch_preview_6_role_details from '../../assets/previews/choco/6_role_details.png';
import ch_preview_7_knowledge_check from '../../assets/previews/choco/7_knowledge_check.png';
import ch_preview_8_tutorial from '../../assets/previews/choco/8_tutorial.png';
import ch_preview_9a_events_prep from '../../assets/previews/choco/9a_events_prep.png';
import ch_preview_9b_events_play from '../../assets/previews/choco/9b_events_play.png';

// Previews: Escooter
import es_preview_1_landing from '../../assets/previews/escooter/1_landing.png';
import es_preview_2_welcome from '../../assets/previews/escooter/2_welcome.png';
import es_preview_3_industry from '../../assets/previews/escooter/3_industry.png';
import es_preview_4_initial_assessment from '../../assets/previews/escooter/4_initial_assessment.png';
import es_preview_5_teams from '../../assets/previews/escooter/5_teams.png';
import es_preview_6_role_details from '../../assets/previews/escooter/6_role_details.png';
import es_preview_7_knowledge_check from '../../assets/previews/escooter/7_knowledge_check.png';
import es_preview_8_tutorial from '../../assets/previews/escooter/8_tutorial.png';
import es_preview_9a_events_prep from '../../assets/previews/escooter/9a_events_prep.png';
import es_preview_9b_events_play from '../../assets/previews/escooter/9b_events_play.png';

// import closed_landing from '../../assets/closed_landing.png';
// import _landing from '../../assets/_landing.png';
// import _welcome from '../../assets/_welcome.png';
// import _industry from '../../assets/_industry.png';
// import initial_assessment from '../../assets/initial_assessment.png';
// import _teams from '../../assets/_teams.png';
// import _role_details from '../../assets/_role_details.png';
// import _knowledge_check from '../../assets/_knowledge_check.png';
// import tutorial from '../../assets/tutorial.png';
// import events_prep from '../../assets/events_prep.png';
// import events_play from '../../assets/events_play.png';

// import leaderboard from '../../assets/leaderboard.png';
// import release_events from '../../assets/release_events.png';

const FormRun = (props) => {
  // State
  // const [fetching, setFetching] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  // Modal
  const [modal, setModal] = useState(0);
  const [item, setItem] = useState({ registered: [] });
  const [confirmModalPrep, setConfirmModalPrep] = useState(false);
  const [confirmModalPlay, setConfirmModalPlay] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    setItem(game);
  }, [game]);

  // Methods
  // const handleSubmit = (props) => {
  //   dispatch(gameActions.updateGame(item));
  //   setSubmitted(true);
  // };

  const modalOpenToPrep = (e) => {
    // Set the confirmed state to false
    setConfirmModalPrep(!confirmModalPrep);

    // dispatch(
    //   modalActions.open({
    //     title: 'Open to Prepare',
    //     body: (
    //       <div className='text-start'>
    //         <p>
    //           Opening the simulation to either Prepare or Play will begin the
    //           simulation and lock your user assignments. Once the simulation is
    //           open, you cannot make any adjustments to user assignments, teams,
    //           or worlds.
    //         </p>
    //       </div>
    //     ),
    //     buttons: [
    //       <button
    //         key='modal-btn-close'
    //         className='btn btn-outline-secondary me-auto px-5'
    //         onClick={() => dispatch(modalActions.close())}>
    //         Cancel
    //       </button>,
    //       <button
    //         disabled={
    //           assignedPlayers.length === 0 ||
    //           item.events_ids.filter((id) => id !== '').length === 0
    //         }
    //         key='modal-btn-confirm'
    //         className='btn btn-primary px-5'
    //         onClick={openToPrep}>
    //         Open to Prepare
    //       </button>,
    //     ],
    //   })
    // );
  };

  const openToPrep = () => {
    let newItem = { ...item };
    newItem.prep = true;
    newItem.started = false;
    newItem.status = 'launched';
    setItem(newItem);

    // Reset the confirmed state to false
    setConfirmed(false);

    const toSubmit = getSetupValues(newItem);
    dispatch(gameActions.updateGame(toSubmit));

    // dispatch(modalActions.close());
    setConfirmModalPrep(false);
  };

  const modalOpenToPlay = () => {
    // Set the confirmed state to false
    setConfirmModalPlay(!confirmModalPlay);

    // dispatch(
    //   modalActions.open({
    //     title: 'Open To Play',
    //     body: (
    //       <div className='text-start'>
    //         <p>
    //           Opening the simulation to either Prepare or Play will begin the
    //           simulation and lock your user assignments. Once the simulation is
    //           open, you cannot make any adjustments to user assignments, teams,
    //           or worlds.
    //         </p>
    //       </div>
    //     ),
    //     buttons: [
    //       <button
    //         key='modal-btn-close'
    //         className='btn btn-outline-secondary me-auto px-5'
    //         onClick={() => dispatch(modalActions.close())}>
    //         Cancel
    //       </button>,
    //       <button
    //         disabled={
    //           assignedPlayers.length === 0 ||
    //           item.events_ids.filter((id) => id !== '').length === 0
    //         }
    //         key='modal-btn-confirm'
    //         className='btn btn-primary px-5'
    //         onClick={openToPlay}>
    //         Confirm
    //       </button>,
    //     ],
    //   })
    // );
  };

  const openToPlay = (props) => {
    let newItem = { ...item };
    newItem.prep = true;
    newItem.started = true;
    newItem.status = 'launched';
    setItem(newItem);

    // Reset the confirmed state to false
    setConfirmed(false);

    const toSubmit = getSetupValues(newItem);
    dispatch(gameActions.updateGame(toSubmit));

    // dispatch(modalActions.close());
    setConfirmModalPlay(false);

    setTimeout(() => {
      navigate('/games/' + newItem._id + '/players');
    }, 500);
  };

  const validateConfirmation = (e, string) => {
    const value = e.target.value;
    if (value.toUpperCase() === string.toUpperCase()) setConfirmed(true);
    else setConfirmed(false);
  };

  // Logic
  const isClose = item.status === 'saved';
  const isPrep =
    item.status === 'launched' && item.prep === true && item.started === false;
  const isPlay =
    item.status === 'launched' && item.prep === true && item.started === true;

  // Assigned players
  const assignedPlayers = item.players?.filter((p) => {
    // Get this player group
    const playerGroup = item?.groups?.find((g) =>
      g?.players?.find((gp) => gp._id === p._id)
    );
    return playerGroup ? true : false;
  });

  // Unassigned players are not present in any group
  const unassignedPlayers = item.players?.filter((p) => {
    // Get this player group
    const playerGroup = item?.groups?.find((g) =>
      g?.players?.find((gp) => gp._id === p._id)
    );
    return playerGroup ? false : true;
  });

  return (
    <div className='d-flex flex-column h-100'>
      <div className='row flex-grow-1'>
        <div className='col-3 border-end mt-4 mb-4'>
          <h6>Open simulation for play</h6>
          <p>
            Choose “Open to Prepare” in order to allow users to familiarize
            themselves with their role and the gameplay tutorial. Choose “Open
            for Play” to allow users to see their role, tutorial, and to begin
            playing through events. Note that once the simulation is opened for
            play, the settings can no longer be altered.
          </p>
        </div>

        <div className='col-9 d-flex justify-content-center mb-4 mt-4'>
          {/* CLOSED */}
          <div className={`card mx-3 w-25 ${isClose ? '' : 'bg-transparent'}`}>
            <div
              className={`card-header text-center ${
                isClose ? 'bg-secondary text-white' : ''
              }`}>
              CLOSED
            </div>
            <div className='card-body text-center'>
              <p className='card-text mb-4'>Users will have access to:</p>

              <p className='card-text py-2 m-0 border-bottom'>
                Welcome
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom opacity-50'>
                Industry Information
              </p>
              <p className='card-text py-2 m-0 border-bottom opacity-50'>
                Assigned Role
              </p>
              <p className='card-text py-2 m-0 border-bottom opacity-50'>
                Team Chat (if applicable)
              </p>
              <p className='card-text py-2 m-0 border-bottom opacity-50'>
                Events Center
              </p>

              {isClose ? (
                <button className='btn btn-sm btn-light mt-4'>
                  Current Status
                </button>
              ) : (
                <button className='btn btn-sm btn-secondary mt-4 disabled'>
                  Open to CLOSE
                </button>
              )}

              <hr className='my-3' />

              {/* {isClose ? ( */}
              <button
                onClick={(e) => setModal(1)}
                className='btn btn-sm btn-dark'>
                Preview Student
              </button>
              {/* // ) : (
              //   <button className='btn btn-sm btn-dark disabled'>
              //     Preview Student
              //   </button>
              // )} */}
            </div>
          </div>

          {/* PREP */}
          <div className={`card mx-3 w-25 ${isPrep ? '' : 'bg-transparent'}`}>
            <div
              className={`card-header text-center ${
                isPrep ? 'bg-secondary text-white' : ''
              }`}>
              PREPARE
            </div>
            <div className='card-body text-center'>
              <p className='card-text mb-4'>Users will have access to:</p>

              <p className='card-text py-2 m-0 border-bottom'>
                Welcome{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Industry Information{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Assigned Role{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Team Chat (if applicable){' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Events Center (Closed){' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>

              {isPrep ? (
                <button className='btn btn-sm btn-light mt-4'>
                  Current Status
                </button>
              ) : isClose ? (
                <button
                  onClick={modalOpenToPrep}
                  className='btn btn-sm btn-primary mt-4'>
                  Open to Prepare
                </button>
              ) : (
                <button className='btn btn-sm btn-secondary mt-4 disabled'>
                  Open to Prepare
                </button>
              )}

              <hr className='my-3' />

              {/* {isPrep ? ( */}
              <button
                className='btn btn-sm btn-dark'
                onClick={(e) => setModal(2)}>
                Preview Student
              </button>
              {/* ) : (
                <button className='btn btn-sm btn-dark disabled'>
                  Preview Student
                </button>
              )} */}
            </div>
          </div>

          {/* PLAY */}
          <div className={`card mx-3 w-25 ${isPlay ? '' : 'bg-transparent'}`}>
            <div
              className={`card-header text-center ${
                isPlay ? 'bg-secondary text-white' : ''
              }`}>
              PLAY
            </div>
            <div className='card-body text-center'>
              <p className='card-text mb-4'>Users will have access to:</p>

              <p className='card-text py-2 m-0 border-bottom'>
                Welcome{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Industry Information{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Assigned Role{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Team Chat (if applicable){' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>
              <p className='card-text py-2 m-0 border-bottom'>
                Events Center{' '}
                <FontAwesomeIcon
                  icon={faCheck}
                  className='text-success float-end mt-1'
                />
              </p>

              {isPlay ? (
                <button className='btn btn-sm btn-light mt-4'>
                  Current Status
                </button>
              ) : (
                <button
                  disabled={
                    item?.status === 'launched' && item?.started ? true : false
                  }
                  onClick={modalOpenToPlay}
                  className='btn btn-sm btn-primary mt-4'>
                  Open for Play
                </button>
              )}

              <hr className='my-3' />

              {/* {isPlay ? ( */}
              <button
                className='btn btn-sm btn-dark'
                onClick={(e) => setModal(3)}>
                Preview Student
              </button>
              {/* ) : (
                <button className='btn btn-sm btn-dark disabled'>
                  Preview Student
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <footer className='bg-white p-3 text-end'>
        {/* {!submitted && (
          <button className='btn btn-primary' onClick={handleSubmit}>
            Submit
          </button>
        )}
        {submitted && (
          <Link to={`../experience`} className='btn btn-primary'>
            Continue
          </Link>
        )} */}
      </footer>

      {/* Confirmation modal: PREP */}
      <Modal isOpen={confirmModalPrep} centered={true} toggle={modalOpenToPrep}>
        <ModalHeader toggle={modalOpenToPrep}>Open to Prepare</ModalHeader>
        <ModalBody>
          <p>
            Opening the simulation to either Prepare or Play will begin the
            simulation and lock your user assignments. Once the simulation is
            open, you cannot make any adjustments to user assignments, teams, or
            worlds.
          </p>
          {assignedPlayers?.length === 0 && (
            <p className='mb-3 alert alert-warning'>
              You need to{' '}
              <Link to='../users' className='fw-bold'>
                assign users
              </Link>{' '}
              to worlds before you can open this session.
            </p>
          )}
          <p>
            <input
              type='text'
              className='form-control'
              placeholder='Type "PREPARE" to confirm'
              onChange={(e) => validateConfirmation(e, 'prepare')}
              disabled={assignedPlayers?.length === 0 ? true : false}
            />
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            key='modal-btn-close'
            className='btn btn-outline-secondary me-auto px-5'
            onClick={modalOpenToPrep}>
            Cancel
          </button>
          <button
            disabled={
              !confirmed ||
              assignedPlayers?.length === 0 ||
              item?.events_ids?.filter((id) => id !== '').length === 0
            }
            key='modal-btn-confirm'
            className='btn btn-primary px-5'
            onClick={openToPrep}>
            Open to Prepare
          </button>
        </ModalFooter>
      </Modal>

      {/* Confirmation modal: PLAY */}
      <Modal isOpen={confirmModalPlay} centered={true} toggle={modalOpenToPlay}>
        <ModalHeader toggle={modalOpenToPlay}>Open to Play</ModalHeader>
        <ModalBody>
          <p>
            Opening the simulation to either Prepare or Play will begin the
            simulation and lock your user assignments. Once the simulation is
            open, you cannot make any adjustments to user assignments, teams, or
            worlds.
          </p>
          {assignedPlayers?.length === 0 && (
            <p className='mb-3 alert alert-warning'>
              You need to{' '}
              <Link to='../users' className='fw-bold'>
                assign users
              </Link>{' '}
              to worlds before you can open this session.
            </p>
          )}
          <p>
            <input
              type='text'
              className='form-control'
              placeholder='Type "PLAY" to confirm'
              onChange={(e) => validateConfirmation(e, 'play')}
              disabled={assignedPlayers?.length === 0 ? true : false}
            />
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            key='modal-btn-close'
            className='btn btn-outline-secondary me-auto px-5'
            onClick={modalOpenToPlay}>
            Cancel
          </button>
          <button
            disabled={
              !confirmed ||
              assignedPlayers?.length === 0 ||
              item?.events_ids?.filter((id) => id !== '').length === 0
            }
            key='modal-btn-confirm'
            className='btn btn-primary px-5'
            onClick={openToPlay}>
            Open to Play
          </button>
        </ModalFooter>
      </Modal>

      {/* Student preview mode */}
      <Modal
        isOpen={modal > 0}
        toggle={() => setModal(0)}
        centered={true}
        size={'lg'}>
        <ModalHeader toggle={() => setModal(0)}>Preview</ModalHeader>
        <ModalBody className='text-center'>
          {/* <img src={previewImg} alt='' className='img-fluid' /> */}
          {modal === 1 && (
            <Carousel
              interval={false}
              slide={false}
              items={
                game?.scenario === 'chocolate'
                  ? [ch_preview_0_landing]
                  : [es_preview_2_welcome]
              }
            />
          )}
          {modal === 2 && (
            <Carousel
              interval={false}
              slide={false}
              items={
                game?.scenario === 'chocolate'
                  ? [
                      ch_preview_1_landing,
                      ch_preview_2_welcome,
                      ch_preview_3_industry,
                      ch_preview_4_initial_assessment,
                      ch_preview_5_teams,
                      ch_preview_6_role_details,
                      ch_preview_7_knowledge_check,
                      ch_preview_8_tutorial,
                      ch_preview_9a_events_prep,
                    ]
                  : [
                      es_preview_1_landing,
                      es_preview_2_welcome,
                      es_preview_3_industry,
                      es_preview_4_initial_assessment,
                      es_preview_5_teams,
                      es_preview_6_role_details,
                      es_preview_7_knowledge_check,
                      es_preview_8_tutorial,
                      es_preview_9a_events_prep,
                    ]
              }
            />
          )}
          {modal === 3 && (
            <Carousel
              interval={false}
              slide={false}
              items={
                game?.scenario === 'chocolate'
                  ? [
                      ch_preview_1_landing,
                      ch_preview_2_welcome,
                      ch_preview_3_industry,
                      ch_preview_4_initial_assessment,
                      ch_preview_5_teams,
                      ch_preview_6_role_details,
                      ch_preview_7_knowledge_check,
                      ch_preview_8_tutorial,
                      ch_preview_9b_events_play,
                    ]
                  : [
                      es_preview_1_landing,
                      es_preview_2_welcome,
                      es_preview_3_industry,
                      es_preview_4_initial_assessment,
                      es_preview_5_teams,
                      es_preview_6_role_details,
                      es_preview_7_knowledge_check,
                      es_preview_8_tutorial,
                      es_preview_9b_events_play,
                    ]
              }
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FormRun;
