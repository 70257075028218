export const gameConstants = {
  SET_DATA: 'SET_GAME_DATA',
  SET_LEADERBOARD: 'SET_GAME_LEADERBOARD',
  SET_DECISIONS_LIST: 'SET_GAME_DECISIONS_LIST',
  SET_SHORT_DECISIONS_LIST: 'SET_GAME_SHORT_DECISIONS_LIST',
  SET_FEEDBACK_LIST: 'SET_GAME_FEEDBACK_LIST',
  SET_FORCES_DATA: 'SET_FORCES_DATA',
  SET_EVENTS_DATA: 'SET_EVENTS_DATA',
  ADD_PLAYERS: 'ADD_PLAYERS',
  SET_ROUND: 'SET_ROUND',
};
