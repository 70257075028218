import React from 'react';
import { connect } from 'react-redux';

// Components
import Header from '../../components/Header';

// Assets
import fileIntroductionSlides from '../../assets/files/7883_5Forces_PPTintro.pptx';
// import fileDebriefPPT_Chocolate from '../../assets/files/7884_5Forces_debriefPPT_C.pptx';
// import fileDebriefPPT_Escooter from '../../assets/files/7902_5Forces_debriefPPT_ES.pptx';

const Materials = (props) => {
  // Hook: Selector game scenario
  // const scenario = useSelector((state) => state.game.scenario);

  // Render
  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title'>Facilitator Materials</h2>

      <div className='p-3'>
        <p className='lead'>
          <a href={fileIntroductionSlides}>Introductory Slides</a>
        </p>
        {/* {scenario === 'chocolate' && (
          <p className='lead'>
            <a href={fileDebriefPPT_Chocolate}>
              Debrief PPT: Chocolate (Generic)
            </a>
          </p>
        )}
        {scenario === 'escooter' && (
          <p className='lead'>
            <a href={fileDebriefPPT_Escooter}>
              Debrief PPT: eSCooter (Generic)
            </a>
          </p>
        )} */}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedMaterialsPage = connect(mapStateToProps)(Materials);
export { connectedMaterialsPage as GameMaterials };
