import React from 'react';

import { Card, CardHeader, CardBody } from 'reactstrap';
/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

// Components
import ButtonTabs from './ButtonTabs';
import ButtonTabsFeedback from './ButtonTabsFeedback';
import { useSelector } from 'react-redux';

import './ForceAssessment.css';

// Constants
const ASSESSMENT_OPTIONS = ['Increase', 'Decrease', 'No Change'];

const ForceAssessment = (props) => {
  // Props
  const {
    title = '',
    name = '',
    brand = '',
    forceName = '',
    value = '',
    correct = null,
    toggle = true,
    className = '',
    displayOptions = true,
    feedback = false,
    list = [],
    onChange = null,
  } = props;

  // Theme props
  const themeScenario = useSelector((state) => state.game.scenario);
  const settingsDarkTheme = true;
  const themeLayoutClass = settingsDarkTheme
    ? 'bg-cinder'
    : 'bg-white bg-opacity-70 text-raisin';
  const themeBodyClass = settingsDarkTheme ? 'text-white' : 'text-cinder';
  const themeHeaderClass = settingsDarkTheme
    ? 'bg-black bg-opacity-30 text-almond'
    : 'bg-white text-cinder';
  const cardOpacity = themeScenario === 'escooter' ? '' : '';

  return (
    <Card
      tabIndex={0}
      aria-label={title}
      className={`${themeLayoutClass} ${className} ${cardOpacity}`}>
      <CardHeader
        className={`${themeHeaderClass} force-assessment-header`}
        style={{ color: '#F6DECF', padding: '0.75rem .5rem .75rem .75rem' }}>
        <FontAwesomeIcon
          icon={faDotCircle}
          size='1x'
          className='text-pastel align-middle'
        />
        <p className='d-inline-block ms-2 mb-0 serif align-middle'>{title}</p>
      </CardHeader>
      {list.length > 0 && (
        <CardBody className={themeBodyClass}>
          {/* DECISIONS */}
          {list.map((item, index) => (
            <p
              key={`list-item-${index}`}
              style={{ color: '#F6DECF' }}
              className={`fs-6 fw-lighter p-1 mb-3 lh-base`}>
              {brand} (<span className='text-lowercase'>{forceName}</span>)
              decided to {item.charAt(0).toLowerCase() + item.slice(1)}. What
              effect do you think this had on {brand}'s financial position?
            </p>
          ))}

          {/* Display Options */}
          {displayOptions && (
            <div className='px-2x'>
              {toggle && (
                <ButtonTabs
                  className='row-cols-3'
                  name={name}
                  value={value}
                  toggle={toggle}
                  options={ASSESSMENT_OPTIONS}
                  onChange={onChange}
                />
              )}
              {!toggle && correct !== null && (
                <ButtonTabsFeedback
                  options={ASSESSMENT_OPTIONS}
                  selected={value}
                  correct={correct}
                />
              )}
            </div>
          )}

          {/* Display Feedback */}
          {feedback && (
            <div className='mt-3 px-2x'>
              <p className='mb-2'>
                <small>Feedback:</small>
              </p>
              <small className='fw-lighter'>{feedback}</small>
            </div>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default ForceAssessment;
