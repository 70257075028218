import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, useDispatch } from 'react-redux';
// import numeral from 'numeral';

// Actions
import { gameActions } from '../../actions';

// import { Modal, ModalBody, Tooltip } from 'reactstrap';
import Header from '../../components/Header';
import PresentationBtn from '../../components/PresentationBtn';
import { DebriefMenu } from './debrief_menu';
// import GroupDebrief from '../../components/GroupDebrief';
import ForceAssessment from '../../components/ForceAssessment';
import EventButton from '../../components/EventButton';

// Data
import { scenarios } from '../../constants';

// Constants
const ASSESSMENT_OPTIONS = ['Increase', 'Decrease', 'No Change'];

const Analysis = (props) => {
  // Props
  const { game } = props;

  // State
  const [event, setEvent] = useState(1);
  const [world, setWorld] = useState(1);

  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // Effects
  useEffect(() => {
    if (params.id) {
      dispatch(gameActions.getDecisionsList(params.id));
      dispatch(gameActions.getFeedbackList(params.id));
      dispatch(gameActions.getForcesData(params.id));
    }
  }, [params?.id, dispatch]);

  useEffect(() => {
    if (game?.events_ids?.length > 0) setEvent(game.events_ids[0]);
  }, [game?.events_ids]);

  // Functions
  // const rowClick = (data) => {
  //   setGroup(data);
  // };
  const handleEvent = (id) => setEvent(id);
  const handleForce = (id) => setWorld(id);

  // Logic
  // console.log(game);

  // Event buttons
  const eventButtons = game?.events_ids
    ?.map((id) => {
      const theScenario = scenarios.find((s) => s.id === game?.scenario);
      const theEvent = theScenario?.events.find((e) => e.id === id);
      return theEvent;
    })
    .filter((e) => e !== undefined)
    .map((b) => {
      return (
        <div key={b?.id} className='col'>
          <EventButton b={b} event={event} onClick={handleEvent} />
        </div>
      );
    });

  // Force buttons
  const totalWorlds = game?.groups
    // Exclude groups that have only bots and belong to the same world
    ?.filter((g) => {
      // Get all the groups that belong to this world
      const theWorldGroups = game?.groups?.filter((gg) => gg.world === g.world);
      // Get all the groups that have only bots
      const theBotGroups = theWorldGroups?.filter((gg) =>
        gg?.players?.every((p) => p.type === 'bot')
      );
      // If the current group is not a bot group, return true
      return !theBotGroups?.some((gg) => gg._id === g._id);
    })
    // Return the world number
    .map((g) => g.world);

  const uniqueWorlds = [...new Set(totalWorlds)];
  const worldButtons = [
    // 'All Worlds',
    ...uniqueWorlds.map((w) => `World ${w}`),
  ].map((b, bi) => {
    return (
      <div key={b} className='col-12 mt-3'>
        <button
          className={
            bi + 1 === world
              ? 'd-block w-100 btn btn-outline-primary'
              : 'd-block w-100 btn btn-white'
          }
          onClick={() => handleForce(bi + 1)}>
          {b}
        </button>
      </div>
    );
  });

  // const forceLabels = [
  //   'Supplier',
  //   'Buyer',
  //   'Substitute',
  //   'Focal Firm',
  //   'Competitor',
  //   'New Entrant',
  // ];
  const eventOrder = game?.events_ids?.indexOf(event) + 1;
  const selectedForces = eventOrder % 2 === 0 ? [5, 3, 6] : [1, 4, 2];

  // Get the decisions
  const theEventDecisionsByForces = game?.decisions_list?.[`event_${event}`];
  const theEventDecisionsFeedback = game?.feedback_list?.[`event_${event}`];

  // Analyzed Decisions
  const analyzedDecisions = selectedForces.map((sf, sfi) => {
    // Get the force's submitted response for this event
    const theFullForce = game?.groups
      ?.filter((g) => (world > 0 ? g.world === world : true))
      .find((g) => g.rindex === sf);
    const theFirstSubmission = theFullForce?.items?.find(
      (d) => d.event === event
    );
    // Grab the 1st decision from the first submission
    const theFirstDecisionCode = theFirstSubmission?.data?.[0] || '';
    // Get the 1st decision for this force
    const theEventForceDecisions = theEventDecisionsByForces?.[`force_${sf}`];
    const theFullDecision = theEventForceDecisions?.find(
      (d) => d.code === theFirstDecisionCode
    );

    // Direction: Increase, Decrease, No Change
    let selectedDirection = null;
    selectedDirection = theFullDecision?.score > 0 ? 0 : selectedDirection;
    selectedDirection = theFullDecision?.score < 0 ? 1 : selectedDirection;
    selectedDirection = theFullDecision?.score === 0 ? 2 : selectedDirection;

    // Get the full feedback
    const theFullFeedback = theEventDecisionsFeedback?.[theFullDecision?.code];

    // List the groups & players
    const theGroups = game?.groups
      ?.filter((g) => (world > 0 ? g.world === world : true))
      ?.map((g) => {
        const thePlayers =
          g?.players
            ?.filter((p) => p.type !== 'bot')
            .map((p, pi) => {
              const isLast = pi === g?.players?.length - 1;
              const theFullName = `${p?.name} ${p?.lastname}`;
              const theCurrentAssessment = p?.assessments?.[`event_${event}`];
              const theCurrentDecision =
                theCurrentAssessment?.data?.[`force_${sf}`];

              const hasResponse =
                typeof theCurrentDecision !== 'undefined' ? true : false;
              const isRight =
                theCurrentDecision === selectedDirection ? true : false;
              return (
                <p
                  key={`pl-${p?._id}`}
                  className={`mb-0 ${
                    !hasResponse ? '' : isRight ? 'text-success' : 'text-danger'
                  } ${isLast ? 'border-bottom mb-2 pb-2' : ''}`}>
                  <small>
                    {theFullName.trim().length > 0 ? theFullName : p.email}{' '}
                    <i className='text-secondary ms-2'>
                      {hasResponse
                        ? ASSESSMENT_OPTIONS[theCurrentDecision]
                        : 'No response.'}
                    </i>
                  </small>
                </p>
              );
            }) || [];
        return thePlayers;
      });

    // Get the decing force data
    const decidingForce = game?.forces_data
      ? Object.keys(game?.forces_data).find(
          (fkey) => game?.forces_data[fkey].id === theFullForce?.rindex
        )
      : null;
    const brand = game?.forces_data?.[decidingForce]?.brand;
    const forceName = game?.forces_data?.[decidingForce]?.name;

    return (
      <div className='col'>
        {theFullDecision ? (
          <ForceAssessment
            className={`bg-dark text-white h-auto`}
            title={`Review Question ${sfi + 1} of 3`}
            brand={brand}
            forceName={forceName}
            toggle={false}
            list={[theFullDecision?.description || '']}
            value=''
            correct={selectedDirection !== null ? selectedDirection : null}
            feedback={
              <div className='d-flex'>
                <p>
                  {theFullFeedback ||
                    `${theFullDecision?.code} feedback details not available.`}
                </p>
              </div>
            }
          />
        ) : (
          <div className='alert alert-secondary'>Decision Pending.</div>
        )}

        <div className='mt-3'>{theFullDecision ? theGroups : null}</div>
      </div>
    );
  });

  // const someGroupDecided = game?.groups?.some(g => g?.items?.length > 0);

  // Render
  return (
    <div>
      <Header />

      <h2 className='mt-3 sec-title'>
        <b>Results</b>: Analysis
        <PresentationBtn />
        <small className='float-end'>
          <DebriefMenu game_id={game._id} location={location} />
        </small>
      </h2>

      <div className='row mt-3 pb-3'>
        <h6 className='mt-0'>Review Event</h6>
        {eventButtons}
      </div>

      <hr className='bg-secondary bg-opacity-50' />

      <div className='row pt-2'>
        <div className='col-2'>
          <h6 className='mt-0'>Decisions by World</h6>
          <div className='row'>{worldButtons}</div>
        </div>
        <div className='col-10'>
          <div className='row py-3 bg-white rounded-3'>{analyzedDecisions}</div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedAnalysisPage = connect(mapStateToProps)(Analysis);
export { connectedAnalysisPage as GameAnalysis };
