import React, { useState, useEffect, useRef } from 'react';

import './ButtonTabs.css';

const ButtonTabs = (props) => {
  const {
    name = '',
    value = '',
    toggle = true,
    options = [],
    optionsClass = [],
    className = '',
    size = '',
    btnClass = '',
    onChange = null,
  } = props;

  // State
  const [active, setActive] = useState(null);

  // Hooks
  const firstUpdate = useRef(true);

  // Effects
  useEffect(() => {
    // console.log('Switch!', firstUpdate.current, checked);
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      if (active !== null && active !== '' && typeof onChange === 'function')
        onChange({ name, active });
    }
  }, [active, name, onChange]);

  // Effect: Update the checked state from prop
  useEffect(() => {
    setActive(value);
  }, [value]);

  // Functions
  const handleClick = (e, index) => {
    setActive(index);
  };

  // Logic
  let activeClassName = 'btn-pastel';
  const inactiveClassName = 'btn-outline-pastel';
  const optionButtons = options.map((option, index) => {
    activeClassName =
      optionsClass[index]?.length > 0 ? optionsClass[index] : activeClassName;
    return (
      <div key={`btn-${index}`} className='col'>
        <button
          name={name}
          className={`btn btn-${size} btn-tab w-100 ${
            toggle ? '' : 'no-toggle'
          } ${btnClass} ${
            index === active ? activeClassName : inactiveClassName
          }`}
          onClick={toggle ? (e) => handleClick(e, index) : null}>
          {option}
        </button>
      </div>
    );
  });

  return <div className={`row ${className}`}>{optionButtons}</div>;
};

export default ButtonTabs;
