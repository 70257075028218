import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

/* FontAwesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../actions';

// Components
import ForceAssessment from './ForceAssessment';

// Constants
// const ASSESSMENT_OPTIONS = ['Increase', 'Decrease', 'No Change'];

// const forceLabels = [
//   'Supplier',
//   'Buyer',
//   'Substitute',
//   'Focal Firm',
//   'Competitor',
//   'New Entrant',
// ];

const PlayerAnalysis = (props) => {
  // Get props
  const { event = 1, eventPos = 1, player = null } = props;

  // Hooks
  const dispatch = useDispatch();
  const params = useParams();
  const game = useSelector((state) => state.game);

  // Effects
  useEffect(() => {
    if (params.id) {
      dispatch(gameActions.getDecisionsList(params.id));
      dispatch(gameActions.getFeedbackList(params.id));
    }
  }, [dispatch, params.id]);

  // Logic
  const selectedForces = eventPos % 2 === 0 ? [5, 3, 6] : [1, 4, 2];
  // Get the decisions
  const theEventDecisionsByForces = game?.decisions_list?.[`event_${event}`];
  const theEventDecisionsFeedback = game?.feedback_list?.[`event_${event}`];
  // Get the world for this player
  const thePlayeGroup = game?.groups?.find((g) =>
    g.players.find((p) => p.email === player)
  );
  const thePlayer = thePlayeGroup?.players?.find((p) => p.email === player);
  // console.log(thePlayer);

  // Analyzed Decisions
  const analyzedDecisions = selectedForces.map((sf, sfi) => {
    // Get the force's submitted response for this event
    const theFullForce = game?.groups
      ?.filter((g) =>
        thePlayeGroup?.world > 0 ? g.world === thePlayeGroup?.world : true
      )
      .find((g) => g.rindex === sf);
    // console.log({ theFullForce });
    const theFirstSubmission = theFullForce?.items?.find(
      (d) => d.event === event
    );

    // Grab the 1st decision from the first submission
    const theFirstDecisionCode = theFirstSubmission?.data?.[0] || '';
    // Get the 1st decision for this force
    const theEventForceDecisions = theEventDecisionsByForces?.[`force_${sf}`];
    const theFullDecision = theEventForceDecisions?.find(
      (d) => d.code === theFirstDecisionCode
    );

    // The player response
    const theValue = thePlayer?.assessments?.[`event_${event}`];
    const value =
      thePlayer?.assessments?.[`event_${event}`]?.data?.[`force_${sf}`];

    // Direction: Increase, Decrease, No Change
    let selectedDirection = null;
    selectedDirection = theFullDecision?.score > 0 ? 0 : selectedDirection;
    selectedDirection = theFullDecision?.score < 0 ? 1 : selectedDirection;
    selectedDirection = theFullDecision?.score === 0 ? 2 : selectedDirection;

    // Get the full feedback
    const theFullFeedback = theEventDecisionsFeedback?.[theFullDecision?.code];

    // Icon
    const isCorrect =
      selectedDirection !== null ? selectedDirection === value : null;
    const icon = (
      <FontAwesomeIcon
        icon={isCorrect ? faCheckCircle : faTimesCircle}
        className={`text-${isCorrect ? 'seafoam' : 'danger'} mt-1 me-2`}
      />
    );

    // Get the decing force data
    const decidingForce = game?.forces_data
      ? Object.keys(game?.forces_data).find(
          (fkey) => game?.forces_data[fkey].id === theFullForce?.rindex
        )
      : null;
    const brand = game?.forces_data?.[decidingForce]?.brand;
    const forceName = game?.forces_data?.[decidingForce]?.name;

    // console.log(event, thePlayer?.email, value);

    return (
      <div key={`fd-${sfi}`} className='col'>
        {theValue?.status === 'confirmed' && theFullDecision ? (
          <ForceAssessment
            className={`bg-dark text-white text-start h-100`}
            title={`Review Question ${sfi + 1} of 3`}
            brand={brand}
            forceName={forceName}
            toggle={false}
            list={[theFullDecision?.description || '']}
            value={value}
            correct={selectedDirection !== null ? selectedDirection : null}
            feedback={
              <div className='d-flex'>
                {icon}
                <p>
                  <span className='fw-normal'>
                    {isCorrect ? 'Correct. ' : 'Incorrect. '}
                  </span>
                  {theFullFeedback ||
                    `${theFullDecision?.code} feedback details not available.`}
                </p>
              </div>
            }
          />
        ) : (
          <div className='alert alert-secondary'>Decision Pending.</div>
        )}
      </div>
    );
  });

  return <div className='row'>{analyzedDecisions}</div>;
};

export default PlayerAnalysis;
