import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import queryString from 'query-string';

export default class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.qs.pag ? Number(this.props.qs.pag) : 1,
      totalPages: Number(this.props.totalPages) || 1,
      qs: this.props.qs || {},
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState(
      {
        currentPage: Number(event.target.id),
      },
      () => {
        this.props.pageChange &&
          this.props.pageChange(Number(this.state.currentPage));
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ totalPages: Number(nextProps.totalPages) || 1 });
  }

  render() {
    const { totalPages, currentPage } = this.state;

    // Logic for displaying page numbers
    const pageNumbers = [];
    const startAt =
      totalPages > 10 ? (currentPage > 5 ? currentPage - 5 : 1) : 1;
    let finishAt =
      totalPages > 10 ? (currentPage > 5 ? currentPage + 4 : 10) : totalPages;
    finishAt = finishAt > totalPages ? totalPages : finishAt;
    for (let i = startAt; i <= finishAt; i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
      let params = { ...this.state.qs };
      params.pag = number;
      // let query = queryString.stringify(params)
      let active = number === parseInt(currentPage, 10) ? 'active' : '';
      return (
        <li key={number} className={'page-item ' + active}>
          {/* <a href={ '?' + query } id={ number } className="page-link" onClick={this.handleClick}> { number } </a> */}
          <button id={number} className='page-link' onClick={this.handleClick}>
            {' '}
            {number}{' '}
          </button>
        </li>
      );
    });

    return (
      <div>
        <ul
          id='page-numbers'
          className='pagination pagination justify-content-center'>
          {renderPageNumbers}
        </ul>
      </div>
    );
  }
}
