import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Data
// import { scenarios } from '../../constants';

// Chocolaate
import initial_assessment from '../../assets/previews/choco/4_initial_assessment.png';
import leaderboard from '../../assets/previews/choco/10_leaderboard.png';
import release_events from '../../assets/previews/choco/preview_release.png';
import final_reflection from '../../assets/previews/choco/preview_final_reflection.png';
// import animations_off from '../../assets/animations_off.png';

// Escooter
import initial_assessment_escooter from '../../assets/previews/escooter/4_initial_assessment.png';
import leaderboard_escooter from '../../assets/previews/escooter/10_leaderboard.png';
import final_reflection_escooter from '../../assets/previews/escooter/preview_final_reflection.png';
// import animations_off_escooter from '../../assets/animations_off_escooter.png';

// Actions
import { gameActions, modalActions } from '../../actions';

import { getSetupValues } from '../../helpers/utils';

// Constants
const timerDetails = {
  notimer: ['', '', '', '', ''],
  standard: ['No Timer', '10 minutes', '5 minutes', '5 minutes', '5 minutes'],
  extended: [
    'No Timer',
    '15 minutes',
    '7:30 minutes',
    '7:30 minutes',
    '7:30 minutes',
  ],
};

const FormExperience = (props) => {
  // State
  // const [fetching, setFetching] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState({ registered: [] });
  const [modal, setModal] = useState(0);

  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const game = useSelector((state) => state.game);

  // Logic
  // Validation: One single user per world
  const uniqueWorlds = [...new Set(item?.groups?.map((g) => g.world))];
  const hasOneUserPerWorld = uniqueWorlds.every((w) => {
    const thisWorldGroups = item?.groups.filter(
      (g) => g.world === w && g.type === 'user' && g.players.length === 1
    );
    return thisWorldGroups.length === 1;
  });

  // Effects
  useEffect(() => {
    setItem(game);
  }, [game]);

  useEffect(() => {
    if (!hasOneUserPerWorld && item?.pacing === 'notimer') {
      let newItem = { ...item };
      newItem.pacing = 'standard';
      newItem['changed'] = true;
      setItem(newItem);
    }
  }, [hasOneUserPerWorld, item]);

  // Methods
  const validationLaunch = () => {
    const isLaunched =
      game?.started && game?.status === 'launched' ? true : false;
    if (isLaunched)
      return dispatch(
        modalActions.open({
          title: 'Session Launched',
          body: 'This session has already been launched. You can not modify its configuration anymore.',
          buttons: (
            <button
              key='modal-btn-close'
              className='btn btn-light'
              onClick={() => dispatch(modalActions.close())}>
              Cancel
            </button>
          ),
        })
      );
    else return false;
  };

  const handleSubmit = ({ redir = false }) => {
    const toSubmit = getSetupValues(item);
    dispatch(gameActions.updateGame(toSubmit));
    // setSubmitted(true);
    if (redir === true)
      navigate(`/games/${item?._id}/setup/run` + location.search, {
        replace: false,
      });
  };

  const handleChange = (e) => {
    if (validationLaunch()) return;
    const { name, value } = e.target;
    let newItem = { ...item };
    newItem[name] =
      name === 'players_text' ? value.replace(/\t/g, ', ') : value;
    newItem['changed'] = true;

    setItem(newItem);
    // dispatch(gameActions.updateGame(newItem));
  };

  const handleSwitchChange = (e) => {
    if (validationLaunch()) return;
    const { name, checked } = e.target;
    let newItem = { ...item };
    newItem[name] = checked;
    newItem['changed'] = true;

    // Validations
    if (name === 'settings_release' && checked)
      newItem['settings_domino'] = false;

    if (name === 'settings_domino' && checked)
      newItem['settings_release'] = false;

    setItem(newItem);
    // dispatch(gameActions.updateGame(newItem));
  };

  const togglePacingTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          In sessions where users are online simultaneously, two timer options
          are available.
        </li>
        <li className='mb-2'>
          Standard Timer (default est. duration): 45 min.
        </li>
        <li className='mb-2'>
          Extended Timer (default est. duration): 120 min.
        </li>
        <li className='mb-2'>
          No timer is available in sessions where users work independently.
        </li>
        <li className='mb-0'>Once ready, click the “Continue” button.</li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Determine Pacing',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const toggleCustomizeTooltip = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <ul className='fw-light'>
        <li className='mb-2'>
          Click the button labeled “Make Customizations” to display components
          that can be added to the user experience
        </li>
        <li className='mb-2'>
          Instructors can also choose to control when events are presented to
          users.
        </li>
        <li className='mb-0'>
          Once ready, click the “Continue” button. Note that you do not need to
          make customizations to continue.
        </li>
      </ul>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Close
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Customize the Run',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const resetSettings = () => {
    const defaultSettings = {
      settings_survey: true,
      settings_leaderboard: true,
      settings_reflection: true,
      settings_release: false,
      settings_domino: false,
    };

    const newItem = { ...item, ...defaultSettings };
    dispatch(gameActions.updateGame(newItem));
    dispatch(modalActions.close());
    // setSubmitted(true);
  };

  const toggleResetSettings = (value) => {
    // Hide it
    if (!value) return dispatch(modalActions.close());

    // Show it
    const modalBody = (
      <div className='text-center'>
        <p>Are you sure you want to reset settings for this step?</p>
        <p>This decision cannot be undone.</p>
      </div>
    );
    const modalButtons = [
      <button
        key='modal-btn-close'
        className='btn btn-light'
        onClick={() => dispatch(modalActions.close())}>
        Cancel
      </button>,
      <button
        key='modal-btn-close'
        className='btn btn-primary'
        onClick={resetSettings}>
        Confirm
      </button>,
    ];
    dispatch(
      modalActions.open({
        title: 'Reset Settings',
        body: modalBody,
        buttons: modalButtons,
      })
    );
  };

  const numberEvent = game?.events_ids.filter((e) => e !== '').length;

  // Logic
  const disabledForm = ['saving', 'launched'].includes(game?.status)
    ? true
    : false;

  return (
    <div className='d-flex flex-column h-100'>
      <div className='row flex-grow-1'>
        {/* LEFT */}
        <div className='col-4 border-end mt-4'>
          <h6 className='mb-4'>
            Determine pacing{' '}
            <i
              className='ms-1'
              onClick={(e) => togglePacingTooltip(true)}
              style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faInfoCircle} className='text-primary' />
            </i>
          </h6>
          <div className='me-4 me-xl-0'>
            <div className='row flex-column flex-xl-row'>
              {/* PACING RADIO BUTTONS */}
              <div className='order-0 order-xl-1 col-12 col-xl-4 d-flex flex-xl-column mb-3 mb-xl-0 align-items-center justify-content-between justify-content-xl-start'>
                <div className='d-inline-block d-lg-block form-check ps-0'>
                  <label className='form-check-label' htmlFor='ppg-team-s'>
                    Standard
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='pacing'
                    value='standard'
                    checked={item.pacing === 'standard'}
                    disabled={disabledForm}
                    id='ppg-team-s'
                    onChange={handleChange}
                  />
                </div>
                <div className='d-md-block form-check ps-0'>
                  <label className='form-check-label' htmlFor='ppg-team-e'>
                    Extended
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='pacing'
                    value='extended'
                    checked={item.pacing === 'extended'}
                    disabled={disabledForm}
                    id='ppg-team-e'
                    onChange={handleChange}
                  />
                </div>

                {/* EXTEND THIS VALIDATION TO 1 PPW */}
                <div
                  className={`d-md-block form-check ps-0 ${
                    hasOneUserPerWorld ? '' : 'text-muted opacity-50'
                  }`}>
                  <label className='form-check-label' htmlFor='ppg-team-no'>
                    No Timer
                  </label>
                  <input
                    className='form-check-input float-none ms-2'
                    type='radio'
                    name='pacing'
                    value='notimer'
                    checked={item.pacing === 'notimer'}
                    id='ppg-team-no'
                    disabled={disabledForm || !hasOneUserPerWorld}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='order-1 order-xl-0 col-12 col-xl-8'>
                <p className='mb-3 small'>
                  In synchronous play, all stages of the simulation are timed in
                  order to move all users in a world together. The diagram on
                  the right displays the timer length for each stage. Selecting
                  Extended increases the timer by 50%.
                </p>
              </div>
              <div className='order-2 col-12'>
                <p className='mb-0 small'>
                  Individual play adds the option of an untimed run. In this
                  case there would be no timers at any stage.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT */}
        <div className='col-8 mt-3'>
          <div className='d-flex flex-column h-100 px-4'>
            <h6 className='mt-2 mb-4'>
              Structure of run with current selections
            </h6>

            <div className='d-flex flex-grow-1 text-center mt-0'>
              {/* LEFT */}
              <div className='flex-fill'>
                <div className='d-flex flex-column bg-white border p-2 h-100'>
                  <small className='d-block mb-3'>
                    <b>Onboarding</b>
                  </small>
                  <div className='d-flex flex-grow-1'>
                    {item?.settings_survey && (
                      <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 me-2 bg-light'>
                        <small>
                          Initial Industry
                          <br />
                          Assessment
                        </small>
                        <small className='badge bg-secondary mt-2'>
                          {/* {item.pacing === 'standard' ? 'No Timer' : 'No Timer'} */}
                          {timerDetails[item.pacing]?.[0]}
                        </small>
                      </div>
                    )}
                    <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 bg-light'>
                      <small>
                        Knowledge
                        <br />
                        Check
                      </small>
                      <small className='badge bg-secondary mt-2'>
                        {/* {item.pacing === 'standard' ? 'No Timer' : 'No Timer'} */}
                        {timerDetails[item.pacing]?.[0]}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {/* CENTER */}
              <div className='flex-fill mx-2'>
                <div className='d-flex flex-column bg-white border p-2 h-100'>
                  <small className='d-block mb-3'>
                    <b>Each Event ({numberEvent})</b>
                  </small>

                  <div className='d-flex flex-grow-1'>
                    <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 bg-light'>
                      <small>
                        Decisions &amp;
                        <br />
                        Reflection
                      </small>
                      <small className='badge bg-secondary mt-2'>
                        {/* {item.pacing === 'standard' ? '5 min' : '10 min'} */}
                        {timerDetails[item.pacing]?.[1]}
                      </small>
                    </div>
                    <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 mx-2 bg-light'>
                      <small>Analysis</small>
                      <small className='badge bg-secondary mt-2'>
                        {/* {item.pacing === 'standard' ? '3 min' : '6 min'} */}
                        {timerDetails[item.pacing]?.[2]}
                      </small>
                    </div>
                    <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 bg-light'>
                      <small>Outcome</small>
                      <small className='badge bg-secondary mt-2'>
                        {/* {item.pacing === 'standard' ? '2 min' : '4 min'} */}
                        {timerDetails[item.pacing]?.[3]}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-fill'>
                <div className='d-flex flex-column bg-white border p-2 h-100'>
                  <small className='d-block mb-3'>
                    <b>After All Events</b>
                  </small>
                  <div className='d-flex flex-grow-1'>
                    <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 bg-light'>
                      <small>
                        Final Industry
                        <br />
                        Assessment
                      </small>
                      <small className='badge bg-secondary mt-2'>
                        {/* {item.pacing === 'standard' ? '5 min' : '10 min'} */}
                        {timerDetails[item.pacing]?.[4]}
                      </small>
                    </div>
                    {item?.settings_reflection && (
                      <div className='flex-fill d-flex flex-column align-items-center justify-content-center h-100 p-2 ms-2 bg-light'>
                        <small>
                          Final
                          <br />
                          Reflection
                        </small>
                        <small className='badge bg-secondary mt-2'>
                          {timerDetails[item.pacing]?.[0]}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {item.pacing === 'extended' && (
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'></div>
              </div>
            )}
          </div>
        </div>

        <div className='col-12 pt-3'>
          <hr />
        </div>

        <div className='col-2 border-end mb-3'>
          <h6 className='mb-3'>
            Customize the run{' '}
            <i
              className='ms-1'
              onClick={(e) => toggleCustomizeTooltip(true)}
              style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faInfoCircle} className='text-primary' />
            </i>
          </h6>
          <div className='pe-3'>
            <p>
              <small>
                Advanced customizations allow facilitators to add components to
                the student experience or actively manage its flow.
              </small>
            </p>
            {/* <p>
              <button
                onClick={toggleResetSettings}
                className='btn btn-outline-primary me-4'>
                Reset Settings
              </button>
            </p> */}
          </div>
        </div>

        <div className='col-10 pt-2 mb-3'>
          <div className='row'>
            {/* LEFT */}
            <div className='col-6 px-4'>
              {/* Pre-Survey */}
              <div className='form-group mb-4'>
                <div className='d-flex form-check form-switch ps-0 w-100'>
                  <label className='fw-bold' htmlFor='check-survey'>
                    Initial Industry Assessment
                  </label>
                  <button
                    onClick={(e) => setModal(1)}
                    className='btn btn-sm btn-link p-0 ms-3 mb-1'>
                    Preview: Student
                  </button>

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_survey}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_survey'
                      type='checkbox'
                      role='switch'
                      id='check-survey'
                    />
                    <label
                      htmlFor='check-survey'
                      className={`form-check-label fw-lighter ${
                        item.settings_survey ? 'checked' : ''
                      }`}>
                      {item.settings_survey ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='check-survey'>
                  <small>
                    Users can estimate how powerful they believe each of the
                    forces to be, after reading a short summary on each but
                    before playing events.
                  </small>
                </label>
              </div>

              {/* Leaderboard */}
              <div className='form-group mb-4'>
                <div className='d-flex form-check form-switch ps-0'>
                  <label className='fw-bold' htmlFor='settings_leaderboard'>
                    Leaderboard
                  </label>
                  <button
                    onClick={(e) => setModal(2)}
                    className='btn btn-sm btn-link p-0 ms-3 mb-1'>
                    Preview: Student
                  </button>

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_leaderboard}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_leaderboard'
                      type='checkbox'
                      role='switch'
                      id='settings_leaderboard'
                    />
                    <label
                      htmlFor='settings_leaderboard'
                      className={`form-check-label fw-lighter ${
                        item.settings_leaderboard ? 'checked' : ''
                      }`}>
                      {item.settings_leaderboard ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='settings_leaderboard'>
                  <small>
                    Users can start monitoring the leaderboard after the first
                    event is played.
                  </small>
                </label>
              </div>

              {/* Reflection */}
              <div className='form-group mb-2'>
                <div className='d-flex form-check form-switch ps-0'>
                  <label className='fw-bold' htmlFor='settings_reflection'>
                    Final reflection
                  </label>
                  <button
                    onClick={(e) => setModal(3)}
                    className='btn btn-sm btn-link p-0 ms-3 mb-1'>
                    Preview: Student
                  </button>

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_reflection}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_reflection'
                      type='checkbox'
                      role='switch'
                      id='settings_reflection'
                    />
                    <label
                      htmlFor='settings_reflection'
                      className={`form-check-label fw-lighter ${
                        item.settings_reflection ? 'checked' : ''
                      }`}>
                      {item.settings_reflection ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='settings_reflection'>
                  <small>
                    A space for users to enter their final thoughts after
                    playing through all assigned events.
                  </small>
                </label>
              </div>
            </div>

            {/* RIGHT */}
            <div className='col-6 pe-5'>
              {/* Event Release */}
              <div className='form-group mb-4'>
                <div className='d-flex form-check form-switch ps-0'>
                  <label className='fw-bold' htmlFor='settings_release'>
                    Manual advance
                  </label>
                  <button
                    onClick={(e) => setModal(4)}
                    className='btn btn-sm btn-link p-0 ms-3 mb-1'>
                    Preview: Facilitator
                  </button>

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_release}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_release'
                      type='checkbox'
                      role='switch'
                      id='settings_release'
                    />
                    <label
                      htmlFor='settings_release'
                      className={`form-check-label fw-lighter ${
                        item.settings_release ? 'checked' : ''
                      }`}>
                      {item.settings_release ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='settings_release'>
                  <small>
                    Users must wait for facilitator to open each event before
                    advancing.
                  </small>
                </label>
              </div>

              {/* Domino Timers */}
              <div className='form-group mb-4'>
                <div className='d-flex form-check form-switch ps-0'>
                  <label className='fw-bold' htmlFor='settings_domino'>
                    Domino timers
                  </label>

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_domino}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_domino'
                      type='checkbox'
                      role='switch'
                      id='settings_domino'
                    />
                    <label
                      htmlFor='settings_domino'
                      className={`form-check-label fw-lighter ${
                        item.settings_domino ? 'checked' : ''
                      }`}>
                      {item.settings_domino ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='settings_domino'>
                  <small>
                    After the first event is launched, every subsequent event
                    will launch automatically to help ensure users complete the
                    experience at or around the same time.
                  </small>
                </label>
              </div>

              {/* Animations */}
              <div className='form-group mb-2'>
                <div className='d-flex form-check form-switch ps-0'>
                  <label className='fw-bold' htmlFor='settings_animations'>
                    Animations
                  </label>
                  {/* <button
                  onClick={(e) => setModal(5)}
                  className='btn btn-sm btn-link'>
                  Preview: Student
                </button> */}

                  <div className='d-inline-block position-relative ms-auto'>
                    <input
                      className='form-check-input float-end ms-0'
                      checked={item.settings_animations}
                      onChange={handleSwitchChange}
                      disabled={disabledForm}
                      name='settings_animations'
                      type='checkbox'
                      role='switch'
                      id='settings_animations'
                    />
                    <label
                      htmlFor='settings_animations'
                      className={`form-check-label fw-lighter ${
                        item.settings_animations ? 'checked' : ''
                      }`}>
                      {item.settings_animations ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                <label
                  className='form-check-labelx pe-5 me-3'
                  htmlFor='settings_animations'>
                  <small>
                    Animations can have usability implications for some people,
                    including inducing motion sickness. If any students prefer
                    no animation for any reason, you have the option to turn it
                    off for all.{' '}
                  </small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className='d-flex align-items-center bg-white p-3'>
        <button
          disabled={disabledForm}
          onClick={toggleResetSettings}
          className='btn btn-outline-primary me-auto'>
          Reset Settings
        </button>

        {/* Debug */}
        {/* <span className='me-4'>Players: {item?.players?.length}</span>
        <span className='me-auto'>Groups: {item?.groups?.length}</span> */}

        <small className='me-4 text-muted'>
          {item.status === 'saving'
            ? 'Saving...'
            : `Saved on: ${moment(item?.updated_at).format(
                'MMMM D, YYYY [at] h:mm A'
              )}`}
        </small>

        <button
          disabled={disabledForm}
          className='btn btn-outline-primary me-4'
          onClick={handleSubmit}>
          Save
        </button>

        <button
          disabled={disabledForm}
          className='btn btn-primary'
          onClick={(e) => handleSubmit({ redir: true })}>
          Save &amp; Continue
        </button>
      </footer>

      <Modal
        isOpen={modal > 0}
        toggle={() => setModal(0)}
        centered={true}
        size={'lg'}>
        <ModalHeader toggle={() => setModal(0)}>Preview</ModalHeader>
        <ModalBody className='text-center'>
          {modal === 1 && (
            <img
              src={
                game?.scenario === 'chocolate'
                  ? initial_assessment
                  : initial_assessment_escooter
              }
              alt=''
              className='img-fluid'
            />
          )}
          {modal === 2 && (
            <img
              src={
                game?.scenario === 'chocolate'
                  ? leaderboard
                  : leaderboard_escooter
              }
              alt=''
              className='img-fluid'
            />
          )}
          {modal === 3 && (
            <img
              src={
                game?.scenario === 'chocolate'
                  ? final_reflection
                  : final_reflection_escooter
              }
              alt=''
              className='img-fluid'
            />
          )}
          {modal === 4 && (
            <img src={release_events} alt='' className='img-fluid' />
          )}
        </ModalBody>
        {/* <ModalFooter>
          <button
            className='btn btn-outline-primary'
            onClick={() => setModal(0)}>
            Close
          </button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
};

export default FormExperience;
